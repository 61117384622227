import React, { Component } from 'react'
import $ from 'jquery'
import { TweenMax, Expo, Back } from 'gsap/all';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LOCATION } from '../constants';
//import ReactPlayer from 'react-player'

class DetailPanel extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false;
    this._onResizeWindow = this.onResizeWindow.bind(this);
    this._onTouchStart = this.onTouchStart.bind(this);
    this._onTouchMove = this.onTouchMove.bind(this);
    this.slideDisplay = React.createRef()

    this.state = {
      infoHeight: 'none', //Detail info height for scrollbar
      detail: [], //Works in each group (DEVELOPMENT / DIGITAL_MEDIA)
      page: '', //DEVELOPMENT, DIGITAL_MEDIA
      index: 0, //Work Index for Detail Page
      imageSlide: 0, //Slideshow image index
      playerSlide: null, //Slideshow player index
      isAnimate: false //Slideshow animation status to prevent repeated clicks 
    }

    this.xDown = null;
    this.yDown = null;
  }

  componentDidMount() {
    //console.log("***************************** DetailPanel");
    this._isMounted = true;
    this.props.setLogo(true)
    const { theRef } = this.props
    if (theRef) theRef(this)

    $( ".detail-panel-wrapper" ).scrollTop(0)
    setTimeout(() => { $( ".detail-panel-wrapper" ).scrollTop(0) }, 1000);
    TweenMax.set($('.detail-screen__display'), { transform: 'perspective(400px) rotateX(60deg) translateY(-300px)', opacity: 0 })
    TweenMax.set($('.detail-bullet'), { transform: 'perspective(400px) rotateX(-60deg) translateY(150px)', opacity: 0 })
    TweenMax.set($('.detail-info'), { opacity: 0 })
    TweenMax.set($('.detail-arrow.left'), { opacity: 0 })
    TweenMax.set($('.detail-arrow.right'), { opacity: 0 })
    TweenMax.set($('.detail-panel-wrapper'), { opacity: 0 })
    TweenMax.set($('.page-name'), { y: '-50px', opacity: 0 })
    TweenMax.set($('.platform-name'), { y: '-50px', opacity: 0 })

    this.reduceData()
    this.onResizeWindow();
    window.addEventListener("resize", this._onResizeWindow);
    this.slideDisplay.addEventListener('touchstart', this._onTouchStart, false);
    this.slideDisplay.addEventListener('touchmove', this._onTouchMove, false);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this._onResizeWindow);
    this.slideDisplay.removeEventListener('touchstart', this._onTouchStart, false);
    this.slideDisplay.removeEventListener('touchmove', this._onTouchMove, false);
  }

  onResizeWindow() {
    this.resizeWindow();
    setTimeout(this.resizeWindow.bind(this), 1000);
  }

  resizeWindow() {
    if (!this._isMounted) return;
    if (window.innerHeight < 560) {
      this.setState({
        infoHeight: (window.innerHeight - 100) + 'px'
      })
    } else {
      this.setState({
        infoHeight: 'none'
      })
    }
  }

  reduceData() {
    //const { imageSlide } = this.state
    const { page, identifyNumber } = this.props
    const works = this.props.worksData.works
    let filter = []

    //console.log("DetailPanel", "reduceData", imageSlide, page, identifyNumber); //TODO: Remove later when end this project

    if (page === 'DEVELOPMENT') {
      filter = works.filter(item => item.partOf === 'development')
    } else if (page === 'DIGITAL_MEDIA') {
      filter = works.filter(item => item.partOf === 'digital-media')
    }

    this.setState({
      detail: filter,
      page: page,
      index: filter[identifyNumber] ? identifyNumber : 0,
      imageSlide: 0,
      playerSlide: null,
      isAnimate: false
    }, () => {
      TweenMax.set($('.detail-arrow.left'), { opacity: 0 })
      TweenMax.set($('.detail-arrow.right'), { opacity: 0 })
      TweenMax.to($('.detail-panel-wrapper'), 0.5, {
        opacity: 1, ease: Expo.easeInOut, onComplete: () => {
          TweenMax.to($('.page-name'), 0.8, { delay: 0.2, y: '0px', opacity: 1, ease: Back.easeOut })
          TweenMax.to($('.platform-name'), 0.8, { delay: 0.4, y: '0px', opacity: 1, ease: Back.easeOut })
          this.transitionIn()
        }
      })
    })
  }

  transitionIn() {
    const { setHash } = this.props
    const { page, index } = this.state
    //console.log("DetailPanel", "transitionIn"); //TODO: Remove later when end this project

    setTimeout(() => {
      this.setState({ imageSlide: 0 }, () => {
        $( ".detail-info" ).scrollTop(0)
        TweenMax.fromTo($('.detail-screen__display'), 0.8, { transform: 'perspective(400px) rotateX(60deg) translateY(-300px)', opacity: 0 }, { opacity: 1, transform: 'perspective(400px) rotateX(0) translateY(0)', ease: Expo.easeInOut })
        TweenMax.fromTo($('.detail-bullet'), 0.8, { transform: 'perspective(400px) rotateX(-60deg) translateY(150px)', opacity: 0 }, { opacity: 1, transform: 'perspective(400px) rotateX(0) translateY(0)', ease: Expo.easeInOut })
        TweenMax.fromTo($('.detail-info'), 0.8, { opacity: 0 }, { opacity: 1, ease: Expo.easeInOut })
        TweenMax.fromTo($('.detail-arrow.left'), 0.5, { opacity: 0 }, { opacity: 1, ease: Expo.easeInOut })
        TweenMax.fromTo($('.detail-arrow.right'), 0.5, { opacity: 0 }, { opacity: 1, ease: Expo.easeInOut })
        TweenMax.to($('.slide-display'), 0.5, { x: `0%`, ease: Expo.easeInOut })
        this.fadeSlidePlayer(0, 1, 0.25)
      })
    }, 100);
    
    if (page === 'DEVELOPMENT') {
      if (setHash) setHash(LOCATION.DEVELOPMENT_DETAIL, String(index))
    } else if (page === 'DIGITAL_MEDIA') {
      if (setHash) setHash(LOCATION.DIGITAL_MEDIA_DETAIL, String(index))
    }
  }

  transitionOut(callback = () => { }) {
    //console.log("DetailPanel", "transitionOut"); //TODO: Remove later when end this project
    TweenMax.fromTo($('.detail-screen__display'), 0.8, { opacity: 1, transform: 'perspective(400px) rotateX(0) translateY(0)' }, { transform: 'perspective(400px) rotateX(60deg) translateY(-300px)', opacity: 0, ease: Expo.easeInOut })
    TweenMax.fromTo($('.detail-bullet'), 0.8, { opacity: 1, transform: 'perspective(400px) rotateX(0) translateY(0)' }, { transform: 'perspective(400px) rotateX(-60deg) translateY(150px)', opacity: 0, ease: Expo.easeInOut })
    TweenMax.fromTo($('.detail-info'), 0.8, { opacity: 1 }, { opacity: 0, ease: Expo.easeInOut, onComplete: () => callback & callback() })
    TweenMax.fromTo($('.detail-arrow.left'), 0.5, { opacity: 1 }, { opacity: 0, ease: Expo.easeInOut })
    TweenMax.fromTo($('.detail-arrow.right'), 0.5, { opacity: 1 }, { opacity: 0, ease: Expo.easeInOut })
  }

  closePanel(complete = () => { }) {
    const { onCloseDetail, setLock } = this.props
    setLock(true);

    //console.log("DetailPanel", "closePanel"); //TODO: Remove later when end this project

    this.transitionOut(() => {
      TweenMax.to($('.detail-panel-wrapper'), 0.5, {
        opacity: 0, ease: Expo.easeInOut, onComplete: () => {
          if (onCloseDetail) onCloseDetail(complete)
        }
      })
    });
  }

  swapContent(index, complete = () => { }) {
    const { setLock } = this.props
    setLock(true);

    //console.log("DetailPanel", "swapContent index:", index); //TODO: Remove later when end this project

    this.transitionOut(() => {
      const { detail } = this.state
      const identifyNumber = !isNaN(parseInt(index)) ? parseInt(index) : 0
      const currentContent = detail[identifyNumber] ? identifyNumber : 0

      this.setState({
        index: currentContent,
        playerSlide: null,
      }, () => {
        this.transitionIn()
        TweenMax.delayedCall(0.5, () => { complete && complete() })
      })
    })
  }

  changeContent(value) {
    const { index, detail } = this.state
    let currentContent = index + value

    if (currentContent > detail.length - 1) {
      currentContent = 0
    } else if (currentContent < 0) {
      currentContent = detail.length - 1
    }

    this.setState({
      index: currentContent,
      playerSlide: null,
    }, () => {
      this.transitionIn()
    })
  }

  getNextContent() {
    this.changeContent(1);
  }

  getPrevContent() {
    this.changeContent(-1);
  }

  slidePhoto(value, force = false) {
    const { detail, index, isAnimate, imageSlide } = this.state
    if (isAnimate) return

    let slide = imageSlide + value
    if (slide > detail[index].detail.length - 1) {
      slide = detail[index].detail.length - 1
    } else if (slide < 0) {
      slide = 0
    }
    if (force) slide = value

    TweenMax.set($('#cover-' + slide), { opacity: 1, visibility: 'visible' })
    //console.log("DetailPanel", "slidePhoto - slide:", slide); //TODO: Remove later when end this project

    this.setState({
      imageSlide: slide,
      isAnimate: true
    }, () => {
      this.fadeSlidePlayer(slide, 1, 0.25)
      TweenMax.to($('.slide-display'), 0.5, {
        x: `${slide * -100}%`, ease: Expo.easeInOut,
        onComplete: () => {
          this.setState({
            isAnimate: false
          })
        }
      })
    })
  }

  fadeSlidePlayer(slide, delaySlide = 0.5, delayPlayer = 0.5) {
    const $cover = $('#cover-' + slide);
    TweenMax.delayedCall(delayPlayer, () => { this.setState({ playerSlide: slide }) })
    TweenMax.fromTo($cover, 0.5,
      { opacity: 1, visibility: 'visible' },
      {
        opacity: 0,
        delay: delaySlide,
        ease: Expo.easeInOut,
        onComplete: () => {
          $cover.css({ visibility: 'hidden' })
        }
      }
    )
  }

  onTouchStart(evt) {
    this.xDown = evt.touches[0].clientX
    this.yDown = evt.touches[0].clientY
    //console.log("DetailPanel", "onTouchStart", this.xDown, this.yDown); //TODO: Remove later when end this project
  }

  onTouchMove(evt) {
    let xUp = evt.touches[0].clientX
    let yUp = evt.touches[0].clientY
    let xDiff = this.xDown - xUp
    let yDiff = this.yDown - yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        this.slidePhoto(1)
      } else {
        this.slidePhoto(-1)
      }

      //console.log("DetailPanel", "onTouchMove", "xDiff:", xDiff); //TODO: Remove later when end this project
    }
  }

  renderBullet() {
    const { detail, index, imageSlide, page } = this.state
    if (detail.length === 0) return <div></div>
    let color = (page === 'DEVELOPMENT') ? 'development' : 'digital'
    let invisible = !(detail.length > 0 && detail[index] && detail[index].detail.length > 1) ? 'invisible' : '';

    return (
      <div className={`display-status py-4 mx-auto ${invisible}`}>
        {
          detail[index].detail.map((item, index) => (
            <span 
              key={index} 
              className={`ball-status ${color} ${index === imageSlide && 'active'}`} 
              onClick={() => this.slidePhoto(index, true)} 
            />
          ))
        }
      </div>
    )
  }

  renderMedia(id, item, title) {
    const { playerSlide } = this.state
    let height = (636 / 900) * 100 + "%"
    if (item.ratio) {
      const ratios = String(item.ratio).split(":")
      const percent = (ratios[1] / ratios[0]) * 100
      if (!isNaN(percent) && percent > 0) {
        height = percent + "%"
      }
    }

    return (
      <div key={id} className="slide-display px-0 col-12">
        {item.youtubeId ?
          <>
            <div className="d-flex h-100">
              <div className="position-relative w-100 my-auto"
                style={{
                  paddingBottom: height,
                  height: 0
                }}
              >
                {playerSlide === id &&
                  <iframe src={`https://www.youtube.com/embed/${item.youtubeId}?autoplay=1&loop=1&playlist=${item.youtubeId}`}
                    title={title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  />
                }
              </div>
            </div>
            <img id={"cover-" + id} className="position-absolute top-0 left-0 bottom-0 right-0 img-fluid m-auto" src={item.photo} alt={title} />
          </>
          : <img className="img-fluid m-auto" src={item.photo} alt={title} />
        }
      </div>
    )
  }

  renderSlide() {
    const { detail, index } = this.state
    if (detail.length === 0) return <div>Loading...</div>

    return detail[index].detail.map((item, id) => {
      return this.renderMedia(id, item, detail[index].brandName);
    })
  }

  render() {
    const { index, imageSlide, detail, page, infoHeight } = this.state
    let color = (page === 'DEVELOPMENT') ? 'development' : 'digital'
    let showNextArrow = false;
    let showPrevArrow = false;
    let brandName = ''
    let type = ''
    let year = ''
    let description = ''

    if (detail.length > 0 && detail[index]) {
      if (detail[index].detail.length > 1) {
        if (imageSlide < detail[index].detail.length - 1) {
          showNextArrow = true;
        }
        if (imageSlide > 0) {
          showPrevArrow = true;
        }
      }
      brandName = detail[index].brandName
      type = detail[index].type
      year = detail[index].year
      description = detail[index].description
    }

    return (
      <div className="detail-panel-wrapper">
        <div className="detail-panel-wrapper__badge bs4">
          <span className="page-name text-center">OUR WORKS</span>
          <span className="platform-name">{/*String(page).replace(/_/gi, ' ').toUpperCase()*/}</span>
        </div>
        <span className="btn-close-panel" onClick={() => this.closePanel()} />
        <div className="detail-screen bs4">
          <div className="container-fluid padding-wrapper d-flex flex-column h-100">
            <div className="row justify-content-center flex-row my-auto">
              <div className="col-12 col-xl-11">
                <div className="row">
                  <div className="col-12 col-lg-8 detail-slide">
                    <div className="row mx-lg-5">
                      <div ref={ref => this.slideDisplay = ref} className="detail-screen__display mx-auto">
                        <div className="d-flex w-100 overflow-hidden">
                          {this.renderSlide()}
                        </div>
                        {showPrevArrow && <span className={`detail-arrow inner left ${color}`}
                          onClick={() => this.slidePhoto(-1)} />}
                        {showNextArrow && <span className={`detail-arrow inner right ${color}`}
                          onClick={() => this.slidePhoto(1)} />}
                      </div>
                    </div>
                    <div className="row detail-bullet">
                      {this.renderBullet()}
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 overflow-auto detail-info" style={{ 'maxHeight': infoHeight }}>
                    <h1 className="detail-title mx-auto">{brandName}</h1>
                    <div className="detail-content mx-auto">
                      <div className="detail-screen__active-status">
                        <div className="display-type">{type}</div>
                        <div className="display-year">{year}</div>
                      </div>
                      <div className="detail-screen__description mb-5 pb-3" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div 
          className={`detail-panel-wrapper__next ${index >= (detail.length - 1) ? 'hide' : ''} ${color}`} 
          onClick={() => this.transitionOut(() => this.getNextContent())}
        >NEXT</div>
        <div 
          className={`detail-panel-wrapper__prev ${index <= 0 ? 'hide' : ''} ${color}`} 
          onClick={() => this.transitionOut(() => this.getPrevContent())}
        >PREVIOUS</div>
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    setLogo: (value) => dispatch({ type: 'IS_COLOR', payload: value })
  }

  return bindActionCreators(action, dispatch)
}

const stateToProps = (state) => {
  return {
    worksData: state.worksData
  }
}

export default connect(stateToProps, dispatchToProps)(DetailPanel)
