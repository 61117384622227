const initialState = {
  works: [],
  error: false
}

const worksData = (state = initialState, action) => {
  switch(action.type) {
    case 'GET_WORKS_DATA_SUCCESS':
      return {...state, works: action.payload, error: false }
    case 'GET_WORKS_DATA_FAILED':
      return {...state, works: action.payload, error: true }
    default : return state
  }
}

export default worksData
