import APIAdapter from "../adapter/APIAdapter"

export default class Action {
  static getWorksData() {
    return async (dispatch) => {
      const response = await APIAdapter.getWorksData()
      if (response) {
        dispatch({ type: 'GET_WORKS_DATA_SUCCESS', payload: response })
      } else {
        dispatch({ type: 'GET_WORKS_DATA_FAILED', payload: new Error('GET ERROR BY THE URL 404').message })
      }
    }
  }

  static getClientsData() {
    return async (dispatch) => {
      const response = await APIAdapter.getClientsData()
      if (response) {
        dispatch({ type: 'GET_CLIENTS_DATA_SUCCESS', payload: response })
      } else {
        dispatch({ type: 'GET_CLIENTS_DATA_FAILED', payload: new Error('GET ERROR BY THE URL 404').message })
      }
    }
  }
}