import React, { Component } from 'react'
import { TweenMax, Expo, TimelineMax } from 'gsap/all'
import { LOCATION } from '../constants'
import $ from 'jquery'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class MainMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false
    }
  }

  componentDidMount() {
    //console.log("***************************** MainMenu");
    const { theRef } = this.props
    if (theRef) theRef(this)

    const tl = new TimelineMax()
    tl.append(TweenMax.fromTo($('.main-menu-wrapper'), 0.5, { opacity: 0 }, { opacity: 1, ease: Expo.easeInOut }))
    tl.append(TweenMax.fromTo($('.main-menu-wrapper'), 0.5, { scale: 0.9 }, { scale: 1, ease: Expo.easeInOut, onComplete:() => {
      this.setState({ open: true })
    }}))
    tl.play()
  }

  closeThisPanel(complete = () => {}) {
    //console.log("MainMenu", "closeThisPanel"); //TODO: Remove later when end this project
    const { onClickClose } = this.props
    const tl = new TimelineMax()
    tl.append(TweenMax.fromTo($('.main-menu-wrapper'), 0.5, { scale: 1 }, { scale: 0.9, ease: Expo.easeInOut }))
    tl.append(TweenMax.fromTo($('.main-menu-wrapper'), 0.5, { opacity: 1 }, { opacity: 0, ease: Expo.easeInOut, onComplete:() => {
      onClickClose()
      if (complete) complete()
    }}))
    tl.play()
    this.setState({ open: false })
  }

  gotoDevelopment() {
    //console.log("MainMenu", "gotoDevelopment"); //TODO: Remove later when end this project
    this.closeThisPanel(() => {
      this.props.history.push(`/#${LOCATION.DEVELOPMENT}`)
    })
  }

  gotoDigital() {
    //console.log("MainMenu", "gotoDigital"); //TODO: Remove later when end this project
    this.closeThisPanel(() => {
      this.props.history.push(`/#${LOCATION.DIGITAL_MEDIA}`)
    })
  }

  gotoHome() {
    //console.log("MainMenu", "gotoHome"); //TODO: Remove later when end this project
    this.closeThisPanel(() => {
      this.props.history.push(LOCATION.HOME)
    })
  }

  gotoContactUs() {
    //console.log("MainMenu", "gotoContactUs"); //TODO: Remove later when end this project
    this.closeThisPanel(() => {
      this.props.history.push(`/#${LOCATION.CONTACT_US}`)
    })
  }

  gotoPlatform() {
    //console.log("MainMenu", "gotoPlatform"); //TODO: Remove later when end this project
    this.closeThisPanel(() => {
      this.props.history.push(`/#${LOCATION.WHO_WE_ARE}`)
    })
  }

  gotoOurServices() {
    //console.log("MainMenu", "gotoOurServices"); //TODO: Remove later when end this project
    this.closeThisPanel(() => {
      this.props.history.push(`/#${LOCATION.OUR_SERVICE}`)
    })
  }

  gotoOurClients() {
    //console.log("MainMenu", "gotoOurClients"); //TODO: Remove later when end this project
    this.closeThisPanel(() => {
      this.props.history.push(`/#${LOCATION.OUR_CLIENT}`)
    })
  }


  render () {
    return (
      <div className="main-menu-wrapper">
        <span className="main-menu-wrapper__close" onClick={() => this.closeThisPanel()}/>
        <div className="main-menu-wrapper__container">
          <ul className="main-menu-wrapper__container--list">
            <li className="main-menu-wrapper__container--item link" onClick={() => this.gotoHome()}>HOME</li>
            <li className="main-menu-wrapper__container--item link" onClick={() => this.gotoPlatform()}>WHO WE ARE</li>
            <li className="main-menu-wrapper__container--item link">
              <div className="menu-platform">
                <span className="item" onClick={() => this.gotoDevelopment()}>OUR WORKS</span>
                <span className="item">|</span>
                <span className="item" onClick={() => this.gotoOurServices()}>OUR SERVICES</span>
              </div>
            </li>
            <li className="main-menu-wrapper__container--item link"  onClick={() => this.gotoOurClients()}>OUR CLIENTS</li>
            <li className="main-menu-wrapper__container--item link" onClick={() => this.gotoContactUs()}>CONTACT US</li>
            <li className="main-menu-wrapper__container--item link">
              <div className="information">FOR MORE INFORMATION</div>
              <div className="icon-contaioner">
                <a href="https://www.facebook.com/superscriptmedia/" rel="noopener noreferrer" target="_blank">
                  <i className="icon-menu facebook"/>
                </a>
                <a href="mailto:digital@superscriptmedia.co.th">
                  <i className="icon-menu mail"/>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    reloadPage: (page) => dispatch({ type: 'RELOAD_PAGE', payload: { page, reload: true } })
  }

  return bindActionCreators(action, dispatch)
}

export default connect(null, dispatchToProps)(MainMenu)
