const initialState = {
  clients: [],
  error: false
}

const clientsData = (state = initialState, action) => {
  switch(action.type) {
    case 'GET_CLIENTS_DATA_SUCCESS':
      return {...state, clients: action.payload, error: false }
    case 'GET_CLIENTS_DATA_FAILED':
      return {...state, clients: action.payload, error: true }
    default : return state
  }
}

export default clientsData
