import React, { Component } from 'react'
import $ from 'jquery'
import { TweenMax, Power4 } from 'gsap/all';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransitionPink from './TransitionPink';
import TransitionGreen from './TrnsitionGreen';
import DevelopmentPanel from './DevelopmentPanel';
import OurServices from './OurServices';
import WhoWeAreWording from './WhoWeAreWording';
import { LOCATION } from '../constants';

class PlatformPanel extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false;
    this._onResizeWindow = this.onResizeWindow.bind(this);
    this.container = React.createRef()
    this.development = React.createRef()
    this.ourService = React.createRef()

    this.state = {
      isMobile: false,
      showMenu: true,
      showButton: false, //Show Buttons in "Who We Are" Page
      isSelect: false, //Clicked from "Who We Are" Page
      isDevContent: false,  //Show Development Page
      isOurService: false, //Show "our services" page
      isRight: false, //Transition to Right
      isLeft: false, //Transition to Left
      platform: 'WHO_WE_ARE' //Name Page
    }
  }

  componentDidMount() {
    //console.log("***************************** PlatformPanel");
    this._isMounted = true;
    this.props.setLogo(false)
    const { theRef, isMobile } = this.props
    if (theRef) theRef(this)

    $( ".platform-heading-wraper" ).scrollTop(0)
    setTimeout(() => { $( ".platform-heading-wraper" ).scrollTop(0) }, 1000);
    if (isMobile) {
      TweenMax.set($('.platform-panel-wrapper__title'), { opacity: 0 })
      TweenMax.set($('.platform-panel-wrapper__description'), { opacity: 0 })
      TweenMax.set($('.platform-panel-animation.development'), { opacity: 0 })
      TweenMax.set($('.platform-panel-animation.digital-media'), { opacity: 0 })
    }

    this.setState({isMobile : isMobile})
    this.openPlatform()
    this.onResizeWindow();
    window.addEventListener("resize", this._onResizeWindow);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this._onResizeWindow);
  }

  onResizeWindow() {
    this.resizeWindow();
    //setTimeout(this.resizeWindow.bind(this), 1000);
  }

  resizeWindow() {
    if (!this._isMounted) return;
    if (this.state.isMobile !== this.props.isMobile) {
      $('.platform-panel-animation.development').css({width: '', height: '', left: ''})
      $('.platform-panel-animation.digital-media').css({width: '', height: '', left: ''})
    }
  }

  onClickClose() {
    const { isSelect, showButton } = this.state
    const { onClickClose, setLock } = this.props
    if (isSelect || !showButton) return

    setLock(true);
    onClickClose();
  }

  onHoverAnim(value) {
    const { isSelect, showButton } = this.state
    const { isMobile } = this.props
    if (isSelect || !showButton || isMobile) return

    if (value >= 1) {
      TweenMax.to($('.platform-panel-animation.development'), 1, { width: '60%', ease: Power4.easeInOut })
      TweenMax.to($('.platform-panel-animation.digital-media'), 1, { width: '40%', ease: Power4.easeInOut })
      TweenMax.to($('.platform-panel-wrapper__title'), 1.2, { left: '60%', ease: Power4.easeInOut })
      TweenMax.to($('.platform-panel-wrapper__description'), 1.3, { left: '60%', ease: Power4.easeInOut })
    } else if (value < 1){
      TweenMax.to($('.platform-panel-animation.development'), 1, { width: '40%', ease: Power4.easeInOut })
      TweenMax.to($('.platform-panel-animation.digital-media'), 1, { width: '60%', ease: Power4.easeInOut })
      TweenMax.to($('.platform-panel-wrapper__title'), 1.2, { left: '40%', ease: Power4.easeInOut })
      TweenMax.to($('.platform-panel-wrapper__description'), 1.3, { left: '40%', ease: Power4.easeInOut })
    }
  }

  onNormalAnim() {
    const { isSelect, showButton } = this.state
    const { isMobile } = this.props
    if (isSelect || !showButton) return

    TweenMax.to($('.platform-panel-wrapper__title'), 1.3, { left: '50%',opacity: 1, ease: Power4.easeInOut })
    TweenMax.to($('.platform-panel-wrapper__description'), 1.2, { left: '50%',opacity: 1, ease: Power4.easeInOut })

    if (isMobile) {
      $('.platform-panel-animation.development').css({width: ''})
      $('.platform-panel-animation.digital-media').css({width: ''})
      TweenMax.to($('.platform-panel-animation.development'), 1, { height: '72%',opacity: 1, ease: Power4.easeInOut })
      TweenMax.to($('.platform-panel-animation.digital-media'), 1, { height: '28%',opacity: 1, ease: Power4.easeInOut, onComplete: () => {
        $('.platform-panel-animation.development').css({height: ''})
        $('.platform-panel-animation.digital-media').css({height: ''})
      }})
    } else {
      $('.platform-panel-animation.development').css({height: ''})
      $('.platform-panel-animation.digital-media').css({height: ''})
      TweenMax.to($('.platform-panel-animation.development'), 1, { width: '50%',opacity: 1, ease: Power4.easeInOut })
      TweenMax.to($('.platform-panel-animation.digital-media'), 1, { width: '50%',opacity: 1, ease: Power4.easeInOut, onComplete: () => {
        $('.platform-panel-animation.development').css({width: ''})
        $('.platform-panel-animation.digital-media').css({width: ''})
      }})
    }
  }

  onClickPlatform(value, complete = () => {}) {
    const { isSelect, showButton } = this.state
    const { checkSubPage, isMobile, setLock } = this.props
    if (isSelect || !showButton) return
    let platform = ''
    setLock(true);

    if (!isMobile) {
      $('.platform-panel-animation.development').css({height: ''})
      $('.platform-panel-animation.digital-media').css({height: ''})
      if (value >= 1) {
        this.setState({ isLeft: true, showMenu: true })
        TweenMax.to($('.platform-panel-animation.development'), 1.5, {  width: '150%', opacity: 1, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-animation.digital-media'), 1.5, {  width: '0%', opacity: 0, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-wrapper__title'), 1.2, {  left: '120%', opacity: 0, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-wrapper__description'), 1.3, { left: '120%', opacity: 0, ease: Power4.easeInOut })
        platform = 'DEVELOPMENT'
      } else if (value < 1) {
        this.setState({ isRight: true, showMenu: true })
        TweenMax.to($('.platform-panel-animation.development'), 1.5, { width: '0%', opacity: 0, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-animation.digital-media'), 1.5, { width: '150%', opacity: 1, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-wrapper__title'), 1.2, { left: '-20%', opacity: 0, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-wrapper__description'), 1.3, { left: '-20%', opacity: 0, ease: Power4.easeInOut })
        platform = 'OUR_SERVICE'
      }
    } else {
      $('.platform-panel-animation.development').css({width: ''})
      $('.platform-panel-animation.digital-media').css({width: ''})
      if (value >= 1) {
        this.setState({ isLeft: true, showMenu: false })
        TweenMax.to($('.platform-panel-animation.development'), 1.5, {  height: '100%', opacity: 1, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-animation.digital-media'), 1.5, {  height: '0%', opacity: 0, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-wrapper__title'), 1.2, {  left: '120%', opacity: 0, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-wrapper__description'), 1.3, { left: '120%', opacity: 0, ease: Power4.easeInOut })
        platform = 'DEVELOPMENT'
      } else if (value < 1) {
        this.setState({ isRight: true, showMenu: false })
        TweenMax.to($('.platform-panel-animation.development'), 1.5, { height: '0%', opacity: 0, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-animation.digital-media'), 1.5, { height: '100%', opacity: 1, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-wrapper__title'), 1.2, { left: '-20%', opacity: 0, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-wrapper__description'), 1.3, { left: '-20%', opacity: 0, ease: Power4.easeInOut })
        platform = 'OUR_SERVICE'
      }
    }

    TweenMax.delayedCall(1.5, () => {
      TweenMax.set($('.platform-panel-wrapper__title'), { opacity: 0 })
      TweenMax.set($('.platform-panel-wrapper__description'), { opacity: 0 })
      if (platform === 'DEVELOPMENT') {
        TweenMax.to($('.platform-panel-animation.development'), 1, { delay: 0.3, opacity: 0, ease: Power4.easeInOut, onComplete: () => {
          this.setState({ 
            isDevContent: true,
            isOurService: false
          }, () => {
            TweenMax.delayedCall(1, () => { complete && complete() })
          })
        }})
      } else { // OUR_SERVICE
        TweenMax.to($('.platform-panel-animation.digital-media'), 1, { delay: 0.3, opacity: 0, ease: Power4.easeInOut, onComplete: () => {
          this.setState({ 
            isDevContent: false,
            isOurService: true
          }, () => {
            TweenMax.delayedCall(1, () => { complete && complete() })
          })
        }})
      }
    })

    //console.log("PlatformPanel", "onClickPlatform", value, isMobile, platform); //TODO: Remove later when end this project
    this.setState({ isSelect: true, platform: platform })
    if (checkSubPage) checkSubPage(platform)
  }

  turnBackToNormalState(complete = () => {}) {
    const { setHash } = this.props

    this.setState({
      isSelect: false
    }, () => {
      this.onNormalAnim()
    })

    //console.log("PlatformPanel", "turnBackToNormalState"); //TODO: Remove later when end this project
    TweenMax.delayedCall(1, () => {
      if (setHash) setHash(LOCATION.WHO_WE_ARE)
      complete && complete();
    })
  }

  openPlatform() {
    this.props.setLogo(false)
    const { setHash } = this.props

    TweenMax.delayedCall(1, () => {
      this.setState({ 
        showButton: true, 
        showMenu: this.props.isMobile && this.state.isSelect ? false : true
      })
    })

    if (this.props.isMobile) {
      TweenMax.fromTo($('.platform-panel-animation.development'), 1, { width: '0%' }, { width: '100%', opacity: 1, ease: Power4.easeInOut })
      TweenMax.fromTo($('.platform-panel-animation.digital-media'), 1, { width: '0%' }, { width: '100%', opacity: 1, ease: Power4.easeInOut, onComplete: () => {
        if (!this.state.isSelect) {
          TweenMax.to($('.platform-panel-wrapper__title'), 1.2, { opacity: 1 })
          TweenMax.to($('.platform-panel-wrapper__description'), 1.3, { opacity: 1 })
        }
      }})
    }

    //console.log("PlatformPanel", "openPlatform"); //TODO: Remove later when end this project
    if (setHash) setHash(LOCATION.WHO_WE_ARE)
  }

  outPlatform(complete = () => {}) {
    //console.log("PlatformPanel", "outPlatform"); //TODO: Remove later when end this project
    this.props.setLogo(true)
    this.setState({ showButton: false, showMenu: true })

    if (this.props.isMobile) {
      TweenMax.fromTo($('.platform-panel-wrapper__title'), 0.5, { opacity: 1 }, { opacity: 0 })
      TweenMax.fromTo($('.platform-panel-wrapper__description'), 0.5, { opacity: 1 }, { opacity: 0, onComplete: () => {
        TweenMax.to($('.platform-panel-animation.development'), 0.5, { width: '0%', opacity: 1, ease: Power4.easeInOut })
        TweenMax.to($('.platform-panel-animation.digital-media'), 0.5, { width: '0%', opacity: 1, ease: Power4.easeInOut, onComplete: () => complete() })
      }})
    } else {
      TweenMax.fromTo($('.platform-panel-wrapper__title'), 0.5, { opacity: 1 },{ opacity: 0 })
      TweenMax.fromTo($('.platform-panel-wrapper__description'), 0.5, { opacity: 1 },{ opacity: 0, onComplete: () => complete() })
    }
  }

  backStep(complete = () => {}) {
    this.setState({ isDevContent: false, isOurService: false, isRight: false, isLeft: false, showMenu: true })
    const { checkSubPage } = this.props
    if (checkSubPage) checkSubPage('WHO_WE_ARE')
    //console.log("PlatformPanel", "backStep"); //TODO: Remove later when end this project
    this.turnBackToNormalState(complete)
  }

  goServicePage(complete = () => {}) {
    //console.log("PlatformPanel", "goServicePage"); //TODO: Remove later when end this project
    this.setState({
      isSelect: false,
      showButton: true
    }, () => {
      this.onClickPlatform(-1, complete)
    })
  }

  closeServicePage(complete = () => {}) {
    const { isOurService } = this.state
    //console.log("PlatformPanel", "closeServicePage"); //TODO: Remove later when end this project
    if (isOurService) {
      this.ourService.backToMainPage(complete)
    }
  }

  goWorkPage(platform, complete = () => {}) {
    //console.log("PlatformPanel", "goWorkPage platform:", platform); //TODO: Remove later when end this project
    this.setState({
      isSelect: false,
      showButton: true
    }, () => {
      if (platform === 'DEVELOPMENT') {
        this.onClickPlatform(1, complete)
      } else if (platform === 'DIGITAL_MEDIA') {
        this.onClickPlatform(1, complete)
      }
    })
  }

  closeWorkPage(complete = () => {}) {
    const { isDevContent } = this.state
    //console.log("PlatformPanel", "closeWorkPage"); //TODO: Remove later when end this project
    if (isDevContent) {
      this.development.backToMainPage(complete)
    }
  }

  goDetailPage(id, complete = () => {}) {
    const { isDevContent } = this.state
    //console.log("PlatformPanel", "goDetailPage id:", id); //TODO: Remove later when end this project
    if (isDevContent) {
      if (this.development.state.isDetail) {
        this.development.detail.swapContent(id, complete)
      } else {
        this.development.selectContent(id, complete)
      }
    }
  }

  closeDetailPage(complete = () => {}) {
    const { isDevContent } = this.state
    //console.log("PlatformPanel", "closeDetailPage"); //TODO: Remove later when end this project
    if (isDevContent && this.development.state.isDetail) {
      this.development.detail.closePanel(complete)
    }
  }

  render() {
    const { isLeft, isRight, platform, isDevContent, isOurService, showButton, showMenu } = this.state
    const { isMobile, setHash, setLock } = this.props

    return (
      <div className="platform-panel-wrapper" ref={ref => this.container = ref}>
        <WhoWeAreWording />

        <div className={`${isMobile ? "platform-panel-mobile" : "platform-panel"} platform-panel-animation development bs4`}>
          <div className={`opacity-transition ${showButton && "show"}`}>
            <div className={`platform-panel__display-text opacity-transition ${showMenu && "show"} ${isMobile ? "d-none" : "p-2"}`} 
              onMouseOver={() => this.onHoverAnim(1)} onMouseLeave={() => this.onNormalAnim()} onClick={() => this.onClickPlatform(1)}>
              <span className="icon-arrow left"/>
              OUR WORKS
            </div>
            { isLeft && <TransitionPink/>}
          </div>
        </div>
        <div className={`${isMobile ? "platform-panel-mobile" : "platform-panel"} platform-panel-animation digital-media bs4`}>
          <div className={`opacity-transition ${showButton && "show"}`}>
            <div className={`platform-panel__display-text opacity-transition ${showMenu && "show"} ${isMobile ? "development" : "d-none"}`} 
              onMouseOver={() => this.onHoverAnim(1)} onMouseLeave={() => this.onNormalAnim()} onClick={() => this.onClickPlatform(1)}>
              <span className="icon-arrow left"/>
              OUR WORKS
            </div>
            <div className={`platform-panel__display-text opacity-transition ${showMenu && "show"} ${isMobile ? "digital-media" : "p-2"}`}  
              onMouseOver={() => this.onHoverAnim(-1)} onMouseLeave={() => this.onNormalAnim()}  onClick={() => this.onClickPlatform(-1)}>
              OUR SERVICES
              <span className="icon-arrow right"/>
            </div>
            { isRight && <TransitionGreen/>}
          </div>
        </div>

        { isDevContent && 
          <DevelopmentPanel 
            theRef={ref => this.development = ref} 
            platform={platform} 
            onClosePanel={(complete) => this.backStep(complete)} 
            setHash={(location, id) => setHash(location, id)} 
            setLock={(status) => setLock(status)} 
          />
        }
        {isOurService &&
          <OurServices
            theRef={ref => this.ourService = ref}
            onClosePanel={(complete) => this.backStep(complete)} 
            setHash={(location) => setHash(location)}
            setLock={(status) => setLock(status)}
          />
        }
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    setLogo:(value) => dispatch({ type: 'IS_COLOR', payload: value })
  }

  return bindActionCreators(action, dispatch)
}

export default connect(null, dispatchToProps)(PlatformPanel)
