import React from 'react'
import $ from 'jquery'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Action from '../actions'
import { TweenMax, Power4 } from 'gsap/all';
import MainMenu from '../components/MainMenu';
//import LogoMini from '../components/LogoMini';
import Navbar from '../components/Navbar';
import DigitalSolution from '../components/DigitalSolution';
import WhoWeAre from '../components/WhoWeAre';
import WhoWeAreWording from '../components/WhoWeAreWording';
import PlatformPanel from '../components/PlatformPanel';
import OurClients from '../components/OurClients';
import ContactUs from '../components/ContactUs';
import { LOCATION, PAGE } from '../constants';
import { isMobile, isTablet, isSafari } from 'react-device-detect';

class Home extends React.Component {
  constructor(props) {
    super(props)

    this._isMounted = false;
    this._isMobileDevice = isMobile;
    this._isPortableDevice = isMobile || isTablet || isSafari;
    this._onResizeWindow = this.onResizeWindow.bind(this);
    this._onMouseWheel = this.onMouseWheel.bind(this);
    this._onTouchStart = this.onTouchStart.bind(this);
    this._onTouchMove = this.onTouchMove.bind(this);
    this.xDown = null;
    this.yDown = null;

    this.home = React.createRef()
    this.digitalSolution = React.createRef()
    this.whoWeAre = React.createRef()
    this.platform = React.createRef()
    this.ourClient = React.createRef()
    this.contactUs = React.createRef()
    this.mainMenu = React.createRef()
    this.navbar = React.createRef()

    this.state = {
      isLock: false, //Lock event for scrollbar
      isChanging: false, //The page changing status via menu or #anchor
      isMobile: false, //Mobile layout
      isMenu: false, //Show "main menu" page
      isHome: true, //Show "home" page
      isPlatForm: false, //Show "who we are" page
      isOurClient: false, //Show "our clients" page
      isContactUs: false, //Show "contact us" page
      prevLocation: null, //Set previous location
      anchorLocation: LOCATION.HOME, //Set anchor location
      currentPage: PAGE.HOME, //Set current page when run step transition for check
      scrollList: [], //Used to check the mouse wheel movement.
      scrollTimeoutID: null, //Scroll Timeout ID
      contentHeight: '',
      showTrouble: true,
    }
  }

  componentDidMount() {
    //console.log("***************************** Home"); //TODO: Remove later when end this project
    this._isMounted = true;
    this.props.setLogo(true)
    this.getWorksData()
    this.getClientsData()

    TweenMax.set($('.platform-panel-wrapper.intro'), { opacity: 0, top: '20%', display: 'none' })

    this.onResizeWindow();
    window.addEventListener("resize", this._onResizeWindow);
    this.home.addEventListener("wheel", this._onMouseWheel);
    this.home.addEventListener("touchstart", this._onTouchStart, false);
    this.home.addEventListener("touchmove", this._onTouchMove, false);

    this.props.history.push(LOCATION.HOME);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this._onResizeWindow);
    this.home.removeEventListener("wheel", this._onMouseWheel);
    this.home.removeEventListener("touchstart", this._onTouchStart, false);
    this.home.removeEventListener("touchmove", this._onTouchMove, false);
  }

  onTouchStart(evt) {
    this.xDown = evt.touches[0].clientX
    this.yDown = evt.touches[0].clientY
    ////console.log("Home", "onTouchStart", this.xDown, this.yDown); //TODO: Remove later when end this project
  }

  onTouchMove(evt) {
    let xUp = evt.touches[0].clientX
    let yUp = evt.touches[0].clientY
    let xDiff = this.xDown - xUp
    let yDiff = this.yDown - yUp
    let degree = Math.atan2(Math.abs(yDiff), Math.abs(xDiff)) / Math.PI * 180;

    if (degree > 70) {
      if (yDiff > 50) {
        this.nextPage(evt)
      } else if (yDiff < -50) {
        this.prevPage(evt)
      }
      ////console.log("Home", "onTouchMove", "degree:", degree, "yDiff:", yDiff); //TODO: Remove later when end this project
    }
  }

  onMouseWheel(event) {
    const { isMenu, isLock, isChanging, scrollList, scrollTimeoutID } = this.state
    if (isMenu || isLock || isChanging) return

    let direction = 0;
    const eventDelta = (event.deltaY || -event.wheelDelta || event.detail);
    const delta = eventDelta ? ((eventDelta >> 10) || 1) : 0
    const count = scrollList.push(delta)

    clearTimeout(scrollTimeoutID);
    let timeoutID = setTimeout(() => this.setState({ scrollList: [] }), 500)
    this.setState({ scrollTimeoutID: timeoutID })

    if (count >= 3) {
      scrollList.forEach(function (delta) {
        direction += delta
      });
    }

    //console.log("Home", "onMouseWheel direction:", direction, "count:", count); //TODO: Remove later when end this project

    if (direction > 0) {
      this.nextPage()
    } else if (direction < 0) {
      this.prevPage()
    }
  }

  onResizeWindow() {
    this.resizeWindow();
    //setTimeout(this.resizeWindow.bind(this), 1000);
  }

  resizeWindow() {
    if (!this._isMounted) return;
    const introPosition = $(".intro-wrapper").position()
    const detectPosition = $(".detect-bottom").position()
    const contentHeight = (detectPosition.top - introPosition.top) + 'px'

    if (window.innerWidth < 768) {
      this.setState({ isMobile: true, contentHeight: contentHeight })
    } else {
      this.setState({ isMobile: false, contentHeight: contentHeight })
    }
  }

  async getWorksData() {
    //console.log("Home", "getWorksData"); //TODO: Remove later when end this project
    await this.props.getWorksData()
  }

  async getClientsData() {
    //console.log("Home", "getClientsData"); //TODO: Remove later when end this project
    await this.props.getClientsData()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hash } = nextProps.history.location
    let location = hash.split('#')[1];
    let id = '';

    if (location) {
      location = String(location).replace(/^\/|\/$/g, '') //Trim backslash at begin & end positions
    } else {
      location = LOCATION.HOME;
    }

    let path = location.split('/');
    if (path.length === 3 &&
      (location.indexOf(LOCATION.DEVELOPMENT_DETAIL) === 0 ||
        location.indexOf(LOCATION.DIGITAL_MEDIA_DETAIL) === 0)) {
      id = path[2] ? path[2] : 0;
      path.pop();
    }
    let locationObj = {
      hash: location,
      key: path.join('/'),
      id: id
    }

    let foundLocation = false;
    for (let key in LOCATION) {
      if (LOCATION[key] === locationObj.key) {
        foundLocation = true;
        break;
      }
    }
    if (!foundLocation) {
      locationObj.hash = LOCATION.HOME;
      locationObj.key = LOCATION.HOME;
    }

    //console.log("%%%%%%%%%%%%%%%%%%%%%%%%%% Home", "componentWillReceiveProps:", locationObj.key, locationObj.id); //TODO: Remove later when end this project
    if (locationObj.hash === this.state.anchorLocation) {
      return;
    } else {
      if (this.state.isMenu && this.mainMenu.state.open) {
        //console.log("Close main menu auto when #hash is changed !!"); //TODO: Remove later when end this project
        this.mainMenu.closeThisPanel()
      }
      this.gotoPage(locationObj);
    }
  }

  setHash(location, id = '') {
    //console.log("Home", "setHash", location, id); //TODO: Remove later when end this project
    if (!location && location !== LOCATION.HOME) return;

    const page = this.updateTitlePage(location);
    let hash = LOCATION.HOME;
    let delayUnlock = 1500;
    if (location !== LOCATION.HOME) {
      location = location + (id ? `/${id}` : '');
      hash = `/#${location}`;
      delayUnlock = 500;
    }

    this.setState({
      currentPage: page,
      prevLocation: this.state.anchorLocation,
      anchorLocation: location
    }, () => {
      this.props.history.push(hash)
      setTimeout(() => this.setLock(false), delayUnlock)
    })
  }

  setLock(status) {
    const { isLock } = this.state
    if (isLock === status) return
    //console.log("Home", "setLock - isLock:", status); //TODO: Remove later when end this project
    this.setState({ isLock: status })
  }

  updateTitlePage(location) {
    let page = 0;
    switch (location) {
      case LOCATION.HOME:
        page = PAGE.HOME;
        document.title = 'SuperScript Media | Digital Agency'
        break
      case LOCATION.WHO_WE_ARE:
        page = PAGE.WHO_WE_ARE;
        document.title = 'Who We Are | SuperScript Media | Digital Agency'
        break
      case LOCATION.DEVELOPMENT:
        page = PAGE.DEVELOPMENT;
        document.title = 'Our Works | SuperScript Media | Digital Agency'
        break
      case LOCATION.DIGITAL_MEDIA:
        page = PAGE.DIGITAL_MEDIA;
        document.title = 'Our Works | SuperScript Media | Digital Agency'
        break
      case LOCATION.DEVELOPMENT_DETAIL:
        page = PAGE.DEVELOPMENT_DETAIL;
        document.title = 'Our Works | SuperScript Media | Digital Agency'
        break
      case LOCATION.DIGITAL_MEDIA_DETAIL:
        page = PAGE.DIGITAL_MEDIA_DETAIL;
        document.title = 'Our Works | SuperScript Media | Digital Agency'
        break
      case LOCATION.OUR_SERVICE:
        page = PAGE.OUR_SERVICE;
        document.title = 'Our Services | SuperScript Media | Digital Agency'
        break
      case LOCATION.OUR_CLIENT:
        page = PAGE.OUR_CLIENT;
        document.title = 'Our Clients | SuperScript Media | Digital Agency'
        break
      case LOCATION.CONTACT_US:
        page = PAGE.CONTACT_US;
        document.title = 'Contact Us | SuperScript Media | Digital Agency'
        break
      default:
        page = PAGE.BLANK;
        document.title = 'SuperScript Media | Digital Agency'
        break
    }

    return page;
  }

  //------------------------- CHANGE PAGE -------------------------//
  nextPage(event = null) {
    const { isMenu, isLock, isChanging, currentPage } = this.state
    if (isMenu || isLock || isChanging || currentPage === PAGE.CONTACT_US) return

    var scrollTop = null;
    var scrollHeight = null;
    var panelHeight = null;
    if (currentPage === PAGE.OUR_CLIENT) {
      scrollTop = $(".our-client-panel__client").scrollTop();
      scrollHeight = $(".our-client-panel__client").prop("scrollHeight");
      panelHeight = $(".our-client-panel__client").height();
    }
    if (currentPage === PAGE.CONTACT_US) {
      scrollTop = $(".contact-us-wrapper").scrollTop();
      scrollHeight = $(".contact-us-wrapper").prop("scrollHeight");
      panelHeight = $(".contact-us-wrapper").height();
    }
    if (event !== null) {
      if (scrollTop === null || scrollHeight - panelHeight <= 0) {
        //event.preventDefault();
      }
      if (scrollTop < scrollHeight - panelHeight) {
        return
      }
    }

    this.setLock(true)

    switch (currentPage) {
      case PAGE.HOME:
        this.goWhoWeAre()
        break;
      case PAGE.WHO_WE_ARE:
        this.goOurClient()
        break;
      case PAGE.OUR_CLIENT:
        this.goContactUs()
        break;
      default:
        //console.log("No next page !!");
        this.setLock(false)
        break;
    }
  }

  prevPage(event = null) {
    const { isMenu, isLock, isChanging, currentPage } = this.state
    if (isMenu || isLock || isChanging || currentPage === PAGE.HOME) return

    var scrollTop = null;
    var scrollHeight = null;
    var panelHeight = null;
    if (currentPage === PAGE.OUR_CLIENT) {
      scrollTop = $(".our-client-panel__client").scrollTop();
      scrollHeight = $(".our-client-panel__client").prop("scrollHeight");
      panelHeight = $(".our-client-panel__client").height();
    }
    if (currentPage === PAGE.CONTACT_US) {
      scrollTop = $(".contact-us-wrapper").scrollTop();
      scrollHeight = $(".contact-us-wrapper").prop("scrollHeight");
      panelHeight = $(".contact-us-wrapper").height();
    }
    if (event !== null) {
      if (scrollTop === null || scrollHeight - panelHeight <= 0) {
        event.preventDefault();
      }
      if (scrollTop > 0) {
        return
      }
    }

    this.setLock(true)

    switch (currentPage) {
      case PAGE.WHO_WE_ARE:
        this.closeWhoWeAre()
        break;
      case PAGE.OUR_CLIENT:
        this.closeOurClient()
        break;
      case PAGE.CONTACT_US:
        this.closeContactUs()
        break;
      default:
        //console.log("No prev page !!");
        this.setLock(false)
        break;
    }
  }

  gotoPage(locationObj) {
    const { isLock, isChanging, currentPage, anchorLocation } = this.state
    if (isLock || isChanging) return

    const targetPage = this.updateTitlePage(locationObj.key);
    //console.log("Home", "gotoPage"); //TODO: Remove later when end this project
    //console.log("location:", locationObj.key);
    //console.log("id:", locationObj.id);
    //console.log("prevLocation:", anchorLocation);
    //console.log("currentPage:", currentPage);
    //console.log("targetPage:", targetPage);
    this.setState({
      prevLocation: anchorLocation,
      anchorLocation: locationObj.hash
    })

    const pageList = this.getPageList(currentPage, targetPage);
    //console.log("pageList:", pageList);
    this.changePage(currentPage, pageList, locationObj.id)
  }

  changePage(currentPage, pageList = [], id = '') {
    if (!pageList || pageList.length === 0) {
      //console.log("changePage - rejected !!");
      this.setState({ isChanging: false })
      return
    }

    const nextPage = pageList.shift();
    if (nextPage === currentPage &&
      ((nextPage !== PAGE.DEVELOPMENT_DETAIL && nextPage !== PAGE.DIGITAL_MEDIA_DETAIL) ||
        ((nextPage === PAGE.DEVELOPMENT_DETAIL || nextPage === PAGE.DIGITAL_MEDIA_DETAIL) && pageList.length > 0))) {
      //console.log("changePage - skip !!");
      this.changePage(currentPage, pageList, id)
      return
    } else {
      //console.log("changePage:", currentPage, "=>", nextPage, "id:", id);
      this.setLock(true)
      this.setState({ isChanging: true })
    }

    switch (currentPage) {
      case PAGE.HOME:
        this.goWhoWeAre(() => {
          setTimeout(() => this.changePage(nextPage, pageList, id), 500)
        })
        break;
      case PAGE.WHO_WE_ARE:
        if (nextPage === PAGE.OUR_CLIENT) {
          this.goOurClient(() => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        } else if (nextPage === PAGE.DEVELOPMENT) {
          this.goOurWork('DEVELOPMENT', () => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        } else if (nextPage === PAGE.DIGITAL_MEDIA) {
          this.goOurWork('DIGITAL_MEDIA', () => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        } else if (nextPage === PAGE.OUR_SERVICE) {
          this.goOurService(() => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        } else { // PAGE.HOME
          this.closeWhoWeAre(() => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        }
        break;
      case PAGE.DEVELOPMENT:
      case PAGE.DIGITAL_MEDIA:
        if (nextPage === PAGE.DEVELOPMENT_DETAIL || nextPage === PAGE.DIGITAL_MEDIA_DETAIL) {
          this.goWorkDetail(id, () => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        } else { // PAGE.WHO_WE_ARE
          this.closeOurWork(() => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        }
        break;
      case PAGE.DEVELOPMENT_DETAIL:
      case PAGE.DIGITAL_MEDIA_DETAIL:
        if (nextPage === PAGE.DEVELOPMENT_DETAIL || nextPage === PAGE.DIGITAL_MEDIA_DETAIL) {
          this.goWorkDetail(id, () => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        } else { // PAGE.DEVELOPMENT, PAGE.DIGITAL_MEDIA
          this.closeWorkDetail(() => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        }
        break;
      case PAGE.OUR_SERVICE:
        this.closeOurService(() => {
          setTimeout(() => this.changePage(nextPage, pageList, id), 500)
        })
        break;
      case PAGE.OUR_CLIENT:
        if (nextPage === PAGE.CONTACT_US) {
          this.goContactUs(() => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        } else { // PAGE.WHO_WE_ARE
          this.closeOurClient(() => {
            setTimeout(() => this.changePage(nextPage, pageList, id), 500)
          })
        }
        break;
      case PAGE.CONTACT_US:
        this.closeContactUs(() => {
          setTimeout(() => this.changePage(nextPage, pageList, id), 500)
        })
        break;
      default:
        //console.log("changePage - unknown current page !!");
        this.setLock(false)
        this.setState({ isChanging: false })
        break;
    }
  }

  getPageList(current, target) {
    let mainList = [PAGE.HOME, PAGE.WHO_WE_ARE, PAGE.OUR_CLIENT, PAGE.CONTACT_US];
    let subList1 = [PAGE.DEVELOPMENT, PAGE.DEVELOPMENT_DETAIL];
    let subList2 = [PAGE.OUR_SERVICE];
    let pageList = [];
    let pageList1 = [];
    let pageList2 = [];

    // Find main page list
    if (current < target) {
      pageList = mainList.slice(Math.round(current / 100), Math.round(target / 100) + 1);
    } else {
      pageList = mainList.slice(Math.round(target / 100), Math.round(current / 100) + 1).reverse();
    }

    // Find sub page list for the development side
    if (current >= PAGE.DEVELOPMENT && current < PAGE.OUR_SERVICE) {
      let target1 = PAGE.DEVELOPMENT;
      if (target >= PAGE.DEVELOPMENT && target < PAGE.OUR_SERVICE) {
        target1 = target;
        pageList = [];
      }
      if (current < target1) {
        pageList1 = subList1.slice((current) % 10, (target1 % 10) + 1);
      } else {
        pageList1 = subList1.slice((target1) % 10, (current % 10) + 1).reverse();
      }
      pageList = pageList1.concat(pageList);
    } else if (target >= PAGE.DEVELOPMENT && target < PAGE.OUR_SERVICE) {
      pageList = pageList.concat(subList1.slice(0, (target % 10) + 1));
    }

    // Find sub page list for the digital media side
    if (current >= PAGE.OUR_SERVICE && current < PAGE.OUR_CLIENT) {
      let target2 = PAGE.OUR_SERVICE;
      if (target >= PAGE.OUR_SERVICE && target < PAGE.OUR_CLIENT) {
        target2 = target;
        pageList = [];
      }
      if (current < target2) {
        pageList2 = subList2.slice((current) % 10, (target2 % 10) + 1);
      } else {
        pageList2 = subList2.slice((target2) % 10, (current % 10) + 1).reverse();
      }
      pageList = pageList2.concat(pageList);
    } else if (target >= PAGE.OUR_SERVICE && target < PAGE.OUR_CLIENT) {
      pageList = pageList.concat(subList2.slice(0, (target % 10) + 1));
    }

    return pageList;
  }

  //------------------------- WHO WE ARE -------------------------//
  goWhoWeAre(complete = () => { }) {
    //console.log("Home", "goWhoWeAre"); //TODO: Remove later when end this project
    let delayWhoWeAre = 0;
    this.setState({ showTrouble: false })

    if (!this._isPortableDevice) {
      this.digitalSolution.startAnimation()
      TweenMax.to($('.logo-app img'), 2, { y: -100, alpha: 0, ease: Power4.easeInOut })
      TweenMax.to($('.intro-animate-text__message'), 1, { delay: 2.5, alpha: 0, ease: Power4.easeInOut })
      delayWhoWeAre = 2.5;
    } else {
      TweenMax.to($('.logo-app img'), 1, { y: -100, alpha: 0, ease: Power4.easeInOut })
      TweenMax.to($('.intro-animate-text'), 1, { alpha: 0, ease: Power4.easeInOut })
      delayWhoWeAre = 0;
    }

    TweenMax.delayedCall(delayWhoWeAre, () => {
      if (!this.state.isMobile) {
        TweenMax.fromTo($('.platform-panel-wrapper.intro'), 1,
          { opacity: 0, top: '20%', display: 'block' },
          { delay: 1, opacity: 1, top: 0, ease: Power4.easeInOut }
        )
      } else {
        TweenMax.set($('.platform-panel-wrapper.intro'), { opacity: 0, top: '20%', display: 'none' })
      }

      this.whoWeAre.animStart(() => {
        this.setState({ isPlatForm: true, isOurClient: true })
        if (complete) complete()
      })
    })
  }

  closeWhoWeAre(complete = () => { }) {
    //console.log("Home", "closeWhoWeAre"); //TODO: Remove later when end this project
    const { isOurClient, isPlatForm } = this.state

    if (isOurClient) {
      this.ourClient.dropControl(() => {
        //console.log("Home", "isOurClient:", false); //TODO: Remove later when end this project
        this.setState({ isOurClient: false })
      })
    }

    if (isPlatForm) {
      this.platform.outPlatform(() => {
        //console.log("Home", "isPlatForm:", false); //TODO: Remove later when end this project
        this.setState({
          isPlatForm: false
        }, () => {
          this.setState({ showTrouble: true })
          TweenMax.set($('.platform-panel-wrapper.intro'), { opacity: 0, top: '20%', display: 'none' })

          if (!this._isPortableDevice) {
            //console.log("Home", "outPlatform:", "Desktop Device"); //TODO: Remove later when end this project
            this.digitalSolution.reversTonomal()
            TweenMax.to($('.logo-app img'), 1, {
              y: 0, alpha: 1, ease: Power4.easeInOut, onComplete: () => {
                TweenMax.to($('.intro-animate-text__message'), 1, { alpha: 1, ease: Power4.easeInOut })
              }
            })
          } else {
            //console.log("Home", "outPlatform:", "Mobile Device"); //TODO: Remove later when end this project
            TweenMax.to($('.logo-app img'), 1, { y: 0, alpha: 1, ease: Power4.easeInOut })
            TweenMax.to($('.intro-animate-text'), 1, { alpha: 1, ease: Power4.easeInOut })
          }

          this.whoWeAre.reversTonomal(() => {
            this.setHash(LOCATION.HOME);
            if (complete) complete()
          })
        })
      })
    }
  }

  checkPlatformSubPage(platform) {
    //console.log("Home", "checkPlatformSubPage", platform); //TODO: Remove later when end this project
    if (platform !== 'WHO_WE_ARE') { //Such as DEVELOPMENT, DIGITAL_MEDIA, OUR_SERVICE
      this.ourClient.dropControl()
    } else { //Case WHO_WE_ARE
      this.ourClient.startControl()
    }
  }

  //------------------------- OUR WORK -------------------------//
  goOurWork(platform, complete = () => { }) {
    //console.log("Home", "goOurWork", platform); //TODO: Remove later when end this project
    this.setState({
      isPlatForm: true
    }, () => {
      this.platform.goWorkPage(platform, () => {
        if (complete) complete()
      })
    })
  }

  closeOurWork(complete = () => { }) {
    //console.log("Home", "closeOurWork"); //TODO: Remove later when end this project
    this.setState({
      isPlatForm: true
    }, () => {
      this.platform.closeWorkPage(() => {
        if (complete) complete()
      })
    })
  }

  //------------------------- OUR WORK DETAIL -------------------------//
  goWorkDetail(id, complete = () => { }) {
    //console.log("Home", "goWorkDetail", id); //TODO: Remove later when end this project
    this.setState({
      isPlatForm: true
    }, () => {
      this.platform.goDetailPage(id, () => {
        if (complete) complete()
      })
    })
  }

  closeWorkDetail(complete = () => { }) {
    //console.log("Home", "closeWorkDetail"); //TODO: Remove later when end this project
    this.setState({
      isPlatForm: true
    }, () => {
      this.platform.closeDetailPage(() => {
        if (complete) complete()
      })
    })
  }

  //------------------------- OUR SERVICE -------------------------//
  goOurService(complete = () => { }) {
    //console.log("Home", "goOurService"); //TODO: Remove later when end this project
    this.setState({
      isPlatForm: true
    }, () => {
      this.platform.goServicePage(() => {
        if (complete) complete()
      })
    })
  }

  closeOurService(complete = () => { }) {
    //console.log("Home", "closeOurService"); //TODO: Remove later when end this project
    this.setState({
      isPlatForm: true
    }, () => {
      this.platform.closeServicePage(() => {
        if (complete) complete()
      })
    })
  }

  //------------------------- OUR CLIENT -------------------------//
  goOurClient(complete = () => { }) {
    //console.log("Home", "goOurClient"); //TODO: Remove later when end this project
    this.setState({
      isPlatForm: true,
      isOurClient: true
    }, () => {
      this.ourClient.togglePanel(() => {
        if (complete) complete()
      }, true)
    })
  }

  closeOurClient(complete = () => { }) {
    //console.log("Home", "closeOurClient"); //TODO: Remove later when end this project
    this.setState({
      isPlatForm: true,
      isOurClient: true
    }, () => {
      this.ourClient.togglePanel(() => {
        if (complete) complete()
      }, false)
    })
  }

  //------------------------- CONTACT US -------------------------//
  goContactUs(complete = () => { }) {
    this.setLock(true);
    //console.log("Home", "goContactUs"); //TODO: Remove later when end this project
    this.setState({
      isContactUs: true
    }, () => {
      this.contactUs.openContactus(() => {
        this.setHash(LOCATION.CONTACT_US)
        if (complete) complete()
      })
    })
  }

  closeContactUs(complete = () => { }) {
    const { isContactUs } = this.state
    if (isContactUs) {
      this.setLock(true);
      //console.log("Home", "closeContactUs"); //TODO: Remove later when end this project
      this.contactUs.closeContactus(() => {
        this.setState({ isContactUs: false })
        this.setHash(LOCATION.OUR_CLIENT)
        if (complete) complete()
      })
    }
  }

  //------------------------- MAIN MENU -------------------------//
  openMainMenu() {
    this.setState({ isMenu: true })
    //console.log("Home", "openMainMenu - isMenu:", true); //TODO: Remove later when end this project
  }

  closeMainMenu() {
    this.setState({ isMenu: false })
    //console.log("Home", "closeMainMenu - isMenu:", false); //TODO: Remove later when end this project
  }

  //------------------------- RENDER -------------------------//
  render() {
    const { isLock, isChanging, isPlatForm, isOurClient, isContactUs, isHome, isMenu, isMobile, currentPage, contentHeight } = this.state

    return (
      <>
        <div className="bs4">
          <div className="fixed-bottom detect-bottom"></div>
        </div>
        <div ref={ref => this.home = ref} className="intro-wrapper" style={{ height: contentHeight }}>
          {isHome && this.homeContent()}
          {isPlatForm &&
            <PlatformPanel
              isMobile={isMobile}
              theRef={ref => this.platform = ref}
              checkSubPage={(platform) => this.checkPlatformSubPage(platform)}
              onClickClose={(complete) => this.closeWhoWeAre(complete)}
              setHash={(location, id) => this.setHash(location, id)}
              setLock={(status) => this.setLock(status)}
            />
          }
          {isOurClient &&
            <OurClients
              theRef={ref => this.ourClient = ref}
              goContactUs={(complete) => this.goContactUs(complete)}
              setHash={(location) => this.setHash(location)}
              setLock={(status) => this.setLock(status)}
            />
          }
          {isContactUs &&
            <ContactUs
              theRef={ref => this.contactUs = ref}
              onClickClose={(complete) => this.closeContactUs(complete)}
              setHash={(location) => this.setHash(location)}
              setLock={(status) => this.setLock(status)}
            />
          }
          {isMenu &&
            <MainMenu
              theRef={ref => this.mainMenu = ref}
              history={this.props.history}
              onClickClose={() => this.closeMainMenu()}
              setHash={(location) => this.setHash(location)}
              setLock={(status) => this.setLock(status)}
            />
          }
          {/*<LogoMini onClickOpen={() => this.openMainMenu()} />*/}
          <Navbar
            isMobile={isMobile}
            theRef={ref => this.navbar = ref}
            page={currentPage}
            isLock={isLock}
            isChanging={isChanging}
            history={this.props.history}
            setLock={(status) => this.setLock(status)}
          />
        </div>
      </>
    )
  }

  // Render home page for desktop
  homeContent() {
    const { isLock, isPlatForm, currentPage, isMobile, showTrouble } = this.state
    return (
      <>
        <div className="logo-app bs4">
          <img className="img-fluid" src="/assets/images/svg/logo.svg" alt="Super Script Media" />
        </div>
        <div className="intro-animate-text bs4">
          {
            this._isPortableDevice
              ? <div className="text-bold text-center">DIGITAL SOLUTIONS</div>
              : <DigitalSolution ref={ref => this.digitalSolution = ref} />
          }
          <p className="intro-animate-text__message">for  LifeStyle  Bussiness</p>
        </div>
        {
          <div className="bs4">
            <div className={`btn-superscriptmedia opacity-transition ${showTrouble && 'show'}`}>
              <div>Trouble Viewing Website?</div>
              <a href="http://www.superscriptmedia.co.th/v1/">Switch to Non-Animated Version</a>
            </div>
          </div>
        }
        <div className="intro-animate-action">
          <div className="who-we-are position-absolute top-0">
            <WhoWeAre
              isMobile={isMobile}
              ref={ref => this.whoWeAre = ref}
            />
          </div>
        </div>
        {!isPlatForm && <div className="platform-panel-wrapper intro">
          <WhoWeAreWording />
        </div>}
        {(!isLock && currentPage === PAGE.HOME) && <span className="click-area" onClick={() => {
          this.setLock(true)
          this.goWhoWeAre()
        }} />}
      </>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    getWorksData: Action.getWorksData,
    getClientsData: Action.getClientsData,
    setLogo: (value) => dispatch({ type: 'IS_COLOR', payload: value })
  }

  return bindActionCreators(action, dispatch)
}

const stateToProps = (state) => {
  return {
    worksData: state.worksData,
    clientsData: state.clientsData
  }
}

export default connect(stateToProps, dispatchToProps)(Home)
