import React from 'react'
import { TweenMax, Power4 } from 'gsap/all';
import $ from 'jquery'

class WhoWeAre extends React.Component {
  constructor(props) {
    super(props)
    
    this.animContainer = React.createRef()
    this.triangle = React.createRef()
  }

  componentDidMount() {
    //console.log("***************************** WhoWeAre"); //TODO: Remove later when end this project
    TweenMax.set($('.action-text__poloy--x.pink'), { top: '50%', opacity: 0 })
    TweenMax.set($('.action-text__poloy--x.pink-trans'), { top: '50%', opacity: 0 })
  }

  animStart(complete = () => {}) {
    TweenMax.to($('#who'), 1, { x: 150, ease: Power4.easeInOut })
    TweenMax.to($('#we'), 1, { x: -50, ease: Power4.easeInOut })
    TweenMax.to($('#are'), 1, { x: -100, ease: Power4.easeInOut })
    TweenMax.to($('.action-text__poloy'), 1, { scale: 100, zIndex: 1000, ease: Power4.easeInOut, onComplete: () => {
      if (!this.props.isMobile) {
        TweenMax.set($('.action-text__poloy--x.pink'), {  opacity: 1 })
        TweenMax.set($('.action-text__poloy--x.pink-trans'), {  opacity: 1 })
        TweenMax.to($('.action-text__poloy--x.pink'), 1,{ top: '-1%', opacity: 1, ease: Power4.easeInOut })
        TweenMax.to($('.action-text__poloy--x.pink-trans'), 1,{ top: '-1%', opacity: 1, ease: Power4.easeInOut, onComplete: () => complete() })
      } else {
        complete()
      }
    }})
    //console.log("WhoWeAre", "animStart"); //TODO: Remove later when end this project
  }

  reversTonomal(complete = () => {}) {
    if (!this.props.isMobile) {
      TweenMax.set($('.action-text__poloy--x.pink'), {  top: '-1%', opacity: 1 })
      TweenMax.set($('.action-text__poloy--x.pink-trans'), {  top: '-1%', opacity: 1 })
    } else {
      TweenMax.set($('.action-text__poloy--x.pink'), { top: '50%', opacity: 0 })
      TweenMax.set($('.action-text__poloy--x.pink-trans'), { top: '50%', opacity: 0 })
    }
    TweenMax.to($('#who'), 1, { x: 0, ease: Power4.easeInOut })
    TweenMax.to($('#we'), 1, { x: 0, ease: Power4.easeInOut })
    TweenMax.to($('#are'), 1, { x: 0, ease: Power4.easeInOut })
    TweenMax.to($('.action-text__poloy--x.pink'), 0.6,{ top: '50%', ease: Power4.easeInOut })
    TweenMax.to($('.action-text__poloy--x.pink-trans'), 0.6,{ top: '50%', ease: Power4.easeInOut, onComplete: () => {
      TweenMax.set($('.action-text__poloy--x.pink'), {  opacity: 0 })
      TweenMax.set($('.action-text__poloy--x.pink-trans'), {  opacity: 0 })
    }})
    TweenMax.to($('.action-text__poloy'), 1, { scale: 1, zIndex: 1000, ease: Power4.easeInOut, onComplete:() => {
      complete()
    } })
    //console.log("WhoWeAre", "reversTonomal"); //TODO: Remove later when end this project
  }

  render() {
    return (
      <div ref={ref => this.animContainer = ref} className="action-content-wrapper">
        <div className="action-content-order">
          <div className="action-text who">
            <span id="who" className="action-text__span">WHO</span>
          </div>
          <div className="action-text__poloy">
            <span className="action-text__poloy--v pink"/>
            <span className="action-text__poloy--v pink-trans"/>

            <span className="action-text__poloy--x pink"/>
            <span className="action-text__poloy--x pink-trans"/>
          </div>
          <div className="action-text we">
            <span id="we" className="action-text__span">E</span>
          </div>
          <div className="action-text are">
            <span id="are" className="action-text__span">ARE</span>
          </div>
        </div>
      </div>
    )
  }
}

export default WhoWeAre
