import React, { Component } from 'react'
import Slider from "react-slick";
import $ from 'jquery'
import { TweenMax, Power4 } from 'gsap/all';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { LOCATION } from '../constants';
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

class OurServices extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false;
    this._onResizeWindow = this.onResizeWindow.bind(this);
    this.slickMenu = React.createRef()

    this.state = {
      isMobile: false,
      animateTimeoutID: null,
      selectIndex: 1,
      targetIndex: 1,
      iconWidth: 240,
      iconHeight: 208,
      iconMargin: 4,
      iconList: [
        {
          title: "Digital Strategy",
          icon: "/assets/images/png/ourservices/icon1.png",
          bg: "bg-pink",
          animate: "left-animate",
          position: { x: 0, y: 0 }
        },
        {
          title: "Website / eCommerce",
          icon: "/assets/images/png/ourservices/icon2.png",
          bg: "bg-yellow",
          animate: "right-animate",
          position: { x: -1, y: 0 }
        },
        {
          title: "Mobile Applications",
          icon: "/assets/images/png/ourservices/icon3.png",
          bg: "bg-blue",
          animate: "top-animate",
          position: { x: -1, y: 1 }
        },
        {
          title: "Enterprise Solutions",
          icon: "/assets/images/png/ourservices/icon4.png",
          bg: "bg-green",
          animate: "left-animate",
          position: { x: 0, y: 1 }
        },
        {
          title: "Interactive Activations",
          icon: "/assets/images/png/ourservices/icon5.png",
          bg: "bg-yellow",
          animate: "top-animate",
          position: { x: 0, y: 2 }
        },
        {
          title: "Digital Transformation",
          icon: "/assets/images/png/ourservices/icon9.png",
          bg: "bg-pink",
          animate: "right-animate",
          position: { x: -1, y: 2 }
        },
        {
          title: "UX / UI Design",
          icon: "/assets/images/png/ourservices/icon6B.png",
          bg: "bg-blue",
          animate: "top-animate",
          position: { x: -1, y: 3 }
        },
        {
          title: "Online Advertising",
          icon: "/assets/images/png/ourservices/icon7B.png",
          bg: "bg-green",
          animate: "right-animate",
          position: { x: -2, y: 3 }
        },
        {
          title: "Social Media Management",
          icon: "/assets/images/png/ourservices/icon8B.png",
          bg: "bg-yellow",
          animate: "right-animate",
          position: { x: -3, y: 3 }
        },
      ],
      settings: {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        centerPadding: 0,
        slidesToShow: 1,
        centerMode: true,
        adaptiveHeight: true,
        //variableWidth: true,
        beforeChange: (oldIndex, newIndex) => {
          this.selectMenu(newIndex+1)
        },
        customPaging: i => <button className={this.state.iconList[i].bg}>{i + 1}</button>
      },
      responsive: {
        icon: "col-12",
        menu: "col-12",
        slick: "col-12"
      }
    }
  }

  componentDidMount() {
    //console.log("***************************** OurServices");
    this._isMounted = true;
    this.props.setLogo(true)
    const { theRef } = this.props
    if (theRef) theRef(this)

    $( ".our-services" ).scrollTop(0)
    setTimeout(() => { $( ".our-services" ).scrollTop(0) }, 1000);
    TweenMax.set($('.our-services'), { opacity: 0 })
    this.transitionIn()
    this.onResizeWindow();
    window.addEventListener("resize", this._onResizeWindow);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this._onResizeWindow);
  }

  onResizeWindow() {
    this.resizeWindow();
    //setTimeout(this.resizeWindow.bind(this), 1000);
  }

  resizeWindow() {
    if (!this._isMounted) return;
    if (window.innerWidth >= 768 && window.innerHeight >= 660 && window.innerHeight < 860) { // Case two columns menu
      this.setState({
        isMobile: false,
        iconWidth: 240 * (window.innerWidth >= 992 ? 1.6 : 1.2),
        iconHeight: 208 * (window.innerWidth >= 992 ? 1.6 : 1.2),
        responsive: {
          icon: "col-5 col-lg-4 offset-1 offset-lg-2 my-auto px-0",
          menu: "col-6 col-lg-4 my-auto px-0 ",
          slick: "d-none"
        }
      })
    } else if (window.innerHeight < 860) { // Case slick menu
      this.setState({
        isMobile: true,
        iconWidth: 240 * ((window.innerWidth >= 576 && window.innerHeight >= 560) ? 1 : 0.8),
        iconHeight: 208 * ((window.innerWidth >= 576 && window.innerHeight >= 560) ? 1 : 0.8),
        responsive: {
          icon: (window.innerWidth >= 568 && window.innerHeight < 560) ? (window.innerWidth >= 678 ? "offset-1" : "") + " col-5" : "col-12",
          menu: "d-none",
          slick: (window.innerWidth >= 568 && window.innerHeight < 560) ? (window.innerWidth >= 678 ? "col-6" : "col-7") + " my-n5 mx-auto px-0" : "col-12 mx-auto px-0"
        }
      })
    } else { // Case one column menu
      this.setState({
        isMobile: window.innerWidth < 576 ? true : false,
        iconWidth: 240 * (window.innerWidth >= 576 ? 1 : 0.8),
        iconHeight: 208 * (window.innerWidth >= 576 ? 1 : 0.8),
        responsive: {
          icon: "col-12",
          menu: "col-12 d-none d-sm-block px-0",
          slick: "col-12 d-sm-none px-0"
        }
      })
    }
  }

  transitionIn() {
    const { setHash } = this.props
    if (setHash) setHash(LOCATION.OUR_SERVICE)

    TweenMax.to($('.our-services'), 1,
      {
        opacity: 1,
        ease: Power4.easeInOut
      }
    )

    //console.log("OurServices", "transitionIn"); //TODO: Remove later when end this project
  }

  backToMainPage(complete = () => {}) {
    this.props.setLogo(false)
    const { onClosePanel, setLock } = this.props
    setLock(true);

    TweenMax.to($('.our-services'), 0.5,
      {
        opacity: 0,
        ease: Power4.easeInOut,
        onComplete: () => onClosePanel(complete)
      }
    )

    //console.log("OurServices", "backToMainPage"); //TODO: Remove later when end this project
  }

  selectMenu(index) {
    this.setState({
      targetIndex: index
    }, () => {
      this.slickMenu.slickGoTo(index-1, false)
      this.animateMenu()
    })
  }

  animateMenu() {
    const { selectIndex, targetIndex, animateTimeoutID } = this.state
    let nextIndex = 1;

    if (targetIndex > selectIndex) {
      nextIndex = selectIndex + 1
    } else if (targetIndex < selectIndex) {
      nextIndex = selectIndex - 1
    } else {
      return;
    }

    clearTimeout(animateTimeoutID);
    let timeoutID = setTimeout(() => { this.animateMenu() }, 100)
    this.setState({
      animateTimeoutID: timeoutID,
      selectIndex: nextIndex
    })
  }

  renderServiceIcon() {
    const { selectIndex, targetIndex, iconList, iconWidth, iconHeight, iconMargin } = this.state
    let item = iconList[targetIndex-1]
    if (!item) item = {position: { x: 0, y: 0 }}
    const logoX = -item.position.x * (iconWidth * .5 + iconMargin)
    const logoY = -item.position.y * (iconHeight + iconMargin)

    return (
      <div className="logo-container" style={{ "marginTop": iconHeight + "px" }}>
        <div className="logo-wrapper" style={{ left: logoX + "px", top: logoY + "px" }}>
          {
            iconList.map((item, index) => {
              const posX = item.position.x * (iconWidth * .5 + iconMargin) - iconWidth * .5
              const posY = item.position.y * (iconHeight + iconMargin) - iconHeight
              const dataX = -(posX + iconWidth * .5)
              const dataY = -(posY + iconHeight)

              let mask = "clip-tri-down"
              let points = "0,0 50,100 100,0"
              if (index % 2 === 1) {
                mask = "clip-tri-up"
                points = "0,100 50,0 100,100"
              }

              return (
                <div key={index} data-x={dataX} data-y={dataY}
                  className={`tri-container ${(index+1) === selectIndex ? "show" : ""} ${mask}`}
                  style={{ left: posX + "px", top: posY + "px", width: iconWidth + "px", height: iconHeight + "px" }}>
                  <div className="svg-wrapper">
                    <svg viewBox="0 0 100 100" width="100%" height="100%" preserveAspectRatio="none">
                      <polygon points={points} vectorEffect="non-scaling-stroke"></polygon>
                    </svg>
                  </div>
                  {item.icon &&
                    <div className={`icon-wrapper d-flex ${item.bg}`}>
                      <img className={`img-fluid m-auto ${item.animate}`} src={item.icon} alt={item.title} />
                    </div>
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  renderServiceMenu() {
    const { targetIndex, iconList } = this.state
    return (
      <ul className="menu-container my-4 pb-0 px-0 text-center">
        {
          iconList.map((item, index) => {
            if (!item.title) return ''
            return (
              <li key={index} className="mb-1">
                <div className={`menu-wrapper col-auto d-inline-block px-0 py-2 hover ${(index+1) === targetIndex ? "show" : ""}`}
                  onMouseOver={() => this.selectMenu(index+1)}
                  onClick={() => this.selectMenu(index+1)}>
                  <div className="px-4" dangerouslySetInnerHTML={{ __html: item.title }}></div>
                  <div className={`text-highlight py-2 ${item.bg}`}>
                    <div className="px-4" dangerouslySetInnerHTML={{ __html: item.title }}></div>
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
    )
  }

  renderServiceSlick() {
    const { targetIndex, iconList, settings } = this.state

    return (
      <Slider ref={ref => this.slickMenu = ref} {...settings} className="menu-container text-center menu-margin">
        {
          iconList.map((item, index) => {
            if (!item.title) return ''
            return (
              <div key={index} className="menu-padding">
                <div className={`menu-wrapper col-auto d-inline-block px-0 py-2 ${(index+1) === targetIndex ? "show" : ""}`}>
                  <div className="px-4" dangerouslySetInnerHTML={{ __html: item.title }}></div>
                  <div className={`text-highlight py-2 ${item.bg}`}>
                    <div className="px-4" dangerouslySetInnerHTML={{ __html: item.title }}></div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </Slider>
    )
  }

  render() {
    const { responsive, isMobile } = this.state
    return (
      <div className="our-services bs4">
        <div className="btn-close-panel" onClick={() => this.backToMainPage()} />
        <div className="container-fluid pt-5">
          <div className={`row ${window.innerHeight < 560 ? "mb-4" : "mb-5"} ${isMobile ? "mt-3" : "mt-5"}`}>
            <div className="col">
              <h1 className={`text-center my-0 ${isMobile ? "small" : ""}`}>OUR SERVICES</h1>
            </div>
          </div>
          <div className="row">
            <div className={responsive.icon}>
              {this.renderServiceIcon()}
            </div>
            <div className={responsive.menu}>
                {this.renderServiceMenu()}
            </div>
            <div className={responsive.slick}>
                {this.renderServiceSlick()}
            </div>
          </div>
          {<div className="row fixed-bottom hide">
            <div className="col px-5">
              <p className="text-center mb-2">
                DIGITAL SOLUTIONS<br className="d-none d-sm-inline"/>
                FOR LIFESTYLE BUSINESS
              </p>
            </div>
          </div>}
        </div>
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    setLogo: (value) => dispatch({ type: 'IS_COLOR', payload: value })
  }

  return bindActionCreators(action, dispatch)
}

export default connect(null, dispatchToProps)(OurServices)
