import React, { Component } from 'react'


class Thumbnail extends Component {
  
  render() {
    const { item , index, onActionHandler, color} = this.props
    return (
      <div className="col-12 col-sm-6 col-md-4 mb-4">
        <div id={`thumb-${index}`} className="thumb" onClick={() => onActionHandler()}>
          <img className="img-fluid w-100 pointer-none" src={item.thumbimage} alt=""/>
          <div className={`thumb__screen-hover unselectable ${color}`}>
            <span className="thumb__screen-hover--title">{item.brandName}</span>
            <div  className="thumb__screen-hover--footer">
              <span className="type">{item.type}</span>
              <span className="year">{item.year}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Thumbnail
