import React, { Fragment } from 'react';
import { Router, Route, Switch } from "react-router-dom"
import CookieConsent from "react-cookie-consent"; // Document : https://github.com/Mastermindzh/react-cookie-consent#readme
import { LOCATION } from '../constants';
import history from './history';
import Home from '../pages/Home';
import { isIE, isEdge, engineName } from 'react-device-detect';
//import {  browserName, browserVersion, engineVersion } from 'react-device-detect';

class App extends React.Component {
  constructor(props) {
    super(props)

    if ((window.location.protocol !== 'https:') && !window.location.port) {
      window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }
    if (isIE || (isEdge && engineName !== "Blink")) {
      window.location = "https://www.superscriptmedia.co.th/v1";
    }
    //console.log(browserName, browserVersion, ",", engineName, engineVersion);
  }

  render() {
    return (
      <Fragment>
        <div className="background-wrapper">
          <Router history={history}>
            <Switch>
              <Route exact={true} path={LOCATION.HOME} component={Home} />
            </Switch>
          </Router>
          <div className="bs4">
            <CookieConsent
              location="none"
              buttonText="ACCEPT"
              cookieName="consent"
              containerClasses="cookie-consent-popup row"
              contentClasses="content-wrapper col-12"
              buttonWrapperClasses="button-wrapper col-12"
              buttonClasses="accept-button float-right"
              disableStyles="true"
              expires={365}
            >
              <p>We use cookies to personalize and enhance your experience on our site.</p>
              <p>By continuing to browse or by clicking ‘Accept’, you are agreeing to the use of cookies.</p>
            </CookieConsent>
          </div>
        </div>
      </Fragment>
    );
  }
}


export default App;
