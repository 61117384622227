const initialState = {
  isColor: false
}

const logoColor = (state = initialState, action) => {
  switch(action.type) {
    case 'IS_COLOR':
      return {...state, isColor: action.payload }
    default : return state
  }
}

export default logoColor