import React, { Component } from 'react'
import $ from 'jquery'
import { TweenMax, Back, Power4 } from 'gsap/all';
import Thumbnail from './Thumbnail';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DetailPanel from './DetailPanel';
import { LOCATION } from '../constants';

class DevelopmentPanel extends Component {
  constructor(props) {
    super(props)

    this.detail = React.createRef()

    this.state = {
      products: [], //Works in each group (DEVELOPMENT / DIGITAL_MEDIA)
      page: '', //DEVELOPMENT, DIGITAL_MEDIA
      identifyNumber: 0, //Work Index for Detail Page
      isDetail: false //Show Detail Page
    }
  }

  componentDidMount() {
    //console.log("***************************** DevelopmentPanel");
    this.props.setLogo(true)
    const { theRef } = this.props
    if (theRef) theRef(this)

    $( ".development-panel" ).scrollTop(0)
    setTimeout(() => { $( ".development-panel" ).scrollTop(0) }, 1000);
    TweenMax.set($('.development-panel'), { opacity: 0 })
    this.reduceData(this.props.platform)
  }

  UNSAFE_componentWillReceiveProps(nextProp) {
    ////console.log("%%%%%%%%%%%%%%%%%%%%%%%%%% DevelopmentPanel", "componentWillReceiveProps:", nextProp); //TODO: Remove later when end this project
    if (nextProp.pageReducer.reload) {
      //console.log("DevelopmentPanel", "reload", nextProp.pageReducer.page); //TODO: Remove later when end this project
      this.props.reloadPage(null)
      this.reduceData(nextProp.pageReducer.page)
    }
  }

  reduceData(page) {
    const { setHash } = this.props
    const works = this.props.worksData.works
    let filter = []

    //console.log("DevelopmentPanel", "reduceData", page); //TODO: Remove later when end this project

    if (page === 'DEVELOPMENT') {
      filter = works.filter(item => item.partOf === 'development')
      if (setHash) setHash(LOCATION.DEVELOPMENT)
    } else if (page === 'DIGITAL_MEDIA') {
      filter = works.filter(item => item.partOf === 'digital-media')
      if (setHash) setHash(LOCATION.DIGITAL_MEDIA)
    }

    this.setState({ 
      products: filter,
      page: page,
      identifyNumber: 0,
      isDetail: false
    }, () => {
      this.transitionIn()
    })
  }

  transitionIn() {
    const { products } = this.state

    for (const i in products) {
      const thumbnail = $(`#thumb-${i}`)
      const dalay = 1 + (parseInt(i) * 0.2)
      TweenMax.fromTo(thumbnail, 0.5, 
        { alpha: 0, top: 200 },
        { alpha: 1, top: 0, ease: Back.easeOut, delay: dalay }
      )
    }

    TweenMax.fromTo($('.development-panel__title'), 0.5, 
      { 'margin-top': '-100px', opacity: 0 },
      { 'margin-top': '0', opacity: 1, ease: Power4.easeInOut, delay: 0.5 }
    )
    TweenMax.fromTo($('.development-panel__back-btn'), 0.5, 
      { right: 0, opacity: 0 },
      { right: '40px', opacity: 1, ease: Power4.easeInOut, delay: 0.5 }
    )
    TweenMax.to($('.development-panel'), 0.25, { opacity: 1, ease: Power4.easeInOut, delay: 0.25 })

    //console.log("DevelopmentPanel", "transitionIn"); //TODO: Remove later when end this project
  }

  backToMainPage(complete = () => {}) {
    this.props.setLogo(false)
    const { onClosePanel, setLock } = this.props
    setLock(true);
    
    TweenMax.to($('.development-panel'), 0.5, 
      { 
        opacity: 0, 
        ease: Power4.easeInOut, 
        onComplete: () => onClosePanel(complete) 
      }
    )

    //console.log("DevelopmentPanel", "backToMainPage"); //TODO: Remove later when end this project
  }

  selectContent(index, complete = () => {}){
    const { setLock } = this.props
    setLock(true);

    //console.log("DevelopmentPanel", "selectContent", index); //TODO: Remove later when end this project
    this.onOpenDetail(() => {
      this.setState({ 
        identifyNumber: !isNaN(parseInt(index)) ? parseInt(index) : 0, 
        isDetail: true
      }, () => {
        TweenMax.delayedCall(1, () => { complete && complete() })
      })
    })
  }

  onOpenDetail(complete = () => {}) {
    //console.log("DevelopmentPanel", "onOpenDetail"); //TODO: Remove later when end this project
    TweenMax.to($('.development-panel__content'), 0.5, { opacity: 0, onComplete: () => complete(), ease: Power4.easeInOut })
    TweenMax.to($('.development-panel__title'), 0.5, { opacity: 0 , ease: Power4.easeInOut})
    TweenMax.to($('.development-panel__back-btn'), 0.5, { opacity: 0, ease: Power4.easeInOut })
  }

  onCloseDetail(complete = () => {}) {
    //console.log("DevelopmentPanel", "onCloseDetail"); //TODO: Remove later when end this project
    const { page } = this.state
    const { setHash } = this.props
    this.setState({ isDetail: false })

    TweenMax.fromTo($('.development-panel__content'), 0.5, { opacity: 0},{ opacity: 1, ease: Power4.easeInOut })
    TweenMax.fromTo($('.development-panel__title'), 0.5, { opacity: 0}, { opacity: 1 , ease: Power4.easeInOut})
    TweenMax.fromTo($('.development-panel__back-btn'), 0.5, { opacity: 0}, { opacity: 1, ease: Power4.easeInOut })

    if (page === 'DEVELOPMENT') {
      if (setHash) setHash(LOCATION.DEVELOPMENT)
    } else if (page === 'DIGITAL_MEDIA') {
      if (setHash) setHash(LOCATION.DIGITAL_MEDIA)
    }
    complete && complete();
  }

  renderThumbnail() {
    const { products, page: title } = this.state
    return products.map((item, index) => (
      <Thumbnail color={title === 'DEVELOPMENT' ? 'pink' : 'blue'} index={index} key={index} item={item} onActionHandler={() => this.selectContent(index)}/>
    ))
  }

  render() {
    const { page, identifyNumber, isDetail } = this.state
    const { setHash, setLock } = this.props

    return (
      <div className={`development-panel ${page === 'DEVELOPMENT' ? 'development' : 'digital-media'}`}>
       { !isDetail && <div className="bs4">
          <div className="heading">
            <div className="development-panel__title">
              OUR WORKS
              {/*String(page).replace(/_/gi, ' ').toUpperCase()*/}
              <div className={`development-panel__title--mean mt-1 ${ page === 'DEVELOPMENT' ? 'develop' : 'digital'}`} >
                {/* page === 'DEVELOPMENT' ? 'Digitalization for the most efficiency' : 'Specialized Technology to REACH more' */}
              </div>
            </div>
          </div>
          <div className="btn-close-panel" onClick={() => this.backToMainPage()} />
          <div className="development-panel__content mb-5">
            <div className="container">
              <div className="row">
                {this.renderThumbnail()}
              </div>
            </div>
          </div>
        </div>}
        { isDetail && 
          <DetailPanel 
            theRef={ref => this.detail = ref} 
            page={page} 
            identifyNumber={identifyNumber} 
            onCloseDetail={(complete) => this.onCloseDetail(complete)}
            setHash={(location, id) => setHash(location, id)} 
            setLock={(status) => setLock(status)} 
          />
        }
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    setLogo:(value) => dispatch({ type: 'IS_COLOR', payload: value }),
    reloadPage: (page) => dispatch({ type: 'RELOAD_PAGE', payload: { page, reload: false } })
  }

  return bindActionCreators(action, dispatch)
}

const stateToProps = (state) => {
  return {
    worksData: state.worksData,
    pageReducer: state.pageReducer
  }
}

export default connect(stateToProps, dispatchToProps)(DevelopmentPanel)
