import request from '../utils/request-helper'

class APIAdapter {
  static getWorksData() {
    const header = {
      url: '/assets/data/web-data.json',
      headers: {},
      method: 'get',
      data: {}
    }
    return request(header)
  }

  static getClientsData() {
    const header = {
      url: '/assets/data/our-clients.json',
      headers: {},
      method: 'get',
      data: {}
    }
    return request(header)
  }
}

export default APIAdapter
