import React from 'react'
import { TimelineMax, CSSPlugin, TweenMax } from 'gsap/all';
import { Power4 } from 'gsap';
import $ from 'jquery';

class DigitalSolution extends React.Component {
  constructor (props) {
    super(props)

    this.animBg = React.createRef()
  }
  componentDidMount() {
    //console.log("***************************** DigitalSolution");
    // TweenMax.fromTo(this.animBg, 0.5, { alpha: 1 }, { delay: 2, alpha: 0, onComplete:() => this.explodeText()});
  }

  startAnimation(complete= () => {}) {
    //console.log("DigitalSolution", "startAnimation"); //TODO: Remove later when end this project
    //this.initialText();
    TweenMax.fromTo(this.animBg, 0.1, { alpha: 1 }, { alpha: 0, onComplete:() => this.explodeText()});
    setTimeout(() => complete(), 3000)
  }

  explodeText() {
    //console.log("DigitalSolution", "explodeText"); //TODO: Remove later when end this project
    const tmax_optionsGlobal = {
      repeatDelay: 0
    };

    CSSPlugin.useSVGTransformAttr = true;

    const tl = new TimelineMax(tmax_optionsGlobal),
      path = '.anim *',
      stagger_val = 0.00425,
      duration = 0.5;

    const stagger_opts_to = {
      x: '+=-50', //+ this.getRandom(-100, 0),
      y: '+=-300', //+ this.getRandom(-100, -500),
      //rotation: '+=' + 500,
      //scale: 0,
      opacity: 0,
      ease: Power4.easeInOut
    };

    tl.staggerTo(path, duration, stagger_opts_to, stagger_val);
  }

  explodeReverse() {
    //console.log("DigitalSolution", "explodeReverse"); //TODO: Remove later when end this project
    CSSPlugin.useSVGTransformAttr = true;

    const tl = new TimelineMax(),
      path = '.anim *',
      stagger_val = 0.00425,
      duration = 0.5;

    const stagger_opts_to = {
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      rotation: 0,
      ease: Power4.easeInOut
    };

    tl.staggerTo(path, duration, stagger_opts_to, stagger_val);
  }

  initialText() {
    //console.log("DigitalSolution", "initialText"); //TODO: Remove later when end this project

    const tmax_optionsGlobal = {
      repeatDelay: 0
    };

    CSSPlugin.useSVGTransformAttr = true;

    const tl = new TimelineMax(tmax_optionsGlobal),
      path = '.anim *';

    $.each($(path), function (i, el) {
      tl.set($(this), {
        x: 0,
        y: 0,
        opacity: 1,
        //scale: 1,
        //rotation: 0
      });
    });
  }

  getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  reversTonomal(delay = 2) {
    //console.log("DigitalSolution", "reversTonomal"); //TODO: Remove later when end this project
    this.explodeReverse()
    TweenMax.fromTo(this.animBg, 1, { alpha: 0 }, { delay: delay, alpha: 1})
  }

  render() {
    return (
      <div className="anim-container">
        <svg className="anim" version="1.1" id="Layer_1" x="0px" y="0px" width="987px" height="461px" viewBox="-188.86 9.445 987 461" enableBackground="new -188.86 9.445 987 461" >
          <rect x="118.168" y="302.772" fill="none" width="3.455" height="7.984"/>
          <polygon fill="none" points="155.463,314.951 153.144,320.74 155.463,320.74 "/>
          <rect x="110.343" y="302.772" fill="none" width="1.094" height="7.984"/>
          <rect x="118.168" y="310.756" fill="none" width="3.455" height="9.984"/>
          <rect x="110.343" y="310.756" fill="none" width="1.094" height="9.984"/>
          <rect x="358.506" y="300.772" fill="none" width="4.936" height="9.984"/>
          <path fill="none" d="M346.884,310.558c0.027,0.065,0.05,0.133,0.076,0.199h0.266v-9.984h-7.845c0.756,0.571,1.486,1.171,2.165,1.829
            C343.813,304.802,345.592,307.453,346.884,310.558z"/>
          <rect x="347.226" y="300.772" fill="none" width="11.28" height="9.984"/>
          <polygon fill="none" points="155.463,320.74 165.967,320.74 161.997,310.756 157.143,310.756 155.463,314.951 "/>
          <polygon fill="none" points="155.463,320.74 155.463,327.252 166.743,327.252 166.743,322.694 165.967,320.74 "/>
          <polygon fill="none" points="146.367,320.74 150.696,310.756 144.183,310.756 144.183,320.74 "/>
          <polygon fill="none" points="144.183,325.777 146.367,320.74 144.183,320.74 "/>
          <polygon fill="none" points="153.144,320.74 150.535,327.252 155.463,327.252 155.463,320.74 "/>
          <rect x="121.623" y="310.756" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M280.075,305.764c-0.162-0.224-0.348-0.431-0.53-0.642v5.634h11.28v-9.984h-6.363
            c0.121,0.125,0.257,0.238,0.373,0.367L280.075,305.764z"/>
          <path fill="none" d="M305.031,302.602c0.679-0.658,1.407-1.258,2.161-1.829h-5.086v5.331
            C302.967,304.833,303.938,303.662,305.031,302.602z"/>
          <path fill="none" d="M299.651,310.756c0.026-0.065,0.049-0.133,0.075-0.199c0.657-1.608,1.455-3.089,2.38-4.454v-5.331h-11.28v9.984
            H299.651z"/>
          <rect x="132.903" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="414.907" y="300.772" fill="none" width="11.28" height="9.984"/>
          <rect x="426.188" y="300.772" fill="none" width="6.138" height="9.984"/>
          <rect x="408.39" y="300.772" fill="none" width="6.518" height="9.984"/>
          <rect x="121.623" y="302.772" fill="none" width="11.28" height="7.984"/>
          <rect x="76.502" y="310.756" fill="none" width="0.935" height="9.984"/>
          <rect x="31.381" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M42.661,340.169c2.223-0.007,4.274-0.208,6.147-0.61c1.904-0.407,3.558-0.996,4.964-1.768v-7.068H42.661
            V340.169z"/>
          <rect x="439.058" y="300.772" fill="none" width="9.69" height="9.984"/>
          <rect x="31.381" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="65.222" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="99.062" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="370.174" y="300.772" fill="none" width="10.893" height="9.984"/>
          <rect x="381.067" y="300.772" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M414.907,330.724v8.387c1.479,0.701,3.289,1.061,5.45,1.061c2.221,0,4.103-0.385,5.644-1.156
            c0.065-0.033,0.122-0.072,0.187-0.106v-8.186H414.907z"/>
          <rect x="99.062" y="302.772" fill="none" width="11.28" height="7.984"/>
          <rect x="84.169" y="310.756" fill="none" width="3.613" height="9.984"/>
          <rect x="392.347" y="300.772" fill="none" width="9.311" height="9.984"/>
          <rect x="87.782" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="178.024" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="84.169" y="300.772" fill="none" width="3.613" height="9.984"/>
          <polygon fill="none" points="99.062,310.756 99.062,302.772 96,302.772 96,300.772 87.782,300.772 87.782,310.756 "/>
          <path fill="none" d="M268.265,340.413c0.175,0.007,0.345,0.031,0.522,0.031c1.133,0,2.267-0.158,3.4-0.477
            c1.132-0.316,2.164-0.815,3.094-1.496c0.929-0.68,1.688-1.529,2.277-2.55c0.589-1.02,0.885-2.255,0.885-3.706
            c0-0.533-0.056-1.023-0.139-1.492h-10.04V340.413z"/>
          <rect x="76.502" y="300.772" fill="none" width="0.935" height="9.984"/>
          <path fill="none" d="M278.305,330.724c-0.142-0.807-0.393-1.529-0.779-2.146c-0.612-0.975-1.44-1.802-2.482-2.482
            c-1.043-0.68-2.256-1.258-3.638-1.733c-1.001-0.345-2.058-0.697-3.141-1.053v7.415H278.305z"/>
          <path fill="none" d="M257.697,300.772h-0.712v0.883C257.213,301.353,257.445,301.053,257.697,300.772z"/>
          <path fill="none" d="M268.265,301.543c-0.291,0.057-0.58,0.097-0.872,0.174c-1.111,0.296-2.119,0.771-3.025,1.429
            c-0.907,0.657-1.644,1.474-2.21,2.447c-0.567,0.976-0.851,2.143-0.851,3.502c0,0.601,0.062,1.15,0.17,1.661h6.788V301.543z"/>
          <path fill="none" d="M279.545,310.756v-5.634c-0.886-1.023-1.992-1.892-3.346-2.588c-1.632-0.839-3.468-1.258-5.508-1.258
            c-0.802,0-1.613,0.107-2.427,0.267v9.213H279.545z"/>
          <path fill="none" d="M268.265,316.533c0.928,0.327,1.885,0.657,2.902,0.996c1.632,0.499,3.286,1.065,4.964,1.699
            c1.094,0.415,2.114,0.925,3.073,1.513h0.341v-9.984h-11.28V316.533z"/>
          <rect x="65.222" y="300.772" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M401.657,320.74h-9.311v9.984h9.583c-0.18-1.161-0.272-2.341-0.272-3.541V320.74z"/>
          <path fill="none" d="M16.647,320.74H8.821v9.984h9.587c-1.172-3.006-1.764-6.292-1.764-9.864
            C16.644,320.82,16.647,320.781,16.647,320.74z"/>
          <path fill="none" d="M264.198,314.638c0.884,0.612,1.926,1.134,3.128,1.564c0.303,0.109,0.626,0.22,0.939,0.331v-5.776h-6.788
            c0.129,0.607,0.329,1.157,0.612,1.637C262.61,313.278,263.314,314.026,264.198,314.638z"/>
          <path fill="none" d="M414.907,320.74h-6.518v6.036c0,1.324,0.142,2.64,0.409,3.948h6.108V320.74z"/>
          <rect x="381.067" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M55.405,306.784c-0.446-0.487-0.942-0.935-1.463-1.362v5.334h11.28v-9.984h-6.436
            c0.454,0.359,0.899,0.724,1.31,1.115L55.405,306.784z"/>
          <path fill="none" d="M408.799,330.724c0.062,0.305,0.125,0.609,0.202,0.914c0.408,1.61,1.077,3.05,2.007,4.318
            c0.929,1.27,2.152,2.29,3.672,3.06c0.071,0.036,0.154,0.061,0.228,0.095v-8.387H408.799z"/>
          <rect x="1.957" y="320.74" fill="none" width="6.864" height="9.984"/>
          <path fill="none" d="M256.985,317.167v3.574h4.454c-1.088-0.568-2.085-1.241-2.987-2.022
            C257.913,318.252,257.428,317.731,256.985,317.167z"/>
          <polygon fill="none" points="178.024,320.74 178.024,310.756 168.812,310.756 173.099,320.74 "/>
          <rect x="189.304" y="310.756" fill="none" width="5.839" height="9.984"/>
          <polygon fill="none" points="460.028,310.756 460.028,302.772 450.549,302.772 450.549,300.772 448.748,300.772 448.748,310.756 "/>
          <rect x="189.304" y="300.772" fill="none" width="5.839" height="9.984"/>
          <polygon fill="none" points="168.555,327.252 166.743,322.694 166.743,327.252 "/>
          <path fill="none" d="M254.566,310.756c-0.034-0.448-0.059-0.906-0.059-1.388c0-2.402,0.486-4.465,1.462-6.188
            c0.306-0.541,0.651-1.042,1.016-1.524v-0.883H218.14v9.984H254.566z"/>
          <rect x="211.864" y="300.772" fill="none" width="6.276" height="9.984"/>
          <rect x="178.024" y="300.772" fill="none" width="11.28" height="9.984"/>
          <rect x="201.875" y="300.772" fill="none" width="9.989" height="9.984"/>
          <polygon fill="none" points="150.696,310.756 155.025,300.772 144.183,300.772 144.183,310.756 "/>
          <polygon fill="none" points="168.812,310.756 178.024,310.756 178.024,300.772 166.743,300.772 166.743,305.939 "/>
          <path fill="none" d="M256.985,320.74v-3.574c-0.528-0.672-0.996-1.409-1.39-2.222c-0.568-1.171-0.906-2.572-1.029-4.188H218.14
            v9.984H256.985z"/>
          <polygon fill="none" points="132.903,302.772 132.903,310.756 144.183,310.756 144.183,300.772 133.604,300.772 133.604,302.772 "/>
          <rect x="201.875" y="330.724" fill="none" width="9.989" height="8.292"/>
          <rect x="211.864" y="310.756" fill="none" width="6.276" height="9.984"/>
          <rect x="201.875" y="320.74" fill="none" width="9.989" height="9.984"/>
          <polygon fill="none" points="166.743,300.772 164.525,300.772 166.743,305.939 "/>
          <rect x="201.875" y="310.756" fill="none" width="9.989" height="9.984"/>
          <path fill="none" d="M655.839,322.9c-1.368-0.402-2.716-0.899-4.049-1.462v9.286h11.28v-5.106c-0.852-0.477-1.8-0.896-2.845-1.255
            C658.842,323.886,657.38,323.399,655.839,322.9z"/>
          <path fill="none" d="M651.79,330.724v8.33c1.802,0.915,3.737,1.39,5.817,1.39c1.133,0,2.267-0.158,3.4-0.477
            c0.73-0.204,1.415-0.493,2.062-0.849v-8.395H651.79z"/>
          <path fill="none" d="M663.071,339.119c0.354-0.196,0.702-0.406,1.031-0.647c0.929-0.68,1.688-1.529,2.277-2.55
            c0.589-1.02,0.885-2.255,0.885-3.706c0-0.533-0.056-1.023-0.139-1.492h-4.055V339.119z"/>
          <path fill="none" d="M-46.798,302.704h-0.782v8.052h11.28v-5.886c-0.777-0.364-1.581-0.701-2.441-0.977
            C-41.212,303.102-43.898,302.704-46.798,302.704z"/>
          <path fill="none" d="M23.954,302.602c0.685-0.658,1.421-1.258,2.182-1.829h-6.035v6.784
            C21.177,305.715,22.447,304.051,23.954,302.602z"/>
          <path fill="none" d="M667.125,330.724c-0.143-0.807-0.393-1.529-0.779-2.146c-0.612-0.975-1.44-1.802-2.482-2.482
            c-0.254-0.166-0.519-0.325-0.793-0.478v5.106H667.125z"/>
          <rect x="617.95" y="320.74" fill="none" width="3.142" height="9.984"/>
          <path fill="none" d="M651.283,321.234c-0.351-0.154-0.691-0.32-1.024-0.494h-9.749v9.984h11.28v-9.286
            C651.622,321.367,651.451,321.308,651.283,321.234z"/>
          <rect x="627.824" y="320.74" fill="none" width="1.405" height="9.984"/>
          <rect x="629.23" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M-29.415,310.756c-0.821-1.279-1.787-2.421-2.933-3.394c-1.168-0.99-2.495-1.81-3.951-2.492v5.886H-29.415z"/>
          <rect x="493.868" y="320.74" fill="none" width="6.115" height="9.984"/>
          <polygon fill="none" points="42.661,330.724 53.772,330.724 53.772,324.396 43.096,324.396 43.096,320.74 42.661,320.74 "/>
          <rect x="-47.58" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="-47.58" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M650.978,305.594c-0.567,0.976-0.851,2.143-0.851,3.502c0,0.601,0.062,1.15,0.17,1.661h1.493v-6.302
            C651.491,304.808,651.216,305.184,650.978,305.594z"/>
          <path fill="none" d="M-36.3,320.74h9.621c-0.021-2.913-0.513-5.481-1.488-7.7c-0.359-0.815-0.788-1.565-1.249-2.284H-36.3V320.74z"
            />
          <path fill="none" d="M650.909,312.394c0.25,0.426,0.546,0.818,0.881,1.181v-2.818h-1.493
            C650.427,311.364,650.626,311.914,650.909,312.394z"/>
          <polygon fill="none" points="617.95,330.746 620.956,335.344 621.092,335.344 621.092,330.724 617.95,330.724 "/>
          <path fill="none" d="M550.27,330.724v9.15c1.448-0.245,2.826-0.632,4.114-1.199c2.267-0.996,4.192-2.368,5.78-4.114
            c0.503-0.553,0.956-1.148,1.386-1.762v-2.075H550.27z"/>
          <rect x="-55.23" y="320.74" fill="none" width="7.65" height="9.984"/>
          <path fill="none" d="M561.55,332.799c0.459-0.656,0.874-1.349,1.25-2.075h-1.25V332.799z"/>
          <path fill="none" d="M565.128,320.86c0-0.041-0.005-0.08-0.005-0.12h-3.573v9.984h1.25c0.383-0.74,0.739-1.503,1.036-2.316
            C564.697,326.052,565.128,323.535,565.128,320.86z"/>
          <path fill="none" d="M-26.67,320.928c0-0.064-0.007-0.124-0.008-0.188H-36.3v9.984h7c0.416-0.669,0.804-1.363,1.134-2.112
            C-27.17,326.346-26.67,323.784-26.67,320.928z"/>
          <path fill="none" d="M572.402,320.74c0,0.041,0.003,0.08,0.003,0.12c0,3.572-0.604,6.858-1.796,9.864h2.221v-9.984H572.402z"/>
          <path fill="none" d="M-36.3,336.86c1.456-0.696,2.783-1.531,3.951-2.536c1.199-1.032,2.205-2.242,3.048-3.6h-7V336.86z"/>
          <path fill="none" d="M531.008,330.724c0.729,1.406,1.596,2.695,2.636,3.837c1.496,1.646,3.285,2.948,5.346,3.928v-7.765H531.008z"/>
          <path fill="none" d="M546.836,340.172c1.194,0,2.332-0.111,3.434-0.297v-9.15h-11.28v7.765c0.125,0.06,0.239,0.13,0.366,0.187
            C541.576,339.674,544.071,340.172,546.836,340.172z"/>
          <path fill="none" d="M-47.58,330.724v8.36h0.782c2.9,0,5.586-0.408,8.058-1.224c0.86-0.284,1.664-0.628,2.441-1v-6.136H-47.58z"/>
          <polygon fill="none" points="617.937,330.724 617.95,330.746 617.95,330.724 "/>
          <polygon fill="none" points="606.67,330.724 610.087,330.724 606.67,325.444 "/>
          <rect x="506.716" y="320.74" fill="none" width="9.713" height="9.984"/>
          <rect x="572.829" y="320.74" fill="none" width="11.28" height="9.984"/>
          <polygon fill="none" points="603.626,320.74 595.39,320.74 595.39,330.724 606.67,330.724 606.67,325.444 "/>
          <polygon fill="none" points="617.95,320.74 611.409,320.74 617.937,330.724 617.95,330.724 "/>
          <rect x="584.11" y="320.74" fill="none" width="2.914" height="9.984"/>
          <rect x="593.756" y="320.74" fill="none" width="1.634" height="9.984"/>
          <path fill="none" d="M521.407,320.74h-4.979v9.984h6.739c-1.172-3.006-1.764-6.292-1.764-9.864
            C521.405,320.82,521.407,320.781,521.407,320.74z"/>
          <path fill="none" d="M656.213,301.718c-1.111,0.296-2.119,0.771-3.025,1.429c-0.525,0.38-0.986,0.821-1.397,1.308v6.302h11.28
            v-8.974c-1.11-0.324-2.29-0.506-3.559-0.506C658.423,301.276,657.323,301.424,656.213,301.718z"/>
          <rect x="448.748" y="320.74" fill="none" width="11.279" height="9.984"/>
          <path fill="none" d="M31.381,305.055c-0.88,0.658-1.695,1.387-2.429,2.205c-0.951,1.06-1.755,2.234-2.446,3.497h4.875V305.055z"/>
          <rect x="506.716" y="300.772" fill="none" width="9.713" height="9.984"/>
          <path fill="none" d="M42.661,310.756v-9.195c-0.106-0.001-0.206-0.014-0.313-0.014c-2.812,0-5.35,0.51-7.616,1.53
            c-1.218,0.548-2.33,1.212-3.351,1.977v5.702H42.661z"/>
          <path fill="none" d="M523.233,310.756c0.026-0.065,0.049-0.133,0.075-0.199c1.09-2.668,2.573-4.981,4.401-6.981v-2.804h-11.28v9.984
            H523.233z"/>
          <rect x="-13.74" y="320.74" fill="none" width="8.964" height="9.984"/>
          <path fill="none" d="M16.647,320.74c0.014-3.636,0.635-6.963,1.859-9.984H8.821v9.984H16.647z"/>
          <path fill="none" d="M23.993,320.74h7.388v-9.984h-4.875c-0.461,0.845-0.881,1.722-1.226,2.658
            C24.432,315.713,24.007,318.156,23.993,320.74z"/>
          <path fill="none" d="M528.613,302.602c0.679-0.658,1.407-1.258,2.161-1.829h-3.064v2.804
            C528.008,303.25,528.294,302.911,528.613,302.602z"/>
          <rect x="460.028" y="320.74" fill="none" width="5.958" height="9.984"/>
          <polygon fill="none" points="42.661,320.74 43.096,320.74 43.096,318.684 53.942,318.684 53.942,310.756 42.661,310.756 "/>
          <path fill="none" d="M432.325,320.74h-6.138v9.984h5.729c0.268-1.308,0.409-2.624,0.409-3.948V320.74z"/>
          <polygon fill="none" points="60.436,318.684 60.436,320.74 65.222,320.74 65.222,310.756 53.942,310.756 53.942,318.684 "/>
          <path fill="none" d="M426.188,338.91c1.45-0.757,2.629-1.738,3.52-2.954c0.929-1.269,1.599-2.708,2.007-4.318
            c0.077-0.304,0.14-0.609,0.202-0.914h-5.729V338.91z"/>
          <path fill="none" d="M439.058,320.74v6.443c0,1.199-0.093,2.379-0.272,3.541h9.963v-9.984H439.058z"/>
          <rect x="493.868" y="300.772" fill="none" width="6.115" height="9.984"/>
          <polygon fill="none" points="482.588,302.772 482.588,310.756 493.868,310.756 493.868,300.772 488.154,300.772 488.154,302.772 "/>
          <path fill="none" d="M42.661,301.562v9.195h11.28v-5.334c-1.174-0.964-2.525-1.787-4.079-2.446
            C47.705,302.061,45.3,301.597,42.661,301.562z"/>
          <path fill="none" d="M570.466,310.558c0.027,0.065,0.05,0.133,0.076,0.199h2.287v-9.984h-9.866c0.756,0.571,1.486,1.171,2.165,1.829
            C567.395,304.802,569.174,307.453,570.466,310.558z"/>
          <rect x="-55.23" y="330.724" fill="none" width="7.65" height="8.36"/>
          <rect x="627.824" y="300.772" fill="none" width="1.405" height="9.984"/>
          <rect x="617.95" y="300.772" fill="none" width="3.142" height="9.984"/>
          <polygon fill="none" points="606.67,310.756 606.67,300.772 598.356,300.772 604.883,310.756 "/>
          <rect x="606.67" y="300.772" fill="none" width="11.28" height="9.984"/>
          <rect x="629.23" y="300.772" fill="none" width="11.28" height="9.984"/>
          <rect x="-55.23" y="302.704" fill="none" width="7.65" height="8.052"/>
          <path fill="none" d="M643.386,310.756c-0.034-0.448-0.059-0.906-0.059-1.388c0-2.402,0.486-4.465,1.462-6.188
            c0.498-0.88,1.075-1.681,1.728-2.407h-6.007v9.984H643.386z"/>
          <rect x="584.11" y="300.772" fill="none" width="2.914" height="9.984"/>
          <rect x="482.588" y="320.74" fill="none" width="11.28" height="9.984"/>
          <rect x="1.957" y="310.756" fill="none" width="6.864" height="9.984"/>
          <rect x="572.829" y="300.772" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M-19.467,320.74c0,0.063,0.004,0.124,0.004,0.188c0,3.718-0.633,6.981-1.884,9.796h7.607v-9.984H-19.467z"/>
          <rect x="-55.23" y="310.756" fill="none" width="7.65" height="9.984"/>
          <path fill="none" d="M-13.74,320.74v-9.984h-7.675c1.274,2.831,1.927,6.157,1.948,9.984H-13.74z"/>
          <rect x="-13.74" y="310.756" fill="none" width="8.964" height="9.984"/>
          <rect x="472.717" y="320.74" fill="none" width="9.871" height="9.984"/>
          <path fill="none" d="M527.709,338.127v2.581h2.682c-0.619-0.499-1.212-1.029-1.778-1.59
            C528.294,338.803,528.008,338.459,527.709,338.127z"/>
          <path fill="none" d="M554.418,303.078c-1.286-0.585-2.677-0.982-4.148-1.232v8.91h11.28v-1.719c-0.43-0.618-0.883-1.217-1.386-1.777
            C558.576,305.492,556.662,304.098,554.418,303.078z"/>
          <rect x="448.748" y="330.724" fill="none" width="11.279" height="9.984"/>
          <path fill="none" d="M550.27,310.756v-8.91c-1.097-0.186-2.233-0.298-3.434-0.298c-2.766,0-5.271,0.51-7.514,1.53
            c-0.115,0.052-0.22,0.117-0.333,0.172v7.506H550.27z"/>
          <path fill="none" d="M438.785,330.724c-0.187,1.209-0.468,2.398-0.85,3.566c-0.138,0.421-0.304,0.824-0.468,1.228v5.19h11.28v-9.984
            H438.785z"/>
          <rect x="414.907" y="310.756" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M403.627,336.384v4.324h2.911c-0.104-0.113-0.223-0.215-0.324-0.332
            C405.162,339.17,404.31,337.833,403.627,336.384z"/>
          <path fill="none" d="M538.99,303.25c-2.096,1.003-3.896,2.333-5.379,4.01c-0.938,1.06-1.732,2.234-2.418,3.497h7.797V303.25z"/>
          <path fill="none" d="M521.407,320.74c0.013-3.636,0.622-6.963,1.825-9.984h-6.804v9.984H521.407z"/>
          <path fill="none" d="M402.78,334.29c-0.382-1.167-0.663-2.357-0.85-3.566h-9.583v9.984h11.28v-4.324
            C403.31,335.709,403.017,335.018,402.78,334.29z"/>
          <path fill="none" d="M528.685,320.74h10.305v-9.984h-7.797c-0.458,0.845-0.875,1.722-1.221,2.658
            C529.124,315.713,528.698,318.156,528.685,320.74z"/>
          <rect x="472.717" y="330.724" fill="none" width="9.871" height="9.984"/>
          <rect x="482.588" y="330.724" fill="none" width="11.28" height="9.984"/>
          <rect x="538.99" y="320.74" fill="none" width="11.28" height="9.984"/>
          <rect x="550.27" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M562.61,310.756c-0.325-0.595-0.677-1.169-1.06-1.719v1.719H562.61z"/>
          <rect x="460.028" y="330.724" fill="none" width="5.958" height="9.984"/>
          <path fill="none" d="M561.55,320.74h3.573c-0.014-2.584-0.44-5.027-1.287-7.326c-0.345-0.936-0.765-1.813-1.227-2.658h-1.06V320.74z
            "/>
          <rect x="550.27" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="493.868" y="330.724" fill="none" width="6.115" height="9.984"/>
          <rect x="448.748" y="310.756" fill="none" width="11.279" height="9.984"/>
          <rect x="439.058" y="310.756" fill="none" width="9.69" height="9.984"/>
          <rect x="426.188" y="310.756" fill="none" width="6.138" height="9.984"/>
          <rect x="460.028" y="310.756" fill="none" width="5.958" height="9.984"/>
          <polygon fill="none" points="381.067,330.724 381.067,339.016 391.662,339.016 391.662,340.708 392.347,340.708 392.347,330.724 "/>
          <rect x="460.028" y="302.772" fill="none" width="5.958" height="7.984"/>
          <path fill="none" d="M299.726,331.094c-0.05-0.122-0.092-0.247-0.14-0.37h-8.761v9.984h11.28v-5.141
            C301.181,334.19,300.383,332.702,299.726,331.094z"/>
          <path fill="none" d="M302.106,335.567v5.141h4.703c-0.619-0.499-1.212-1.029-1.778-1.59
            C303.938,338.037,302.967,336.849,302.106,335.567z"/>
          <rect x="414.907" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M285.27,330.724c0.014,0.293,0.041,0.573,0.041,0.879c0,2.494-0.465,4.658-1.394,6.494
            c-0.485,0.958-1.058,1.825-1.709,2.61h8.618v-9.984H285.27z"/>
          <rect x="472.717" y="310.756" fill="none" width="9.871" height="9.984"/>
          <rect x="482.588" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="493.868" y="310.756" fill="none" width="6.115" height="9.984"/>
          <rect x="506.716" y="310.756" fill="none" width="9.713" height="9.984"/>
          <path fill="none" d="M347.027,330.724c-0.049,0.123-0.092,0.248-0.143,0.37c-1.292,3.105-3.071,5.78-5.338,8.024
            c-0.567,0.561-1.161,1.091-1.781,1.59h7.461v-9.984H347.027z"/>
          <rect x="347.226" y="330.724" fill="none" width="11.28" height="9.984"/>
          <rect x="472.717" y="302.772" fill="none" width="9.871" height="7.984"/>
          <rect x="358.506" y="330.724" fill="none" width="4.936" height="9.984"/>
          <path fill="none" d="M434.501,340.376c-0.102,0.117-0.22,0.219-0.325,0.332h3.292v-5.19
            C436.739,337.308,435.759,338.934,434.501,340.376z"/>
          <path fill="none" d="M646.864,334.8c1.133,1.678,2.652,3.038,4.556,4.08c0.121,0.066,0.249,0.112,0.371,0.174v-8.33h-11.28v9.984
            h2.476c-0.477-0.469-0.934-0.96-1.358-1.488L646.864,334.8z"/>
          <rect x="627.824" y="330.724" fill="none" width="1.405" height="9.984"/>
          <rect x="538.99" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="110.343" y="320.74" fill="none" width="1.094" height="9.984"/>
          <polygon fill="none" points="159.579,304.676 157.143,310.756 161.997,310.756 "/>
          <rect x="627.824" y="310.756" fill="none" width="1.405" height="9.984"/>
          <rect x="617.95" y="310.756" fill="none" width="3.142" height="9.984"/>
          <path fill="none" d="M18.506,310.756c0.027-0.065,0.049-0.133,0.077-0.199c0.441-1.06,0.962-2.047,1.519-3.001v-6.784H8.821v9.984
            H18.506z"/>
          <rect x="629.23" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="121.623" y="320.74" fill="none" width="11.28" height="9.984"/>
          <rect x="118.168" y="320.74" fill="none" width="3.455" height="9.984"/>
          <path fill="none" d="M651.79,310.756v2.818c0.361,0.391,0.77,0.747,1.228,1.063c0.884,0.612,1.926,1.134,3.128,1.564
            c1.2,0.432,2.481,0.873,3.842,1.326c1.018,0.311,2.048,0.658,3.083,1.022v-7.794H651.79z"/>
          <polygon fill="none" points="606.67,330.724 606.67,340.708 616.547,340.708 610.087,330.724 "/>
          <rect x="178.024" y="320.74" fill="none" width="11.28" height="9.984"/>
          <polygon fill="none" points="178.024,332.21 178.024,330.724 177.385,330.724 "/>
          <rect x="189.304" y="320.74" fill="none" width="5.839" height="9.984"/>
          <polygon fill="none" points="173.099,320.74 177.385,330.724 178.024,330.724 178.024,320.74 "/>
          <rect x="629.23" y="330.724" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M523.308,331.094c-0.05-0.122-0.092-0.247-0.14-0.37h-6.739v9.984h11.28v-2.581
            C525.881,336.094,524.398,333.763,523.308,331.094z"/>
          <rect x="584.11" y="310.756" fill="none" width="2.914" height="9.984"/>
          <polygon fill="none" points="595.39,308.011 593.892,305.696 593.756,305.696 593.756,310.756 595.39,310.756 "/>
          <rect x="593.756" y="310.756" fill="none" width="1.634" height="9.984"/>
          <path fill="none" d="M538.99,320.74h-10.305c0,0.041-0.005,0.08-0.005,0.12c0,2.675,0.431,5.191,1.292,7.548
            c0.297,0.813,0.653,1.576,1.036,2.316h7.981V320.74z"/>
          <path fill="none" d="M572.829,320.74v-9.984h-2.287c1.225,3.021,1.846,6.348,1.859,9.984H572.829z"/>
          <rect x="572.829" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="506.716" y="330.724" fill="none" width="9.713" height="9.984"/>
          <rect x="572.829" y="330.724" fill="none" width="11.28" height="9.984"/>
          <rect x="595.39" y="330.724" fill="none" width="11.28" height="9.984"/>
          <rect x="584.11" y="330.724" fill="none" width="2.914" height="9.984"/>
          <polygon fill="none" points="611.409,320.74 617.95,320.74 617.95,310.756 606.67,310.756 606.67,313.49 "/>
          <rect x="593.756" y="330.724" fill="none" width="1.634" height="9.984"/>
          <polygon fill="none" points="595.39,320.74 603.626,320.74 597.166,310.756 595.39,310.756 "/>
          <path fill="none" d="M570.609,330.724c-0.049,0.123-0.092,0.248-0.143,0.37c-1.292,3.105-3.071,5.78-5.338,8.024
            c-0.567,0.561-1.161,1.091-1.781,1.59h9.482v-9.984H570.609z"/>
          <polygon fill="none" points="597.166,310.756 595.39,308.011 595.39,310.756 "/>
          <polygon fill="none" points="606.67,310.756 604.883,310.756 606.67,313.49 "/>
          <path fill="none" d="M650.259,320.74c-1.088-0.568-2.086-1.241-2.987-2.022c-1.18-1.02-2.132-2.277-2.856-3.773
            c-0.568-1.171-0.905-2.572-1.029-4.188h-2.876v9.984H650.259z"/>
          <path fill="none" d="M-21.346,330.724c-0.137,0.309-0.275,0.616-0.428,0.914c-0.91,1.779-2.005,3.354-3.246,4.769v4.301h11.28
            v-9.984H-21.346z"/>
          <rect x="65.222" y="320.74" fill="none" width="11.28" height="9.984"/>
          <rect x="-13.74" y="330.724" fill="none" width="8.964" height="9.984"/>
          <rect x="76.502" y="320.74" fill="none" width="0.935" height="9.984"/>
          <path fill="none" d="M297.825,320.74h-7v9.984h8.761c-1.172-3.006-1.764-6.292-1.764-9.864
            C297.823,320.82,297.825,320.781,297.825,320.74z"/>
          <path fill="none" d="M-27.826,339.118c-0.73,0.577-1.493,1.098-2.273,1.59h5.08v-4.301
            C-25.882,337.391-26.804,338.31-27.826,339.118z"/>
          <path fill="none" d="M262.599,338.88c1.752,0.96,3.645,1.457,5.666,1.533v-9.689h-11.28v4.969l1.059-0.894
            C259.176,336.478,260.696,337.838,262.599,338.88z"/>
          <path fill="none" d="M279.545,320.74v0.205c0.392,0.253,0.774,0.517,1.143,0.799c1.359,1.043,2.47,2.346,3.331,3.91
            c0.751,1.364,1.155,3.068,1.251,5.07h5.556v-9.984H279.545z"/>
          <rect x="60.436" y="320.74" fill="none" width="4.786" height="9.984"/>
          <rect x="408.39" y="310.756" fill="none" width="6.518" height="9.984"/>
          <rect x="211.864" y="330.724" fill="none" width="6.276" height="8.292"/>
          <rect x="218.14" y="320.74" fill="none" width="38.845" height="9.984"/>
          <rect x="211.864" y="320.74" fill="none" width="6.276" height="9.984"/>
          <path fill="none" d="M268.265,323.31c-0.414-0.136-0.82-0.271-1.246-0.409c-1.541-0.453-3.06-1.009-4.556-1.666
            c-0.351-0.154-0.691-0.32-1.024-0.494h-4.454v9.984h11.28V323.31z"/>
          <rect x="84.169" y="320.74" fill="none" width="3.613" height="9.984"/>
          <rect x="87.782" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M18.549,331.094c-0.05-0.122-0.092-0.247-0.14-0.37H8.821v9.984h11.28v-6.506
            C19.529,333.213,18.996,332.19,18.549,331.094z"/>
          <path fill="none" d="M20.101,334.202v6.506h5.534c-0.622-0.499-1.216-1.029-1.783-1.59C22.385,337.666,21.15,336.014,20.101,334.202
            z"/>
          <rect x="1.957" y="330.724" fill="none" width="6.864" height="9.984"/>
          <path fill="none" d="M26.316,330.724c0.728,1.406,1.596,2.695,2.636,3.837c0.729,0.803,1.545,1.518,2.429,2.163v-6H26.316z"/>
          <rect x="-13.74" y="300.772" fill="none" width="8.964" height="9.984"/>
          <path fill="none" d="M-21.774,309.98c0.128,0.252,0.243,0.515,0.36,0.776h7.675v-9.984h-11.28v4.424
            C-23.78,306.61-22.684,308.189-21.774,309.98z"/>
          <path fill="none" d="M-25.02,305.197v-4.424h-5.366c0.881,0.527,1.742,1.089,2.559,1.728
            C-26.804,303.299-25.882,304.214-25.02,305.197z"/>
          <rect x="358.506" y="320.74" fill="none" width="4.936" height="9.984"/>
          <path fill="none" d="M31.381,320.74h-7.388c0,0.041-0.005,0.08-0.005,0.12c0,2.675,0.43,5.191,1.292,7.548
            c0.297,0.813,0.652,1.576,1.036,2.316h5.065V320.74z"/>
          <rect x="370.174" y="320.74" fill="none" width="10.893" height="9.984"/>
          <rect x="1.957" y="300.772" fill="none" width="6.864" height="9.984"/>
          <rect x="370.174" y="330.724" fill="none" width="10.893" height="8.292"/>
          <path fill="none" d="M323.254,340.172c0.481,0,0.945-0.035,1.412-0.065v-9.383h-11.28v6.631c0.755,0.489,1.541,0.941,2.388,1.321
            C317.994,339.674,320.489,340.172,323.254,340.172z"/>
          <path fill="none" d="M324.666,330.724v9.383c2.212-0.144,4.263-0.607,6.136-1.431c1.979-0.87,3.683-2.044,5.145-3.486v-4.466
            H324.666z"/>
          <polygon fill="none" points="144.183,320.74 132.903,320.74 132.903,330.724 142.038,330.724 144.183,325.777 "/>
          <path fill="none" d="M335.947,335.19c0.212-0.209,0.435-0.407,0.636-0.628c1.039-1.143,1.907-2.431,2.636-3.837h-3.271V335.19z"/>
          <path fill="none" d="M307.426,330.724c0.729,1.406,1.596,2.695,2.636,3.837c0.981,1.081,2.1,2,3.324,2.793v-6.631H307.426z"/>
          <path fill="none" d="M347.027,330.724h0.199v-0.517C347.161,330.38,347.095,330.552,347.027,330.724z"/>
          <path fill="none" d="M341.546,320.86c0-0.041-0.005-0.08-0.005-0.12h-5.595v9.984h3.271c0.383-0.74,0.739-1.503,1.036-2.316
            C341.115,326.052,341.546,323.535,341.546,320.86z"/>
          <path fill="none" d="M348.82,320.74c0,0.041,0.003,0.08,0.003,0.12c0,3.368-0.536,6.482-1.597,9.347v0.517h11.28v-9.984H348.82z"/>
          <path fill="none" d="M42.552,340.172c0.037,0,0.072-0.002,0.109-0.002v-9.445h-11.28v6c1.037,0.757,2.171,1.414,3.419,1.952
            C37.113,339.674,39.697,340.172,42.552,340.172z"/>
          <path fill="none" d="M313.386,320.74h-8.283c0,0.041-0.005,0.08-0.005,0.12c0,2.675,0.431,5.191,1.292,7.548
            c0.297,0.813,0.653,1.576,1.036,2.316h5.96V320.74z"/>
          <polygon fill="none" points="178.024,330.724 178.024,332.21 181.673,340.708 189.304,340.708 189.304,330.724 "/>
          <rect x="189.304" y="330.724" fill="none" width="5.839" height="9.984"/>
          <path fill="none" d="M347.226,310.756h-0.266c0.093,0.228,0.18,0.458,0.266,0.689V310.756z"/>
          <path fill="none" d="M335.947,320.74h5.595c-0.014-2.584-0.44-5.027-1.287-7.326c-0.345-0.936-0.765-1.813-1.227-2.658h-3.081
            V320.74z"/>
          <path fill="none" d="M348.82,320.74h9.687v-9.984h-11.28v0.689C348.274,314.282,348.808,317.378,348.82,320.74z"/>
          <path fill="none" d="M339.028,310.756c-0.69-1.263-1.495-2.437-2.445-3.497c-0.201-0.224-0.424-0.425-0.636-0.638v4.134H339.028z"/>
          <polygon fill="none" points="166.743,333.168 166.743,340.708 174.124,340.708 171.004,333.168 "/>
          <rect x="155.463" y="333.168" fill="none" width="11.28" height="7.54"/>
          <path fill="none" d="M330.836,303.078c-1.858-0.845-3.923-1.319-6.17-1.464v9.143h11.28v-4.134
            C334.488,305.162,332.795,303.968,330.836,303.078z"/>
          <path fill="none" d="M324.666,310.756v-9.143c-0.466-0.03-0.93-0.066-1.412-0.066c-2.766,0-5.271,0.51-7.514,1.53
            c-0.838,0.38-1.611,0.831-2.354,1.315v6.363H324.666z"/>
          <rect x="358.506" y="310.756" fill="none" width="4.936" height="9.984"/>
          <rect x="381.067" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="324.666" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="392.347" y="310.756" fill="none" width="9.311" height="9.984"/>
          <rect x="370.174" y="310.756" fill="none" width="10.893" height="9.984"/>
          <rect x="324.666" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M256.985,335.693v-4.969H218.14v8.292h5.223v1.692h30.802c-0.476-0.469-0.933-0.96-1.358-1.488L256.985,335.693
            z"/>
          <rect x="313.386" y="320.74" fill="none" width="11.28" height="9.984"/>
          <rect x="313.386" y="310.756" fill="none" width="11.28" height="9.984"/>
          <polygon fill="none" points="148.156,333.168 145.079,340.708 155.463,340.708 155.463,333.168 "/>
          <rect x="99.062" y="330.724" fill="none" width="11.28" height="9.984"/>
          <rect x="110.343" y="330.724" fill="none" width="1.094" height="9.984"/>
          <path fill="none" d="M279.545,320.945v-0.205h-0.341C279.317,320.81,279.434,320.874,279.545,320.945z"/>
          <rect x="118.168" y="330.724" fill="none" width="3.455" height="9.984"/>
          <rect x="65.222" y="330.724" fill="none" width="11.28" height="9.984"/>
          <rect x="76.502" y="330.724" fill="none" width="0.935" height="9.984"/>
          <rect x="84.169" y="330.724" fill="none" width="3.613" height="9.984"/>
          <rect x="87.782" y="330.724" fill="none" width="11.28" height="9.984"/>
          <rect x="121.623" y="330.724" fill="none" width="11.28" height="9.984"/>
          <path fill="none" d="M305.103,320.74h8.283v-9.984h-5.775c-0.458,0.845-0.875,1.722-1.221,2.658
            C305.542,315.713,305.116,318.156,305.103,320.74z"/>
          <path fill="none" d="M313.386,304.394c-1.248,0.814-2.377,1.758-3.357,2.866c-0.938,1.06-1.732,2.234-2.418,3.497h5.775V304.394z"/>
          <rect x="60.436" y="330.724" fill="none" width="4.786" height="9.984"/>
          <polygon fill="none" points="132.903,330.724 132.903,340.708 137.708,340.708 142.038,330.724 "/>
          <path fill="none" d="M297.825,320.74c0.013-3.636,0.623-6.963,1.826-9.984h-8.825v9.984H297.825z"/>
          <rect x="279.545" y="310.756" fill="none" width="11.28" height="9.984"/>
          <rect x="99.062" y="320.74" fill="none" width="11.28" height="9.984"/>
          <path fill="#231F20" d="M-19.467,320.74h-5.553v9.984h3.673c1.251-2.815,1.884-6.078,1.884-9.796
            C-19.462,320.864-19.466,320.804-19.467,320.74z"/>
          <path fill="#231F20" d="M-26.678,320.74c0.001,0.064,0.008,0.123,0.008,0.188c0,2.856-0.5,5.418-1.496,7.685
            c-0.33,0.749-0.718,1.442-1.134,2.112h4.28v-9.984H-26.678z"/>
          <rect x="-61.962" y="340.708" fill="#231F20" width="3.102" height="4.292"/>
          <rect x="-61.962" y="300.772" fill="#231F20" width="3.102" height="9.984"/>
          <path fill="#231F20" d="M-36.3,300.772v-2.585c-3.047-0.885-6.093-1.331-9.139-1.331h-2.142v3.916H-36.3z"/>
          <path fill="#231F20" d="M-46.798,339.084h-0.782v1.624h11.28v-3.848c-0.777,0.372-1.581,0.716-2.441,1
            C-41.212,338.676-43.898,339.084-46.798,339.084z"/>
          <rect x="-58.86" y="296.856" fill="#231F20" width="11.28" height="3.916"/>
          <path fill="#231F20" d="M-36.3,304.871c1.456,0.682,2.783,1.502,3.951,2.492c1.146,0.973,2.112,2.115,2.933,3.394h4.396v-5.56
            c-0.862-0.983-1.784-1.897-2.807-2.697c-0.817-0.639-1.678-1.201-2.559-1.728H-36.3V304.871z"/>
          <path fill="#231F20" d="M-30.385,300.772c-1.806-1.081-3.733-1.947-5.805-2.557c-0.037-0.011-0.073-0.018-0.109-0.028v2.585H-30.385
            z"/>
          <rect x="-61.962" y="296.856" fill="#231F20" width="3.102" height="3.916"/>
          <rect x="-61.962" y="310.756" fill="#231F20" width="3.102" height="9.984"/>
          <rect x="-58.86" y="340.708" fill="#231F20" width="11.28" height="4.292"/>
          <path fill="#231F20" d="M-47.58,340.708V345h2.142c3.045,0,6.092-0.479,9.139-1.432v-2.86H-47.58z"/>
          <rect x="-61.962" y="330.724" fill="#231F20" width="3.102" height="9.984"/>
          <path fill="#231F20" d="M-36.3,343.568c0.037-0.011,0.073-0.019,0.109-0.03c2.18-0.689,4.205-1.642,6.091-2.83h-6.2V343.568z"/>
          <rect x="-61.962" y="320.74" fill="#231F20" width="3.102" height="9.984"/>
          <path fill="#231F20" d="M-25.02,336.407v-5.683h-4.28c-0.844,1.358-1.849,2.568-3.048,3.6c-1.168,1.004-2.495,1.839-3.951,2.536
            v3.848h6.2c0.78-0.492,1.543-1.013,2.273-1.59C-26.804,338.31-25.882,337.391-25.02,336.407z"/>
          <path fill="#231F20" d="M-26.678,320.74h1.659v-9.984h-4.396c0.461,0.719,0.89,1.468,1.249,2.284
            C-27.191,315.259-26.699,317.828-26.678,320.74z"/>
          <path fill="#231F20" d="M-46.798,302.704c2.9,0,5.586,0.397,8.058,1.189c0.86,0.276,1.664,0.613,2.441,0.977v-4.098h-11.28v1.932
            H-46.798z"/>
          <path fill="#231F20" d="M-19.467,320.74c-0.021-3.827-0.673-7.153-1.948-9.984h-3.605v9.984H-19.467z"/>
          <polygon fill="#231F20" points="-55.23,302.704 -47.58,302.704 -47.58,300.772 -58.86,300.772 -58.86,310.756 -55.23,310.756 "/>
          <polygon fill="#231F20" points="-55.23,339.084 -55.23,330.724 -58.86,330.724 -58.86,340.708 -47.58,340.708 -47.58,339.084 "/>
          <rect x="-58.86" y="310.756" fill="#231F20" width="3.63" height="9.984"/>
          <path fill="#231F20" d="M-25.02,336.407c1.24-1.416,2.336-2.99,3.246-4.769c0.152-0.298,0.291-0.605,0.428-0.914h-3.673V336.407z"/>
          <rect x="-58.86" y="320.74" fill="#231F20" width="3.63" height="9.984"/>
          <path fill="#231F20" d="M-21.774,309.98c-0.91-1.792-2.005-3.371-3.246-4.784v5.56h3.605
            C-21.531,310.496-21.646,310.233-21.774,309.98z"/>
          <rect x="-4.775" y="296.856" fill="#231F20" width="2.316" height="3.916"/>
          <rect x="-2.459" y="310.756" fill="#231F20" width="4.416" height="9.984"/>
          <rect x="-4.775" y="300.772" fill="#231F20" width="2.316" height="9.984"/>
          <rect x="-4.775" y="320.74" fill="#231F20" width="2.316" height="9.984"/>
          <rect x="-2.459" y="320.74" fill="#231F20" width="4.416" height="9.984"/>
          <rect x="-4.775" y="310.756" fill="#231F20" width="2.316" height="9.984"/>
          <rect x="-2.459" y="330.724" fill="#231F20" width="4.416" height="9.984"/>
          <rect x="-4.775" y="340.708" fill="#231F20" width="2.316" height="4.292"/>
          <rect x="-2.459" y="300.772" fill="#231F20" width="4.416" height="9.984"/>
          <rect x="-4.775" y="330.724" fill="#231F20" width="2.316" height="9.984"/>
          <rect x="-2.459" y="340.708" fill="#231F20" width="4.416" height="4.292"/>
          <rect x="-2.459" y="296.856" fill="#231F20" width="4.416" height="3.916"/>
          <path fill="#231F20" d="M53.942,344.642c2.306-0.68,4.475-1.552,6.495-2.634v-1.3h-6.495V344.642z"/>
          <path fill="#231F20" d="M42.661,340.708v5.507c3.414-0.021,6.577-0.377,9.479-1.08c0.612-0.148,1.208-0.319,1.801-0.494v-3.934
            H42.661z"/>
          <path fill="#231F20" d="M20.101,307.557c-0.557,0.954-1.078,1.941-1.519,3.001c-0.027,0.065-0.05,0.133-0.077,0.199h1.596V307.557z"
            />
          <rect x="53.942" y="330.724" fill="#231F20" width="6.495" height="9.984"/>
          <path fill="#231F20" d="M18.549,331.094c0.448,1.096,0.98,2.119,1.553,3.108v-3.478h-1.693
            C18.456,330.847,18.499,330.972,18.549,331.094z"/>
          <rect x="53.942" y="320.74" fill="#231F20" width="6.495" height="9.984"/>
          <path fill="#231F20" d="M31.381,336.724c-0.883-0.645-1.699-1.359-2.429-2.163c-1.04-1.143-1.908-2.431-2.636-3.837h-6.215v3.478
            c1.049,1.812,2.284,3.464,3.751,4.917c0.567,0.561,1.161,1.091,1.783,1.59h5.746V336.724z"/>
          <path fill="#231F20" d="M25.635,340.708c1.703,1.365,3.628,2.479,5.746,3.376v-3.376H25.635z"/>
          <path fill="#231F20" d="M31.381,340.708v3.376c0.203,0.086,0.391,0.188,0.597,0.269c3.15,1.247,6.607,1.87,10.37,1.87
            c0.106,0,0.207-0.007,0.313-0.008v-5.507H31.381z"/>
          <path fill="#231F20" d="M53.942,305.422c0.521,0.428,1.017,0.875,1.463,1.362l4.692-4.896c-0.411-0.392-0.856-0.756-1.31-1.115
            h-4.845V305.422z"/>
          <rect x="53.942" y="318.684" fill="#231F20" width="6.495" height="2.057"/>
          <path fill="#231F20" d="M53.942,300.772v-2.861c-0.448-0.197-0.886-0.401-1.359-0.579c-2.913-1.094-6.226-1.648-9.921-1.686v5.126
            H53.942z"/>
          <path fill="#231F20" d="M58.786,300.772c-1.405-1.112-3.025-2.062-4.845-2.861v2.861H58.786z"/>
          <path fill="#231F20" d="M49.863,302.976c1.554,0.66,2.905,1.482,4.079,2.446v-4.649h-11.28v0.789
            C45.3,301.597,47.705,302.061,49.863,302.976z"/>
          <rect x="43.096" y="318.684" fill="#231F20" width="10.845" height="2.057"/>
          <path fill="#231F20" d="M20.101,320.74v-9.984h-1.596c-1.224,3.021-1.845,6.348-1.859,9.984H20.101z"/>
          <path fill="#231F20" d="M23.993,320.74c0.013-2.584,0.438-5.027,1.287-7.326c0.345-0.936,0.765-1.813,1.226-2.658h-6.405v9.984
            H23.993z"/>
          <path fill="#231F20" d="M42.348,301.548c0.107,0,0.207,0.012,0.313,0.014v-0.789h-11.28v4.282c1.021-0.764,2.133-1.429,3.351-1.977
            C36.999,302.058,39.537,301.548,42.348,301.548z"/>
          <path fill="#231F20" d="M42.661,300.772v-5.126c-0.129-0.001-0.25-0.014-0.381-0.014c-3.672,0-7.072,0.612-10.2,1.836
            c-0.241,0.094-0.463,0.211-0.699,0.312v2.993H42.661z"/>
          <polygon fill="#231F20" points="43.096,320.74 43.096,324.396 53.772,324.396 53.772,330.724 53.942,330.724 53.942,320.74 "/>
          <path fill="#231F20" d="M20.101,307.557v3.2h6.405c0.69-1.263,1.495-2.437,2.446-3.497c0.734-0.818,1.549-1.547,2.429-2.205v-4.282
            h-5.245c-0.761,0.571-1.497,1.171-2.182,1.829C22.447,304.051,21.177,305.715,20.101,307.557z"/>
          <path fill="#231F20" d="M23.989,320.86c0-0.041,0.005-0.08,0.005-0.12h-3.892v9.984h6.215c-0.383-0.74-0.739-1.503-1.036-2.316
            C24.419,326.052,23.989,323.535,23.989,320.86z"/>
          <path fill="#231F20" d="M53.772,337.792c-1.406,0.771-3.06,1.36-4.964,1.768c-1.873,0.401-3.924,0.603-6.147,0.61v0.539h11.28
            v-9.984h-0.169V337.792z"/>
          <path fill="#231F20" d="M42.552,340.172c-2.856,0-5.44-0.498-7.752-1.496c-1.249-0.538-2.382-1.195-3.419-1.952v3.984h11.28v-0.539
            C42.624,340.169,42.59,340.172,42.552,340.172z"/>
          <path fill="#231F20" d="M16.647,320.74c0,0.041-0.002,0.08-0.002,0.12c0,3.572,0.592,6.858,1.764,9.864h1.693v-9.984H16.647z"/>
          <path fill="#231F20" d="M31.381,297.779c-1.91,0.81-3.662,1.805-5.245,2.993h5.245V297.779z"/>
          <rect x="77.437" y="296.856" fill="#231F20" width="6.732" height="3.916"/>
          <rect x="77.437" y="340.708" fill="#231F20" width="6.732" height="4.292"/>
          <rect x="77.437" y="310.756" fill="#231F20" width="6.732" height="9.984"/>
          <rect x="77.437" y="320.74" fill="#231F20" width="6.732" height="9.984"/>
          <rect x="77.437" y="330.724" fill="#231F20" width="6.732" height="9.984"/>
          <rect x="77.437" y="300.772" fill="#231F20" width="6.732" height="9.984"/>
          <rect x="96" y="296.856" fill="#231F20" width="3.062" height="3.916"/>
          <rect x="99.062" y="300.772" fill="#231F20" width="11.28" height="2"/>
          <rect x="132.903" y="300.772" fill="#231F20" width="0.701" height="2"/>
          <rect x="96" y="300.772" fill="#231F20" width="3.062" height="2"/>
          <rect x="111.436" y="340.708" fill="#231F20" width="6.732" height="4.292"/>
          <rect x="111.436" y="330.724" fill="#231F20" width="6.732" height="9.984"/>
          <polygon fill="#231F20" points="111.436,302.772 111.436,310.756 118.168,310.756 118.168,302.772 121.623,302.772 121.623,300.772 
            110.343,300.772 110.343,302.772 "/>
          <rect x="121.623" y="296.856" fill="#231F20" width="11.28" height="3.916"/>
          <rect x="110.343" y="296.856" fill="#231F20" width="11.28" height="3.916"/>
          <rect x="99.062" y="296.856" fill="#231F20" width="11.28" height="3.916"/>
          <rect x="111.436" y="310.756" fill="#231F20" width="6.732" height="9.984"/>
          <rect x="111.436" y="320.74" fill="#231F20" width="6.732" height="9.984"/>
          <rect x="121.623" y="300.772" fill="#231F20" width="11.28" height="2"/>
          <rect x="132.903" y="296.856" fill="#231F20" width="0.701" height="3.916"/>
          <polygon fill="#231F20" points="155.463,299.763 155.025,300.772 155.463,300.772 "/>
          <polygon fill="#231F20" points="155.463,310.756 155.463,300.772 155.025,300.772 150.696,310.756 "/>
          <polygon fill="#231F20" points="164.525,300.772 162.844,296.856 156.723,296.856 155.463,299.763 155.463,300.772 "/>
          <polygon fill="#231F20" points="137.708,340.708 135.847,345 143.327,345 144.183,342.904 144.183,340.708 "/>
          <polygon fill="#231F20" points="144.183,342.904 145.079,340.708 144.183,340.708 "/>
          <polygon fill="#231F20" points="155.463,310.756 157.143,310.756 159.579,304.676 161.997,310.756 166.743,310.756 166.743,305.939 
            164.525,300.772 155.463,300.772 "/>
          <polygon fill="#231F20" points="173.099,320.74 168.812,310.756 166.743,310.756 166.743,320.74 "/>
          <polygon fill="#231F20" points="166.743,320.74 166.743,310.756 161.997,310.756 165.967,320.74 "/>
          <polygon fill="#231F20" points="166.743,322.694 166.743,320.74 165.967,320.74 "/>
          <polygon fill="#231F20" points="153.144,320.74 155.463,314.951 155.463,310.756 150.696,310.756 146.367,320.74 "/>
          <polygon fill="#231F20" points="178.024,340.708 181.673,340.708 178.024,332.21 "/>
          <polygon fill="#231F20" points="178.024,345 183.515,345 181.673,340.708 178.024,340.708 "/>
          <polygon fill="#231F20" points="155.463,333.168 155.463,330.724 144.183,330.724 144.183,340.708 145.079,340.708 148.156,333.168 
            "/>
          <polygon fill="#231F20" points="178.024,332.21 177.385,330.724 166.743,330.724 166.743,333.168 171.004,333.168 174.124,340.708 
            178.024,340.708 "/>
          <polygon fill="#231F20" points="174.124,340.708 175.9,345 178.024,345 178.024,340.708 "/>
          <polygon fill="#231F20" points="157.143,310.756 155.463,310.756 155.463,314.951 "/>
          <polygon fill="#231F20" points="142.038,330.724 144.183,330.724 144.183,325.777 "/>
          <rect x="155.463" y="330.724" fill="#231F20" width="11.28" height="2.444"/>
          <polygon fill="#231F20" points="166.743,305.939 166.743,310.756 168.812,310.756 "/>
          <polygon fill="#231F20" points="144.183,330.724 142.038,330.724 137.708,340.708 144.183,340.708 "/>
          <polygon fill="#231F20" points="166.743,320.74 166.743,322.694 168.555,327.252 166.743,327.252 166.743,330.724 177.385,330.724 
            173.099,320.74 "/>
          <polygon fill="#231F20" points="150.535,327.252 153.144,320.74 146.367,320.74 144.183,325.777 144.183,330.724 155.463,330.724 
            155.463,327.252 "/>
          <rect x="155.463" y="327.252" fill="#231F20" width="11.28" height="3.472"/>
          <rect x="211.864" y="340.708" fill="#231F20" width="6.276" height="4.292"/>
          <rect x="200.584" y="340.708" fill="#231F20" width="11.28" height="4.292"/>
          <rect x="195.143" y="340.708" fill="#231F20" width="5.44" height="4.292"/>
          <rect x="195.143" y="330.724" fill="#231F20" width="5.44" height="9.984"/>
          <rect x="218.14" y="339.016" fill="#231F20" width="5.223" height="1.692"/>
          <rect x="218.14" y="340.708" fill="#231F20" width="5.223" height="4.292"/>
          <rect x="200.584" y="300.772" fill="#231F20" width="1.292" height="9.984"/>
          <rect x="200.584" y="320.74" fill="#231F20" width="1.292" height="9.984"/>
          <rect x="200.584" y="310.756" fill="#231F20" width="1.292" height="9.984"/>
          <rect x="195.143" y="320.74" fill="#231F20" width="5.44" height="9.984"/>
          <rect x="200.584" y="296.856" fill="#231F20" width="1.292" height="3.916"/>
          <rect x="195.143" y="300.772" fill="#231F20" width="5.44" height="9.984"/>
          <rect x="195.143" y="296.856" fill="#231F20" width="5.44" height="3.916"/>
          <rect x="211.864" y="339.016" fill="#231F20" width="6.276" height="1.692"/>
          <polygon fill="#231F20" points="201.875,339.016 201.875,330.724 200.584,330.724 200.584,340.708 211.864,340.708 211.864,339.016 
            "/>
          <rect x="195.143" y="310.756" fill="#231F20" width="5.44" height="9.984"/>
          <path fill="#231F20" d="M280.688,321.744c-0.369-0.282-0.751-0.546-1.143-0.799v9.779h5.725c-0.096-2.002-0.5-3.706-1.251-5.07
            C283.157,324.09,282.047,322.787,280.688,321.744z"/>
          <path fill="#231F20" d="M258.451,318.718c0.902,0.781,1.899,1.454,2.987,2.022h6.826v-4.208c-0.313-0.11-0.637-0.222-0.939-0.331
            c-1.202-0.431-2.244-0.952-3.128-1.564c-0.884-0.611-1.588-1.359-2.108-2.244c-0.283-0.48-0.483-1.03-0.612-1.637h-4.492v6.41
            C257.428,317.731,257.913,318.252,258.451,318.718z"/>
          <path fill="#231F20" d="M284.463,300.772c-1.308-1.354-2.948-2.471-4.917-3.354v3.354H284.463z"/>
          <path fill="#231F20" d="M279.204,320.74h-10.939v2.569c1.083,0.356,2.14,0.708,3.141,1.053c1.382,0.476,2.595,1.054,3.638,1.733
            c1.042,0.681,1.87,1.508,2.482,2.482c0.387,0.617,0.637,1.339,0.779,2.146h1.24v-9.779
            C279.434,320.874,279.317,320.81,279.204,320.74z"/>
          <path fill="#231F20" d="M256.985,301.655c-0.364,0.483-0.709,0.984-1.016,1.524c-0.976,1.724-1.462,3.786-1.462,6.188
            c0,0.482,0.025,0.94,0.059,1.388h2.418V301.655z"/>
          <path fill="#231F20" d="M256.985,317.167v-6.41h-2.418c0.123,1.616,0.461,3.017,1.029,4.188
            C255.989,315.758,256.456,316.494,256.985,317.167z"/>
          <path fill="#231F20" d="M271.167,317.528c-1.017-0.339-1.975-0.669-2.902-0.996v4.208h10.939c-0.959-0.588-1.979-1.098-3.073-1.513
            C274.453,318.594,272.799,318.027,271.167,317.528z"/>
          <path fill="#231F20" d="M278.444,332.216c0,1.451-0.296,2.687-0.885,3.706c-0.589,1.021-1.349,1.87-2.277,2.55
            c-0.93,0.681-1.962,1.18-3.094,1.496c-1.134,0.318-2.268,0.477-3.4,0.477c-0.177,0-0.347-0.024-0.522-0.031v0.295h11.28v-9.984
            h-1.24C278.388,331.193,278.444,331.683,278.444,332.216z"/>
          <path fill="#231F20" d="M262.599,338.88c-1.903-1.042-3.423-2.402-4.556-4.08l-1.059,0.894v5.015h11.28v-0.295
            C266.244,340.336,264.351,339.84,262.599,338.88z"/>
          <path fill="#231F20" d="M276.199,302.534c1.354,0.696,2.46,1.565,3.346,2.588v-4.35h-11.28v0.771
            c0.814-0.16,1.625-0.267,2.427-0.267C272.732,301.276,274.568,301.695,276.199,302.534z"/>
          <path fill="#231F20" d="M279.545,305.122c0.182,0.21,0.368,0.417,0.53,0.642l4.76-4.624c-0.115-0.129-0.252-0.242-0.373-0.367
            h-4.917V305.122z"/>
          <path fill="#231F20" d="M279.545,300.772v-3.354c-0.212-0.095-0.407-0.202-0.626-0.291c-2.448-0.996-5.077-1.496-7.888-1.496
            c-0.925,0-1.847,0.062-2.767,0.174v4.966H279.545z"/>
          <path fill="#231F20" d="M256.985,335.693l-4.178,3.526c0.425,0.528,0.882,1.019,1.358,1.488h2.82V335.693z"/>
          <path fill="#231F20" d="M279.545,343.122c0.223-0.155,0.452-0.301,0.666-0.468c0.737-0.575,1.399-1.227,1.997-1.946h-2.663V343.122z
            "/>
          <path fill="#231F20" d="M256.985,340.708v2.221c0.897,0.574,1.836,1.101,2.86,1.527c2.712,1.128,5.521,1.7,8.42,1.749v-5.497
            H256.985z"/>
          <path fill="#231F20" d="M268.265,340.708v5.497c0.13,0.002,0.257,0.019,0.387,0.019c2.13,0,4.204-0.295,6.222-0.884
            c1.737-0.507,3.287-1.256,4.672-2.218v-2.414H268.265z"/>
          <path fill="#231F20" d="M254.165,340.708c0.853,0.841,1.803,1.569,2.82,2.221v-2.221H254.165z"/>
          <path fill="#231F20" d="M262.463,321.234c1.496,0.657,3.015,1.213,4.556,1.666c0.426,0.138,0.832,0.273,1.246,0.409v-2.569h-6.826
            C261.772,320.915,262.113,321.081,262.463,321.234z"/>
          <path fill="#231F20" d="M268.265,295.806c-1.064,0.13-2.126,0.329-3.184,0.608c-1.972,0.521-3.74,1.35-5.304,2.482
            c-0.765,0.554-1.457,1.181-2.081,1.876h10.568V295.806z"/>
          <path fill="#231F20" d="M256.985,310.756h4.492c-0.108-0.51-0.17-1.06-0.17-1.661c0-1.359,0.283-2.526,0.851-3.502
            c0.566-0.974,1.303-1.79,2.21-2.447c0.906-0.657,1.914-1.133,3.025-1.429c0.292-0.077,0.582-0.117,0.872-0.174v-0.771h-10.568
            c-0.251,0.28-0.483,0.58-0.712,0.883V310.756z"/>
          <path fill="#231F20" d="M285.311,331.604c0-0.307-0.027-0.586-0.041-0.879h-5.725v9.984h2.663c0.651-0.786,1.224-1.652,1.709-2.61
            C284.846,336.262,285.311,334.098,285.311,331.604z"/>
          <path fill="#231F20" d="M339.381,300.772c-1.069-0.808-2.211-1.534-3.435-2.167v2.167H339.381z"/>
          <path fill="#231F20" d="M335.947,306.622c0.212,0.212,0.435,0.414,0.636,0.638c0.95,1.06,1.755,2.234,2.445,3.497h7.933
            c-0.026-0.065-0.049-0.133-0.076-0.199c-1.292-3.104-3.071-5.756-5.338-7.956c-0.679-0.658-1.409-1.258-2.165-1.829h-3.435V306.622z
            "/>
          <path fill="#231F20" d="M346.884,331.094c0.051-0.122,0.094-0.247,0.143-0.37h-7.809c-0.729,1.406-1.597,2.695-2.636,3.837
            c-0.201,0.221-0.424,0.419-0.636,0.628v5.518h3.818c0.62-0.499,1.214-1.029,1.781-1.59
            C343.813,336.874,345.592,334.199,346.884,331.094z"/>
          <path fill="#231F20" d="M335.947,300.772v-2.167c-0.8-0.414-1.626-0.799-2.492-1.138c-2.723-1.065-5.653-1.666-8.788-1.804v5.109
            H335.947z"/>
          <path fill="#231F20" d="M302.106,306.104v4.653h5.505c0.686-1.263,1.48-2.437,2.418-3.497c0.98-1.108,2.109-2.052,3.357-2.866
            v-3.621h-6.194c-0.754,0.571-1.482,1.171-2.161,1.829C303.938,303.662,302.967,304.833,302.106,306.104z"/>
          <path fill="#231F20" d="M302.106,306.104c-0.925,1.365-1.723,2.846-2.38,4.454c-0.026,0.065-0.049,0.133-0.075,0.199h2.455V306.104z
            "/>
          <path fill="#231F20" d="M313.386,297.359c-0.099,0.038-0.199,0.07-0.298,0.109c-2.176,0.858-4.133,1.969-5.896,3.305h6.194V297.359z
            "/>
          <path fill="#231F20" d="M324.666,300.772v-5.109c-0.466-0.021-0.937-0.032-1.412-0.032c-3.556,0-6.845,0.58-9.868,1.727v3.414
            H324.666z"/>
          <path fill="#231F20" d="M324.666,340.107v0.601h11.28v-5.518c-1.462,1.442-3.165,2.616-5.145,3.486
            C328.929,339.5,326.878,339.963,324.666,340.107z"/>
          <path fill="#231F20" d="M335.947,343.194c1.366-0.72,2.645-1.543,3.818-2.486h-3.818V343.194z"/>
          <path fill="#231F20" d="M306.809,340.708c1.857,1.496,3.95,2.711,6.279,3.646c0.099,0.04,0.199,0.073,0.298,0.111v-3.756H306.809z"
            />
          <path fill="#231F20" d="M341.541,320.74h5.685v-9.294c-0.086-0.231-0.173-0.462-0.266-0.689h-7.933
            c0.462,0.845,0.882,1.722,1.227,2.658C341.101,315.713,341.528,318.156,341.541,320.74z"/>
          <path fill="#231F20" d="M323.254,340.172c-2.766,0-5.26-0.498-7.48-1.496c-0.847-0.38-1.633-0.832-2.388-1.321v3.353h11.28v-0.601
            C324.199,340.137,323.736,340.172,323.254,340.172z"/>
          <path fill="#231F20" d="M347.226,330.207c1.061-2.865,1.597-5.979,1.597-9.347c0-0.041-0.003-0.08-0.003-0.12h-1.594V330.207z"/>
          <path fill="#231F20" d="M305.098,320.86c0-0.041,0.005-0.08,0.005-0.12h-2.997v9.984h5.32c-0.383-0.74-0.739-1.503-1.036-2.316
            C305.529,326.052,305.098,323.535,305.098,320.86z"/>
          <path fill="#231F20" d="M313.386,337.355c-1.225-0.793-2.343-1.713-3.324-2.793c-1.04-1.143-1.907-2.431-2.636-3.837h-5.32v4.843
            c0.861,1.282,1.832,2.469,2.925,3.551c0.566,0.561,1.159,1.091,1.778,1.59h6.577V337.355z"/>
          <path fill="#231F20" d="M347.226,320.74h1.594c-0.012-3.362-0.546-6.458-1.594-9.294V320.74z"/>
          <path fill="#231F20" d="M347.226,320.74h-5.685c0,0.041,0.005,0.08,0.005,0.12c0,2.675-0.432,5.191-1.292,7.548
            c-0.297,0.813-0.653,1.576-1.036,2.316h7.809c0.068-0.172,0.135-0.344,0.199-0.517V320.74z"/>
          <path fill="#231F20" d="M302.106,320.74v-9.984h-2.455c-1.203,3.021-1.812,6.348-1.826,9.984H302.106z"/>
          <path fill="#231F20" d="M330.836,303.078c1.959,0.89,3.651,2.083,5.11,3.544v-5.85h-11.28v0.841
            C326.913,301.759,328.978,302.233,330.836,303.078z"/>
          <path fill="#231F20" d="M313.386,340.708v3.756c3.023,1.169,6.312,1.759,9.868,1.759c0.476,0,0.946-0.011,1.412-0.032v-5.483
            H313.386z"/>
          <path fill="#231F20" d="M324.666,340.708v5.483c3.135-0.141,6.065-0.752,8.788-1.838c0.866-0.345,1.692-0.738,2.492-1.159v-2.486
            H324.666z"/>
          <path fill="#231F20" d="M299.726,331.094c0.657,1.608,1.455,3.096,2.38,4.474v-4.843h-2.52
            C299.634,330.847,299.676,330.972,299.726,331.094z"/>
          <path fill="#231F20" d="M323.254,301.548c0.482,0,0.946,0.036,1.412,0.066v-0.841h-11.28v3.621c0.743-0.485,1.517-0.935,2.354-1.315
            C317.984,302.058,320.489,301.548,323.254,301.548z"/>
          <path fill="#231F20" d="M297.825,320.74c0,0.041-0.002,0.08-0.002,0.12c0,3.572,0.592,6.858,1.764,9.864h2.52v-9.984H297.825z"/>
          <path fill="#231F20" d="M305.103,320.74c0.014-2.584,0.439-5.027,1.287-7.326c0.346-0.936,0.763-1.813,1.221-2.658h-5.505v9.984
            H305.103z"/>
          <rect x="363.442" y="320.74" fill="#231F20" width="6.345" height="9.984"/>
          <rect x="369.786" y="340.708" fill="#231F20" width="11.28" height="4.292"/>
          <rect x="363.442" y="300.772" fill="#231F20" width="6.345" height="9.984"/>
          <rect x="381.067" y="340.708" fill="#231F20" width="10.596" height="4.292"/>
          <rect x="363.442" y="330.724" fill="#231F20" width="6.345" height="9.984"/>
          <rect x="369.786" y="320.74" fill="#231F20" width="0.388" height="9.984"/>
          <polygon fill="#231F20" points="370.174,330.724 369.786,330.724 369.786,340.708 381.067,340.708 381.067,339.016 370.174,339.016 
            "/>
          <rect x="381.067" y="339.016" fill="#231F20" width="10.596" height="1.692"/>
          <rect x="363.442" y="340.708" fill="#231F20" width="6.345" height="4.292"/>
          <rect x="363.442" y="310.756" fill="#231F20" width="6.345" height="9.984"/>
          <rect x="369.786" y="300.772" fill="#231F20" width="0.388" height="9.984"/>
          <rect x="369.786" y="310.756" fill="#231F20" width="0.388" height="9.984"/>
          <rect x="369.786" y="296.856" fill="#231F20" width="0.388" height="3.916"/>
          <rect x="363.442" y="296.856" fill="#231F20" width="6.345" height="3.916"/>
          <path fill="#231F20" d="M402.78,334.29c0.237,0.728,0.53,1.419,0.848,2.094v-5.66h-1.697
            C402.116,331.933,402.398,333.123,402.78,334.29z"/>
          <path fill="#231F20" d="M426.188,345.48c0.86-0.235,1.693-0.505,2.466-0.854c2.201-0.994,4.033-2.31,5.522-3.918h-7.988V345.48z"/>
          <path fill="#231F20" d="M437.468,335.518v-4.794h-5.552c-0.062,0.305-0.125,0.609-0.202,0.914c-0.408,1.61-1.078,3.05-2.007,4.318
            c-0.891,1.216-2.069,2.196-3.52,2.954v1.798h7.988c0.105-0.113,0.224-0.215,0.325-0.332
            C435.759,338.934,436.739,337.308,437.468,335.518z"/>
          <path fill="#231F20" d="M414.907,340.708v4.867c1.664,0.416,3.468,0.648,5.45,0.648c2.133,0,4.065-0.262,5.83-0.744v-4.772H414.907z
            "/>
          <path fill="#231F20" d="M406.538,340.708c1.489,1.608,3.321,2.924,5.523,3.918c0.885,0.399,1.846,0.699,2.846,0.949v-4.867H406.538z
            "/>
          <rect x="432.325" y="310.756" fill="#231F20" width="5.143" height="9.984"/>
          <rect x="403.627" y="310.756" fill="#231F20" width="4.763" height="9.984"/>
          <rect x="437.468" y="310.756" fill="#231F20" width="1.59" height="9.984"/>
          <rect x="401.657" y="310.756" fill="#231F20" width="1.97" height="9.984"/>
          <path fill="#231F20" d="M414.907,339.111c-0.073-0.035-0.156-0.059-0.228-0.095c-1.52-0.77-2.743-1.79-3.672-3.06
            c-0.93-1.269-1.599-2.708-2.007-4.318c-0.077-0.304-0.14-0.609-0.202-0.914h-5.172v5.66c0.683,1.449,1.535,2.786,2.587,3.992
            c0.102,0.117,0.22,0.219,0.324,0.332h8.369V339.111z"/>
          <path fill="#231F20" d="M437.468,335.518c0.164-0.404,0.33-0.807,0.468-1.228c0.382-1.167,0.663-2.357,0.85-3.566h-1.317V335.518z"
            />
          <path fill="#231F20" d="M401.657,320.74v6.443c0,1.199,0.093,2.379,0.272,3.541h1.697v-9.984H401.657z"/>
          <rect x="401.657" y="300.772" fill="#231F20" width="1.97" height="9.984"/>
          <rect x="437.468" y="300.772" fill="#231F20" width="1.59" height="9.984"/>
          <path fill="#231F20" d="M420.358,340.172c-2.161,0-3.971-0.36-5.45-1.061v1.597h11.28v-1.798c-0.064,0.034-0.121,0.073-0.187,0.106
            C424.46,339.787,422.578,340.172,420.358,340.172z"/>
          <path fill="#231F20" d="M408.39,320.74h-4.763v9.984h5.172c-0.268-1.308-0.409-2.624-0.409-3.948V320.74z"/>
          <path fill="#231F20" d="M432.325,320.74v6.036c0,1.324-0.142,2.64-0.409,3.948h5.552v-9.984H432.325z"/>
          <path fill="#231F20" d="M439.058,320.74h-1.59v9.984h1.317c0.18-1.161,0.272-2.341,0.272-3.541V320.74z"/>
          <rect x="403.627" y="300.772" fill="#231F20" width="4.763" height="9.984"/>
          <rect x="437.468" y="296.856" fill="#231F20" width="1.59" height="3.916"/>
          <rect x="403.627" y="296.856" fill="#231F20" width="4.763" height="3.916"/>
          <rect x="401.657" y="296.856" fill="#231F20" width="1.97" height="3.916"/>
          <rect x="432.325" y="296.856" fill="#231F20" width="5.143" height="3.916"/>
          <rect x="432.325" y="300.772" fill="#231F20" width="5.143" height="9.984"/>
          <rect x="471.308" y="320.74" fill="#231F20" width="1.409" height="9.984"/>
          <rect x="465.986" y="320.74" fill="#231F20" width="5.322" height="9.984"/>
          <rect x="465.986" y="310.756" fill="#231F20" width="5.322" height="9.984"/>
          <polygon fill="#231F20" points="465.986,310.756 471.308,310.756 471.308,300.772 460.028,300.772 460.028,302.772 465.986,302.772 
            "/>
          <polygon fill="#231F20" points="472.717,310.756 472.717,302.772 482.588,302.772 482.588,300.772 471.308,300.772 471.308,310.756 
            "/>
          <rect x="471.308" y="340.708" fill="#231F20" width="1.409" height="4.292"/>
          <rect x="471.308" y="310.756" fill="#231F20" width="1.409" height="9.984"/>
          <rect x="471.308" y="330.724" fill="#231F20" width="1.409" height="9.984"/>
          <rect x="460.028" y="296.856" fill="#231F20" width="11.28" height="3.916"/>
          <rect x="482.588" y="296.856" fill="#231F20" width="5.565" height="3.916"/>
          <rect x="471.308" y="296.856" fill="#231F20" width="11.28" height="3.916"/>
          <rect x="450.549" y="296.856" fill="#231F20" width="9.479" height="3.916"/>
          <rect x="465.986" y="340.708" fill="#231F20" width="5.322" height="4.292"/>
          <rect x="450.549" y="300.772" fill="#231F20" width="9.479" height="2"/>
          <rect x="465.986" y="330.724" fill="#231F20" width="5.322" height="9.984"/>
          <rect x="482.588" y="300.772" fill="#231F20" width="5.565" height="2"/>
          <rect x="499.984" y="330.724" fill="#231F20" width="5.165" height="9.984"/>
          <rect x="499.984" y="310.756" fill="#231F20" width="5.165" height="9.984"/>
          <rect x="499.984" y="340.708" fill="#231F20" width="5.165" height="4.292"/>
          <rect x="505.149" y="310.756" fill="#231F20" width="1.567" height="9.984"/>
          <rect x="505.149" y="320.74" fill="#231F20" width="1.567" height="9.984"/>
          <rect x="505.149" y="340.708" fill="#231F20" width="1.567" height="4.292"/>
          <rect x="505.149" y="296.856" fill="#231F20" width="1.567" height="3.916"/>
          <rect x="499.984" y="300.772" fill="#231F20" width="5.165" height="9.984"/>
          <rect x="499.984" y="320.74" fill="#231F20" width="5.165" height="9.984"/>
          <rect x="499.984" y="296.856" fill="#231F20" width="5.165" height="3.916"/>
          <rect x="505.149" y="330.724" fill="#231F20" width="1.567" height="9.984"/>
          <rect x="505.149" y="300.772" fill="#231F20" width="1.567" height="9.984"/>
          <path fill="#231F20" d="M546.836,301.548c1.2,0,2.337,0.113,3.434,0.298v-1.074h-11.28v2.478c0.113-0.055,0.218-0.12,0.333-0.172
            C541.566,302.058,544.071,301.548,546.836,301.548z"/>
          <path fill="#231F20" d="M521.407,320.74c0,0.041-0.003,0.08-0.003,0.12c0,3.572,0.592,6.858,1.764,9.864h4.541v-9.984H521.407z"/>
          <path fill="#231F20" d="M554.418,303.078c2.244,1.02,4.158,2.414,5.746,4.182c0.503,0.561,0.956,1.16,1.386,1.777v-8.265h-11.28
            v1.074C551.741,302.096,553.132,302.493,554.418,303.078z"/>
          <path fill="#231F20" d="M523.308,331.094c1.09,2.669,2.573,5,4.401,7.033v-7.403h-4.541
            C523.216,330.847,523.258,330.972,523.308,331.094z"/>
          <path fill="#231F20" d="M538.99,338.489c-2.061-0.979-3.85-2.281-5.346-3.928c-1.04-1.143-1.907-2.431-2.636-3.837h-3.299v7.403
            c0.299,0.332,0.585,0.676,0.903,0.991c0.566,0.561,1.159,1.091,1.778,1.59h8.599V338.489z"/>
          <path fill="#231F20" d="M530.391,340.708c1.857,1.496,3.95,2.711,6.279,3.646c0.756,0.304,1.529,0.569,2.319,0.798v-4.444H530.391z"
            />
          <path fill="#231F20" d="M528.68,320.86c0-0.041,0.005-0.08,0.005-0.12h-0.976v9.984h3.299c-0.383-0.74-0.739-1.503-1.036-2.316
            C529.111,326.052,528.68,323.535,528.68,320.86z"/>
          <path fill="#231F20" d="M550.27,340.708v5.317c2.381-0.271,4.638-0.823,6.767-1.672c1.622-0.646,3.123-1.433,4.514-2.348v-1.297
            H550.27z"/>
          <path fill="#231F20" d="M570.466,331.094c0.051-0.122,0.094-0.247,0.143-0.37H562.8c-0.376,0.726-0.791,1.419-1.25,2.075v7.909
            h1.797c0.62-0.499,1.214-1.029,1.781-1.59C567.395,336.874,569.174,334.199,570.466,331.094z"/>
          <path fill="#231F20" d="M527.709,303.577c-1.828,2-3.312,4.312-4.401,6.981c-0.026,0.065-0.049,0.133-0.075,0.199h4.477V303.577z"/>
          <path fill="#231F20" d="M561.55,342.005c0.62-0.408,1.223-0.836,1.797-1.297h-1.797V342.005z"/>
          <path fill="#231F20" d="M565.123,320.74h7.278c-0.014-3.636-0.635-6.963-1.859-9.984h-7.933c0.462,0.845,0.882,1.722,1.227,2.658
            C564.683,315.713,565.11,318.156,565.123,320.74z"/>
          <path fill="#231F20" d="M538.99,340.708v4.444c2.453,0.714,5.069,1.072,7.847,1.072c1.173,0,2.315-0.071,3.434-0.198v-5.317H538.99z
            "/>
          <path fill="#231F20" d="M561.55,300.772v-1c-1.391-0.897-2.892-1.669-4.514-2.305c-2.129-0.833-4.386-1.375-6.767-1.641v4.946
            H561.55z"/>
          <path fill="#231F20" d="M550.27,300.772v-4.946c-1.118-0.125-2.261-0.195-3.434-0.195c-2.777,0-5.394,0.352-7.847,1.052v4.088
            H550.27z"/>
          <path fill="#231F20" d="M562.963,300.772c-0.459-0.347-0.926-0.686-1.413-1v1H562.963z"/>
          <path fill="#231F20" d="M527.709,303.577v7.18h3.483c0.686-1.263,1.48-2.437,2.418-3.497c1.483-1.677,3.283-3.006,5.379-4.01v-2.478
            h-8.216c-0.754,0.571-1.482,1.171-2.161,1.829C528.294,302.911,528.008,303.25,527.709,303.577z"/>
          <path fill="#231F20" d="M538.99,296.684c-0.79,0.226-1.563,0.486-2.319,0.784c-2.176,0.858-4.133,1.969-5.896,3.305h8.216V296.684z"
            />
          <path fill="#231F20" d="M561.55,309.037c0.383,0.55,0.734,1.125,1.06,1.719h7.933c-0.026-0.065-0.049-0.133-0.076-0.199
            c-1.292-3.104-3.071-5.756-5.338-7.956c-0.679-0.658-1.409-1.258-2.165-1.829h-1.413V309.037z"/>
          <path fill="#231F20" d="M546.836,340.172c-2.766,0-5.26-0.498-7.48-1.496c-0.127-0.057-0.241-0.127-0.366-0.187v2.219h11.28v-0.833
            C549.168,340.061,548.031,340.172,546.836,340.172z"/>
          <path fill="#231F20" d="M554.384,338.676c-1.288,0.567-2.666,0.954-4.114,1.199v0.833h11.28v-7.909
            c-0.43,0.614-0.883,1.209-1.386,1.762C558.576,336.308,556.651,337.68,554.384,338.676z"/>
          <path fill="#231F20" d="M572.402,320.74h-7.278c0,0.041,0.005,0.08,0.005,0.12c0,2.675-0.432,5.191-1.292,7.548
            c-0.297,0.813-0.653,1.576-1.036,2.316h7.809c1.192-3.006,1.796-6.292,1.796-9.864C572.405,320.82,572.402,320.781,572.402,320.74z"
            />
          <path fill="#231F20" d="M527.709,320.74v-9.984h-4.477c-1.203,3.021-1.812,6.348-1.825,9.984H527.709z"/>
          <path fill="#231F20" d="M528.685,320.74c0.014-2.584,0.439-5.027,1.287-7.326c0.346-0.936,0.763-1.813,1.221-2.658h-3.483v9.984
            H528.685z"/>
          <polygon fill="#231F20" points="621.092,330.724 621.092,335.344 620.956,335.344 617.95,330.746 617.95,340.708 627.824,340.708 
            627.824,330.724 "/>
          <polygon fill="#231F20" points="598.356,300.772 595.796,296.856 595.39,296.856 595.39,300.772 "/>
          <polygon fill="#231F20" points="595.39,308.011 597.166,310.756 604.883,310.756 598.356,300.772 595.39,300.772 "/>
          <polygon fill="#231F20" points="617.95,342.876 619.324,345 627.824,345 627.824,340.708 617.95,340.708 "/>
          <polygon fill="#231F20" points="617.95,330.746 617.937,330.724 610.087,330.724 616.547,340.708 617.95,340.708 "/>
          <rect x="621.092" y="310.756" fill="#231F20" width="6.732" height="9.984"/>
          <polygon fill="#231F20" points="616.547,340.708 617.95,342.876 617.95,340.708 "/>
          <rect x="621.092" y="296.856" fill="#231F20" width="6.732" height="3.916"/>
          <rect x="587.024" y="340.708" fill="#231F20" width="6.732" height="4.292"/>
          <rect x="587.024" y="320.74" fill="#231F20" width="6.732" height="9.984"/>
          <rect x="587.024" y="296.856" fill="#231F20" width="8.366" height="3.916"/>
          <polygon fill="#231F20" points="593.756,310.756 593.756,305.696 593.892,305.696 595.39,308.011 595.39,300.772 587.024,300.772 
            587.024,310.756 "/>
          <polygon fill="#231F20" points="606.67,320.74 606.67,325.444 610.087,330.724 617.937,330.724 611.409,320.74 "/>
          <rect x="621.092" y="300.772" fill="#231F20" width="6.732" height="9.984"/>
          <rect x="621.092" y="320.74" fill="#231F20" width="6.732" height="9.984"/>
          <polygon fill="#231F20" points="606.67,320.74 606.67,313.49 604.883,310.756 597.166,310.756 603.626,320.74 "/>
          <polygon fill="#231F20" points="606.67,325.444 606.67,320.74 603.626,320.74 "/>
          <rect x="587.024" y="310.756" fill="#231F20" width="6.732" height="9.984"/>
          <polygon fill="#231F20" points="606.67,320.74 611.409,320.74 606.67,313.49 "/>
          <rect x="587.024" y="330.724" fill="#231F20" width="6.732" height="9.984"/>
          <path fill="#231F20" d="M650.297,310.756h-6.911c0.124,1.616,0.461,3.017,1.029,4.188c0.725,1.496,1.677,2.754,2.856,3.773
            c0.901,0.781,1.899,1.454,2.987,2.022h1.531v-7.166c-0.335-0.363-0.631-0.755-0.881-1.181
            C650.626,311.914,650.427,311.364,650.297,310.756z"/>
          <path fill="#231F20" d="M651.79,300.772v3.682c0.411-0.487,0.872-0.928,1.397-1.308c0.906-0.657,1.914-1.133,3.025-1.429
            c1.11-0.294,2.21-0.441,3.299-0.441c1.269,0,2.448,0.182,3.559,0.506v-1.01H651.79z"/>
          <path fill="#231F20" d="M651.283,321.234c0.168,0.074,0.339,0.133,0.507,0.204v-0.698h-1.531
            C650.592,320.915,650.933,321.081,651.283,321.234z"/>
          <path fill="#231F20" d="M663.071,325.618c0.274,0.153,0.539,0.312,0.793,0.478c1.042,0.681,1.87,1.508,2.482,2.482
            c0.387,0.617,0.637,1.339,0.779,2.146h6.965c-0.096-2.002-0.5-3.706-1.251-5.07c-0.861-1.564-1.972-2.867-3.331-3.91
            c-0.473-0.362-0.972-0.691-1.483-1.004h-4.954V325.618z"/>
          <path fill="#231F20" d="M657.608,340.444c-2.08,0-4.016-0.475-5.817-1.39v1.654h11.28v-1.589c-0.647,0.356-1.332,0.645-2.062,0.849
            C659.874,340.286,658.74,340.444,657.608,340.444z"/>
          <path fill="#231F20" d="M668.025,320.74c-0.959-0.588-1.979-1.098-3.073-1.513c-0.632-0.239-1.256-0.458-1.881-0.677v2.19H668.025z"
            />
          <path fill="#231F20" d="M655.839,322.9c1.541,0.499,3.003,0.985,4.387,1.462c1.045,0.36,1.993,0.779,2.845,1.255v-4.877h-11.28
            v0.698C653.123,322.001,654.471,322.498,655.839,322.9z"/>
          <path fill="#231F20" d="M656.146,316.202c-1.202-0.431-2.244-0.952-3.128-1.564c-0.458-0.317-0.866-0.673-1.228-1.063v7.166h11.28
            v-2.19c-1.035-0.364-2.065-0.711-3.083-1.022C658.627,317.075,657.346,316.634,656.146,316.202z"/>
          <path fill="#231F20" d="M663.071,301.782c0.676,0.197,1.332,0.435,1.949,0.752c1.632,0.839,2.924,1.915,3.876,3.229l4.76-4.624
            c-0.115-0.129-0.252-0.242-0.373-0.367h-10.212V301.782z"/>
          <path fill="#231F20" d="M651.79,339.054c-0.122-0.062-0.25-0.108-0.371-0.174c-1.903-1.042-3.423-2.402-4.556-4.08l-5.236,4.42
            c0.425,0.528,0.882,1.019,1.358,1.488h8.805V339.054z"/>
          <path fill="#231F20" d="M663.071,300.772v-4.887c-1.048-0.154-2.115-0.254-3.219-0.254c-1.996,0-3.979,0.262-5.95,0.782
            c-0.735,0.194-1.433,0.446-2.111,0.726v3.633H663.071z"/>
          <path fill="#231F20" d="M651.79,297.14c-1.142,0.47-2.212,1.046-3.192,1.757c-0.765,0.554-1.457,1.181-2.081,1.876h5.273V297.14z"/>
          <path fill="#231F20" d="M673.282,300.772c-1.448-1.5-3.283-2.723-5.543-3.645c-1.486-0.605-3.049-1.004-4.669-1.242v4.887H673.282z"
            />
          <path fill="#231F20" d="M643.327,309.368c0,0.482,0.024,0.94,0.059,1.388h6.911c-0.108-0.51-0.17-1.06-0.17-1.661
            c0-1.359,0.283-2.526,0.851-3.502c0.238-0.41,0.514-0.786,0.812-1.139v-3.682h-5.273c-0.652,0.727-1.229,1.527-1.728,2.407
            C643.814,304.903,643.327,306.966,643.327,309.368z"/>
          <path fill="#231F20" d="M667.264,332.216c0,1.451-0.296,2.687-0.885,3.706c-0.589,1.021-1.349,1.87-2.277,2.55
            c-0.329,0.241-0.677,0.451-1.031,0.647v1.589h7.958c0.651-0.786,1.224-1.652,1.709-2.61c0.929-1.836,1.394-4,1.394-6.494
            c0-0.307-0.027-0.586-0.041-0.879h-6.965C667.208,331.193,667.264,331.683,667.264,332.216z"/>
          <path fill="#231F20" d="M642.986,340.708c1.605,1.584,3.494,2.839,5.68,3.748c1.026,0.427,2.071,0.75,3.125,1.022v-4.77H642.986z"/>
          <path fill="#231F20" d="M663.071,345.498c0.207-0.054,0.417-0.098,0.623-0.158c2.017-0.589,3.796-1.484,5.338-2.686
            c0.737-0.575,1.399-1.227,1.997-1.946h-7.958V345.498z"/>
          <path fill="#231F20" d="M651.79,340.708v4.77c1.854,0.479,3.744,0.746,5.682,0.746c1.912,0,3.777-0.251,5.599-0.726v-4.79H651.79z"
            />
          </svg>
          <svg ref={(ref) => this.animBg = ref} className="animate-bg" version="1.1" id="Layer_1" x="0px" y="0px"
            width="987px" height="461px" viewBox="-188.86 9.445 987 461" enableBackground="new -188.86 9.445 987 461">
          <path fill="#231F20" d="M-61.962,296.856h16.524c3.082,0,6.165,0.453,9.248,1.359c3.082,0.907,5.87,2.336,8.364,4.284
            c2.493,1.949,4.51,4.443,6.052,7.48c1.541,3.037,2.312,6.687,2.312,10.947c0,4.126-0.771,7.696-2.312,10.71
            c-1.542,3.016-3.56,5.509-6.052,7.48c-2.494,1.972-5.282,3.445-8.364,4.42c-3.083,0.976-6.166,1.462-9.248,1.462h-16.524V296.856z
            M-55.23,339.084h8.432c2.9,0,5.586-0.408,8.058-1.224c2.471-0.816,4.601-1.995,6.392-3.536c1.791-1.541,3.185-3.445,4.182-5.712
            c0.997-2.267,1.496-4.828,1.496-7.685c0-2.992-0.5-5.62-1.496-7.888c-0.998-2.267-2.392-4.158-4.182-5.678
            c-1.792-1.519-3.921-2.675-6.392-3.469c-2.471-0.792-5.157-1.189-8.058-1.189h-8.432V339.084z"/>
          <path fill="#231F20" d="M1.957,345h-6.732v-48.144h6.732V345z"/>
          <path fill="#231F20" d="M55.405,306.784c-1.452-1.587-3.298-2.856-5.542-3.809c-2.244-0.951-4.75-1.428-7.514-1.428
            c-2.812,0-5.35,0.51-7.616,1.53c-2.267,1.02-4.193,2.414-5.78,4.182c-1.587,1.769-2.812,3.82-3.672,6.154
            c-0.862,2.335-1.292,4.817-1.292,7.446c0,2.675,0.43,5.191,1.292,7.548c0.86,2.357,2.084,4.409,3.672,6.153
            c1.586,1.746,3.536,3.118,5.848,4.114c2.312,0.998,4.896,1.496,7.752,1.496c2.266,0,4.352-0.204,6.256-0.612
            c1.904-0.407,3.558-0.996,4.964-1.768v-13.396H43.096v-5.713h17.34v23.324c-2.539,1.36-5.304,2.403-8.296,3.128
            s-6.256,1.088-9.792,1.088c-3.763,0-7.22-0.623-10.37-1.87c-3.151-1.246-5.859-2.991-8.126-5.235
            c-2.267-2.244-4.035-4.919-5.304-8.024c-1.27-3.104-1.904-6.516-1.904-10.233c0-3.763,0.646-7.196,1.938-10.303
            c1.292-3.104,3.083-5.756,5.372-7.956c2.289-2.198,4.998-3.909,8.126-5.134c3.128-1.224,6.528-1.836,10.2-1.836
            c3.853,0,7.287,0.567,10.302,1.7c3.015,1.134,5.519,2.652,7.514,4.556L55.405,306.784z"/>
          <path fill="#231F20" d="M84.169,345h-6.732v-48.144h6.732V345z"/>
          <path fill="#231F20" d="M118.168,345h-6.732v-42.228H96v-5.916h37.604v5.916h-15.436V345z"/>
          <path fill="#231F20" d="M143.327,345h-7.48l20.876-48.144h6.12L183.515,345H175.9l-4.896-11.832h-22.848L143.327,345z
            M150.535,327.252h18.02l-8.976-22.576L150.535,327.252z"/>
          <path fill="#231F20" d="M201.875,339.016h21.488V345h-28.22v-48.144h6.732V339.016z"/>
          <path fill="#231F20" d="M280.075,305.764c-0.952-1.314-2.244-2.391-3.876-3.229s-3.468-1.258-5.508-1.258
            c-1.089,0-2.188,0.147-3.299,0.441c-1.111,0.296-2.119,0.771-3.025,1.429c-0.907,0.657-1.644,1.474-2.21,2.447
            c-0.567,0.976-0.851,2.143-0.851,3.502c0,1.315,0.261,2.414,0.782,3.298c0.521,0.885,1.225,1.633,2.108,2.244
            c0.884,0.612,1.926,1.134,3.128,1.564c1.2,0.432,2.481,0.873,3.842,1.326c1.632,0.499,3.286,1.065,4.964,1.699
            c1.677,0.636,3.196,1.475,4.557,2.517c1.359,1.043,2.47,2.346,3.331,3.91c0.861,1.563,1.292,3.548,1.292,5.949
            c0,2.494-0.465,4.658-1.394,6.494c-0.93,1.836-2.165,3.355-3.706,4.557c-1.542,1.201-3.321,2.097-5.338,2.686
            c-2.018,0.589-4.092,0.884-6.222,0.884c-3.038,0-5.974-0.589-8.807-1.768s-5.18-2.924-7.038-5.236l5.236-4.42
            c1.133,1.678,2.652,3.038,4.556,4.08c1.904,1.044,3.967,1.564,6.188,1.564c1.133,0,2.267-0.158,3.4-0.477
            c1.132-0.316,2.164-0.815,3.094-1.496c0.929-0.68,1.688-1.529,2.277-2.55c0.589-1.02,0.885-2.255,0.885-3.706
            c0-1.45-0.307-2.662-0.918-3.638c-0.612-0.975-1.44-1.802-2.482-2.482c-1.043-0.68-2.256-1.258-3.638-1.733
            c-1.384-0.477-2.846-0.963-4.387-1.462c-1.541-0.453-3.06-1.009-4.556-1.666s-2.834-1.496-4.012-2.517
            c-1.18-1.02-2.132-2.277-2.856-3.773c-0.726-1.496-1.088-3.354-1.088-5.576c0-2.402,0.486-4.465,1.462-6.188
            c0.975-1.722,2.244-3.15,3.809-4.283c1.563-1.133,3.332-1.961,5.304-2.482c1.972-0.521,3.954-0.782,5.95-0.782
            c2.811,0,5.439,0.5,7.888,1.496c2.448,0.998,4.42,2.335,5.916,4.012L280.075,305.764z"/>
          <path fill="#231F20" d="M348.823,320.86c0,3.718-0.646,7.129-1.938,10.233c-1.292,3.105-3.071,5.78-5.338,8.024
            c-2.268,2.244-4.964,3.989-8.092,5.235c-3.128,1.247-6.528,1.87-10.2,1.87s-7.062-0.623-10.166-1.87
            c-3.105-1.246-5.792-2.991-8.058-5.235c-2.268-2.244-4.036-4.919-5.305-8.024c-1.27-3.104-1.903-6.516-1.903-10.233
            c0-3.763,0.634-7.196,1.903-10.303c1.269-3.104,3.037-5.756,5.305-7.956c2.266-2.198,4.952-3.909,8.058-5.134
            c3.104-1.224,6.494-1.836,10.166-1.836s7.072,0.612,10.2,1.836c3.128,1.225,5.824,2.936,8.092,5.134
            c2.267,2.2,4.046,4.852,5.338,7.956C348.176,313.664,348.823,317.098,348.823,320.86z M341.546,320.86
            c0-2.629-0.432-5.111-1.292-7.446c-0.861-2.334-2.086-4.386-3.672-6.154c-1.588-1.768-3.502-3.162-5.746-4.182
            c-2.244-1.021-4.771-1.53-7.582-1.53c-2.766,0-5.271,0.51-7.514,1.53c-2.244,1.02-4.148,2.414-5.712,4.182
            c-1.564,1.769-2.777,3.82-3.639,6.154c-0.861,2.335-1.292,4.817-1.292,7.446c0,2.675,0.431,5.191,1.292,7.548
            c0.861,2.357,2.085,4.409,3.672,6.153c1.587,1.746,3.49,3.118,5.712,4.114c2.221,0.998,4.715,1.496,7.48,1.496
            c2.765,0,5.28-0.498,7.548-1.496c2.267-0.996,4.192-2.368,5.78-4.114c1.586-1.744,2.811-3.796,3.672-6.153
            C341.115,326.052,341.546,323.535,341.546,320.86z"/>
          <path fill="#231F20" d="M370.174,339.016h21.488V345h-28.221v-48.144h6.732V339.016z"/>
          <path fill="#231F20" d="M420.358,346.224c-3.174,0-5.939-0.533-8.296-1.598c-2.357-1.064-4.308-2.482-5.848-4.25
            c-1.542-1.768-2.687-3.796-3.435-6.086c-0.748-2.289-1.122-4.658-1.122-7.106v-30.327h6.732v29.92c0,1.632,0.204,3.253,0.611,4.861
            c0.408,1.61,1.077,3.05,2.007,4.318c0.929,1.27,2.152,2.29,3.672,3.06c1.518,0.771,3.41,1.156,5.678,1.156
            c2.221,0,4.103-0.385,5.644-1.156c1.541-0.77,2.776-1.79,3.706-3.06c0.929-1.269,1.599-2.708,2.007-4.318
            c0.407-1.608,0.611-3.229,0.611-4.861v-29.92h6.732v30.327c0,2.448-0.374,4.817-1.122,7.106c-0.748,2.29-1.894,4.318-3.435,6.086
            s-3.491,3.186-5.848,4.25C426.296,345.69,423.531,346.224,420.358,346.224z"/>
          <path fill="#231F20" d="M472.717,345h-6.731v-42.228h-15.437v-5.916h37.604v5.916h-15.437V345z"/>
          <path fill="#231F20" d="M506.716,345h-6.732v-48.144h6.732V345z"/>
          <path fill="#231F20" d="M572.405,320.86c0,3.718-0.646,7.129-1.938,10.233c-1.292,3.105-3.071,5.78-5.338,8.024
            c-2.268,2.244-4.964,3.989-8.092,5.235c-3.128,1.247-6.528,1.87-10.2,1.87s-7.062-0.623-10.166-1.87
            c-3.105-1.246-5.792-2.991-8.058-5.235c-2.268-2.244-4.036-4.919-5.305-8.024c-1.27-3.104-1.903-6.516-1.903-10.233
            c0-3.763,0.634-7.196,1.903-10.303c1.269-3.104,3.037-5.756,5.305-7.956c2.266-2.198,4.952-3.909,8.058-5.134
            c3.104-1.224,6.494-1.836,10.166-1.836s7.072,0.612,10.2,1.836c3.128,1.225,5.824,2.936,8.092,5.134
            c2.267,2.2,4.046,4.852,5.338,7.956C571.758,313.664,572.405,317.098,572.405,320.86z M565.128,320.86
            c0-2.629-0.432-5.111-1.292-7.446c-0.861-2.334-2.086-4.386-3.672-6.154c-1.588-1.768-3.502-3.162-5.746-4.182
            c-2.244-1.021-4.771-1.53-7.582-1.53c-2.766,0-5.271,0.51-7.514,1.53c-2.244,1.02-4.148,2.414-5.712,4.182
            c-1.564,1.769-2.777,3.82-3.639,6.154c-0.861,2.335-1.292,4.817-1.292,7.446c0,2.675,0.431,5.191,1.292,7.548
            c0.861,2.357,2.085,4.409,3.672,6.153c1.587,1.746,3.49,3.118,5.712,4.114c2.221,0.998,4.715,1.496,7.48,1.496
            c2.765,0,5.28-0.498,7.548-1.496c2.267-0.996,4.192-2.368,5.78-4.114c1.586-1.744,2.811-3.796,3.672-6.153
            C564.697,326.052,565.128,323.535,565.128,320.86z"/>
          <path fill="#231F20" d="M620.956,335.344h0.136v-38.487h6.732V345h-8.5l-25.433-39.304h-0.136V345h-6.732v-48.144h8.772
            L620.956,335.344z"/>
          <path fill="#231F20" d="M668.896,305.764c-0.952-1.314-2.244-2.391-3.876-3.229s-3.468-1.258-5.508-1.258
            c-1.089,0-2.188,0.147-3.299,0.441c-1.111,0.296-2.119,0.771-3.025,1.429c-0.907,0.657-1.644,1.474-2.21,2.447
            c-0.567,0.976-0.851,2.143-0.851,3.502c0,1.315,0.261,2.414,0.782,3.298c0.521,0.885,1.225,1.633,2.108,2.244
            c0.884,0.612,1.926,1.134,3.128,1.564c1.2,0.432,2.481,0.873,3.842,1.326c1.632,0.499,3.286,1.065,4.964,1.699
            c1.677,0.636,3.196,1.475,4.557,2.517c1.359,1.043,2.47,2.346,3.331,3.91c0.861,1.563,1.292,3.548,1.292,5.949
            c0,2.494-0.465,4.658-1.394,6.494c-0.93,1.836-2.165,3.355-3.706,4.557c-1.542,1.201-3.321,2.097-5.338,2.686
            c-2.018,0.589-4.092,0.884-6.222,0.884c-3.038,0-5.974-0.589-8.807-1.768s-5.18-2.924-7.038-5.236l5.236-4.42
            c1.133,1.678,2.652,3.038,4.556,4.08c1.904,1.044,3.967,1.564,6.188,1.564c1.133,0,2.267-0.158,3.4-0.477
            c1.132-0.316,2.164-0.815,3.094-1.496c0.929-0.68,1.688-1.529,2.277-2.55c0.589-1.02,0.885-2.255,0.885-3.706
            c0-1.45-0.307-2.662-0.918-3.638c-0.612-0.975-1.44-1.802-2.482-2.482c-1.043-0.68-2.256-1.258-3.638-1.733
            c-1.384-0.477-2.846-0.963-4.387-1.462c-1.541-0.453-3.06-1.009-4.556-1.666s-2.834-1.496-4.012-2.517
            c-1.18-1.02-2.132-2.277-2.856-3.773c-0.726-1.496-1.088-3.354-1.088-5.576c0-2.402,0.486-4.465,1.462-6.188
            c0.975-1.722,2.244-3.15,3.809-4.283c1.563-1.133,3.332-1.961,5.304-2.482c1.972-0.521,3.954-0.782,5.95-0.782
            c2.811,0,5.439,0.5,7.888,1.496c2.448,0.998,4.42,2.335,5.916,4.012L668.896,305.764z"/>
          </svg>
      </div>
    )
  }
}

export default DigitalSolution;
