import React from 'react'
//import { TweenMax, Expo, TimelineMax } from 'gsap/all'
import { LOCATION, PAGE } from '../constants';
//import $ from 'jquery'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isColor: false,
      collapsed: true,
      collapsing: false,
      collapseStyle: {},
      collapseHeight: 312,
      collapseDuration: 350
    }
  }

  componentDidMount() {
    //console.log("***************************** Navbar");  //TODO: Remove later when end this project
    const { theRef } = this.props
    if (theRef) theRef(this)
  }

  setPageName(page) {
    let pageName = '';

    switch (page) {
      case PAGE.HOME:
        pageName = 'Home'
        break
      case PAGE.WHO_WE_ARE:
        pageName = 'Who We Are'
        break
      case PAGE.DEVELOPMENT:
      case PAGE.DIGITAL_MEDIA:
      case PAGE.DEVELOPMENT_DETAIL:
      case PAGE.DIGITAL_MEDIA_DETAIL:
        pageName = 'Our Works'
        break
      case PAGE.OUR_SERVICE:
        pageName = 'Our Services'
        break
      case PAGE.OUR_CLIENT:
        pageName = 'Our Clients'
        break
      case PAGE.CONTACT_US:
        pageName = 'Contact Us'
        break
      default:
        pageName = '';
        break
    }

    return pageName;
  }

  selectPage(page) {
    const { isLock, isChanging, isMobile } = this.props
    if (isLock || isChanging) return

    //console.log("Navbar", "selectPage", page); //TODO: Remove later when end this project

    switch (page) {
      case PAGE.HOME:
        this.props.history.push(LOCATION.HOME)
        break
      case PAGE.WHO_WE_ARE:
        this.props.history.push(`/#${LOCATION.WHO_WE_ARE}`)
        break
      case PAGE.DEVELOPMENT:
      case PAGE.DIGITAL_MEDIA:
      case PAGE.DEVELOPMENT_DETAIL:
      case PAGE.DIGITAL_MEDIA_DETAIL:
        this.props.history.push(`/#${LOCATION.DEVELOPMENT}`)
        break
      case PAGE.OUR_SERVICE:
        this.props.history.push(`/#${LOCATION.OUR_SERVICE}`)
        break
      case PAGE.OUR_CLIENT:
        this.props.history.push(`/#${LOCATION.OUR_CLIENT}`)
        break
      case PAGE.CONTACT_US:
        this.props.history.push(`/#${LOCATION.CONTACT_US}`)
        break
      default:
        this.props.history.push(LOCATION.HOME)
        break
    }

    if (isMobile) {
      this.toggleCollapse();
    } else {
      this.setState({
        collapsed: true,
        collapsing: false,
        collapseStyle: {}
      })
    }
  }

  toggleCollapse() {
    const { collapsed, collapseDuration, collapseHeight } = this.state;

    if (collapsed) {
      this.setState({
        collapsing: true,
        collapseStyle: { height: 0 }
      }, () => {
        setTimeout(() => {
          this.setState({
            collapseStyle: { height: collapseHeight }
          })
        }, 0)
        setTimeout(() => {
          this.setState({
            collapsed: false,
            collapsing: false,
            collapseStyle: {}
          })
        }, collapseDuration)
      });
    } else {
      this.setState({
        collapsing: true,
        collapseStyle: { height: collapseHeight }
      }, () => {
        setTimeout(() => {
          this.setState({
            collapseStyle: { height: 0 }
          })
        }, 0)
        setTimeout(() => {
          this.setState({
            collapsed: true,
            collapsing: false,
            collapseStyle: {}
          })
        }, collapseDuration)
      });
    }
  }


  render() {
    const { collapsed, collapsing, collapseStyle } = this.state;
    const { page } = this.props
    const pageName = this.setPageName(page);

    return (
      <div className="bs4 menubar">
        <nav className="navbar fixed-top navbar-expand-md navbar-dark">
          <button className={`navbar-toggler border-0 ${collapsed && 'collapsed'}`} type="button"
            onClick={() => this.toggleCollapse()}>
            <span className="icon-logo"></span>
          </button>
          <div className="navbar-brand d-md-none">{/*pageName*/}</div>
          <div className={`navbar-collapse ${collapsing ? 'collapsing' : 'collapse'} ${!collapsed && 'show'}`} style={collapseStyle}>
            <ul className="navbar-nav mx-auto">
              <li className={`nav-item nav-link px-2 px-lg-3 px-xl-4 ${pageName === "Home" ? "active" : ""}`} 
                  onClick={() => this.selectPage(PAGE.HOME)}>Home</li>
              <li className={`nav-item nav-link px-2 px-lg-3 px-xl-4 ${pageName === "Who We Are" ? "active" : ""}`} 
                  onClick={() => this.selectPage(PAGE.WHO_WE_ARE)}>Who We Are</li>
              <li className={`nav-item nav-link px-2 px-lg-3 px-xl-4 ${pageName === "Our Works" ? "active" : ""}`} 
                  onClick={() => this.selectPage(PAGE.DEVELOPMENT)}>Our Works</li>
              <li className={`nav-item nav-link px-2 px-lg-3 px-xl-4 ${pageName === "Our Services" ? "active" : ""}`} 
                  onClick={() => this.selectPage(PAGE.OUR_SERVICE)}>Our Services</li>
              <li className={`nav-item nav-link px-2 px-lg-3 px-xl-4 ${pageName === "Our Clients" ? "active" : ""}`} 
                  onClick={() => this.selectPage(PAGE.OUR_CLIENT)}>Our Clients</li>
              <li className={`nav-item nav-link px-2 px-lg-3 px-xl-4 ${pageName === "Contact Us" ? "active" : ""}`} 
                  onClick={() => this.selectPage(PAGE.CONTACT_US)}>Contact Us</li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    setLogo: (value) => dispatch({ type: 'IS_COLOR', payload: value })
  }

  return bindActionCreators(action, dispatch)
}

const stateToProps = (state) => {
  return {
    isColor: state.logoColor.isColor
  }
}

export default connect(stateToProps, dispatchToProps)(Navbar)
