import React, { Component } from 'react'
import $ from 'jquery'
import { TweenMax , Power4 } from 'gsap/all';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { LOCATION } from '../constants';

class OurClients extends Component {
  constructor(props) {
    super(props)
    
    this._isMounted = false;
    this._onResizeWindow = this.onResizeWindow.bind(this);
    this.state = {
      contentHeight: 'none',
      open: false,
      clients: []
    }
  }

  componentDidMount() {
    //console.log("***************************** OurClients");
    this._isMounted = true;
    const { theRef } = this.props
    if (theRef) theRef(this)

    this.setState({ 
      open: false,
      clients: this.props.clientsData.clients
    })

    $( ".our-client-panel__client" ).scrollTop(0)
    setTimeout(() => { $( ".our-client-panel__client" ).scrollTop(0) }, 1000);
    TweenMax.set($('.our-client-panel'), { height: '50px', bottom: '-50px'})
    this.startControl()
    this.onResizeWindow();
    window.addEventListener("resize", this._onResizeWindow);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this._onResizeWindow);
  }

  onResizeWindow() {
    this.resizeWindow();
    setTimeout(this.resizeWindow.bind(this), 1000);
  }

  resizeWindow() {
    if (!this._isMounted) return;
    this.setState({
      contentHeight: (window.innerHeight - 100) + 'px'
    })
  }

  startControl(complete = () => {}) {
    //console.log("OurClients", "startControl"); //TODO: Remove later when end this project
    TweenMax.to($('.our-client-panel'), 1, 
    { height: '50px', bottom: 0, ease: Power4.easeInOut, onComplete: () => {
      this.setState({ open: false })
      complete && complete() 
    }})
  }

  dropControl(complete = () => {}) {
    //console.log("OurClients", "dropControl"); //TODO: Remove later when end this project
    TweenMax.to($('.our-client-panel'), 1, 
    { height: '50px', bottom: '-50px', ease: Power4.easeInOut, onComplete: () => {
      this.setState({ open: false })
      complete && complete() 
    }})
  }

  togglePanel(complete = () => {}, forceOpen = null) {
    const { setHash, setLock, setLogo } = this.props
    let open = !this.state.open
    if (forceOpen !== null) open = forceOpen;

    setLock(true);
    setLogo(open)
    this.setState({
      open
    }, () => {
      if ( open ) {
        TweenMax.to($('.our-client-panel'), 1, { height: '100%', bottom: "-50px", ease: Power4.easeInOut, onComplete: () => {
          this.setState({ open: true })
          if (setHash) setHash(LOCATION.OUR_CLIENT)
          complete && complete() 
        }})
      } else {
        TweenMax.to($('.our-client-panel'), 1, { height: '50px', bottom: 0, ease: Power4.easeInOut, onComplete: () => {
          this.setState({ open: false })
          if (setHash) setHash(LOCATION.WHO_WE_ARE)
          complete && complete() 
        }})
      }
    })

    //console.log("OurClients", "togglePanel", "open: " + open, "forceOpen: " + forceOpen); //TODO: Remove later when end this project
  }

  render () {
    const { open, clients, contentHeight } = this.state
    return (
      <div className="our-client-panel bs4">
        <div className="our-client-panel__bar" onClick={() => this.togglePanel()}>
          <span className="ml-4">OUR CLIENTS</span>
          <span className={`our-client-panel__bar--icon ${ !open && 'up' }`}/>
        </div>
        <div className="our-client-panel__client" style={{ 'maxHeight': contentHeight }}>
          <div className="container">
              <div className="row">
                { clients.map((item, index) => (
                  <div key={index} className="col-4 col-sm-4 col-md-3 col-lg-2 text-center my-auto">
                    <img className="brand-image img-fluid" src={item.image} alt={item.brand}/>
                  </div>
                )) }
              </div>
            </div>
        </div>
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    setLogo:(value) => dispatch({ type: 'IS_COLOR', payload: value })
  }

  return bindActionCreators(action, dispatch)
}

const stateToProps = (state) => {
  return {
    clientsData: state.clientsData
  }
}

export default connect(stateToProps, dispatchToProps)(OurClients)
