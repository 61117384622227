import React, { Component } from 'react'


class WhoWeAreWording extends Component {

  render() {
    return (
      <div className="platform-heading-wraper">
        <div className="heading bs4">
          <div className="platform-panel-wrapper__title">WHO WE ARE</div>
          <div className="platform-panel-wrapper__description px-3 pt-0 pt-sm-3 pt-md-4 pb-5 mb-5">
            <p>We are one stop service Digital Agency<br/>Providing Development, Digital Media and Consultant<br/>For Thailand's leading brands and Enterprises</p>
            <p>With our wide array of expertises,<br/>We bridge talents into Elegant, Practical solutions<br/>Delicately craft to impress your customers<br/>We believe that with the right tool, the right solution;<br/>Digital can help people achieving greater than ever before</p>
            <p className="mb-4">Nowadays, Efficiency must be at least squared or cubed<br/>We no longer look at the multiplier,<br/>We look at its Power. We look at SuperScript</p>
          </div>
        </div>
      </div>
    )
  }
}

export default WhoWeAreWording
