import React from 'react'

class TransitionGreen extends React.Component {
  render() {
    return (
      <div className="gwd-div-srpv">
        <img src="/assets/images/svg/green.svg" id="green" className="gwd-img-1v47 gwd-img-t4j9 gwd-gen-13ivgwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_1" className="gwd-img-1v47 gwd-img-86zf gwd-img-1r16 gwd-gen-1bvhgwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_2" className="gwd-img-1v47 gwd-img-86zf gwd-img-yo3n gwd-img-1cwh gwd-gen-ga79gwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_3" className="gwd-img-1v47 gwd-img-wu6y gwd-img-xcpj gwd-gen-12aggwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_4" className="gwd-img-1v47 gwd-img-86zf gwd-img-1cda gwd-img-zu3a gwd-gen-v0begwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_5" className="gwd-img-1v47 gwd-img-86zf gwd-img-yo3n gwd-img-1iwq gwd-img-1fbe gwd-gen-kv98gwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_6" className="gwd-img-1v47 gwd-img-wu6y gwd-img-3rn0 gwd-img-jrvr gwd-gen-kf6vgwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_7" className="gwd-img-1v47 gwd-img-86zf gwd-img-1cda gwd-img-lztc gwd-img-alw9 gwd-gen-uohhgwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_8" className="gwd-img-1v47 gwd-img-86zf gwd-img-yo3n gwd-img-1iwq gwd-img-61s8 gwd-img-1j0n gwd-gen-hkbmgwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_12" className="gwd-img-1v47 gwd-img-wu6y gwd-img-3rn0 gwd-img-11ya gwd-gen-rycegwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_13" className="gwd-img-1v47 gwd-img-86zf gwd-img-1cda gwd-img-lztc gwd-img-js8x gwd-gen-1bvjgwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_14" className="gwd-img-1v47 gwd-img-86zf gwd-img-yo3n gwd-img-1iwq gwd-img-61s8 gwd-img-71a6 gwd-gen-g4gbgwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_9" className="gwd-img-1v47 gwd-img-wu6y gwd-img-3rn0 gwd-img-1ct9 gwd-gen-fbnigwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_10" className="gwd-img-1v47 gwd-img-86zf gwd-img-1cda gwd-img-lztc gwd-img-1d7h gwd-gen-17rjgwdanimation" alt="" />
        <img src="/assets/images/svg/green.svg" id="green_11" className="gwd-img-1v47 gwd-img-86zf gwd-img-yo3n gwd-img-1iwq gwd-img-61s8 gwd-img-vi43 gwd-gen-xkwfgwdanimation" alt="" />
        <img src="/assets/images/svg/white_tail-2.svg" id="white_tail-2" className="gwd-img-1dff gwd-gen-vmfegwdanimation" alt="" />
      </div>
    )
  }
}

export default TransitionGreen
