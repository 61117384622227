import React from 'react'

class TransitionPink extends React.Component {
  render() {
    return (
      <div className="gwd-div-gney">
        <img src="/assets/images/svg/white.svg" id="pink" className="gwd-img-13e8 gwd-img-19qs gwd-img-1jdr gwd-gen-1hj9gwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_1" className="gwd-img-13e8 gwd-img-77hl gwd-img-xtb9 gwd-img-jnp0 gwd-gen-f357gwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_2" className="gwd-img-13e8 gwd-img-77hl gwd-img-12nh gwd-img-1xs2 gwd-gen-1reygwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_3" className="gwd-img-13e8 gwd-img-19qs gwd-img-fvd8 gwd-img-i8tm gwd-gen-13b4gwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_4" className="gwd-img-13e8 gwd-img-77hl gwd-img-xtb9 gwd-img-1gjq gwd-img-14ea gwd-gen-zmzpgwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_5" className="gwd-img-13e8 gwd-img-77hl gwd-img-12nh gwd-img-10op gwd-img-1gnu gwd-gen-hu1ggwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_6" className="gwd-img-13e8 gwd-img-19qs gwd-img-fvd8 gwd-img-cyx9 gwd-img-2mzh gwd-gen-32cpgwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_7" className="gwd-img-13e8 gwd-img-77hl gwd-img-xtb9 gwd-img-1gjq gwd-img-1eor gwd-img-1ckz gwd-gen-1p5zgwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_8" className="gwd-img-13e8 gwd-img-77hl gwd-img-12nh gwd-img-10op gwd-img-1aao gwd-img-9u3j gwd-gen-hcp9gwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_9" className="gwd-img-13e8 gwd-img-19qs gwd-img-fvd8 gwd-img-cyx9 gwd-img-v7mj gwd-gen-1qumgwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_10" className="gwd-img-13e8 gwd-img-77hl gwd-img-xtb9 gwd-img-1gjq gwd-img-1eor gwd-img-lpn3 gwd-gen-1e32gwdanimation" alt="" />
        <img src="/assets/images/svg/white.svg" id="pink_11" className="gwd-img-13e8 gwd-img-77hl gwd-img-12nh gwd-img-10op gwd-img-1aao gwd-img-vmlj gwd-gen-1nuegwdanimation" alt="" />
        <img src="/assets/images/svg/white_tail.svg" id="white_tail" className="gwd-img-x56u gwd-gen-13n5gwdanimation" alt="" />
      </div>
    )
  }
}

export default TransitionPink
