export const LOCATION = {
  HOME: '',
  WHO_WE_ARE: 'whoweare',
  DEVELOPMENT: 'ourworks',
  DEVELOPMENT_DETAIL: 'ourworks/detail',
  DIGITAL_MEDIA: 'ourworks',
  DIGITAL_MEDIA_DETAIL: 'ourworks/detail',
  OUR_SERVICE: 'ourservices',
  OUR_CLIENT: 'ourclients',
  CONTACT_US: 'contactus',
  BLANK: 'blank'
}

export const PAGE = Object.freeze({
  HOME: 0,
  WHO_WE_ARE: 100,
  DEVELOPMENT: 110,
  DEVELOPMENT_DETAIL: 111,
  DIGITAL_MEDIA: 110,
  DIGITAL_MEDIA_DETAIL: 111,
  OUR_SERVICE: 120,
  OUR_CLIENT: 200,
  CONTACT_US: 300,
  BLANK: -1
})
