const initialState = {
  page: undefined,
  reload: false
}

const pageReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'RELOAD_PAGE':
      return {...state, page: action.payload.page, reload: action.payload.reload}
    default : return state
  }
}

export default pageReducer