import { combineReducers, applyMiddleware, createStore } from 'redux'
// import logger from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import worksData from './worksData'
import clientsData from './clientsData'
import logoColor from './IsColor'
import pageReducer from './pageReducer'


const reducers = combineReducers({
  worksData,
  clientsData,
  logoColor,
  pageReducer
})

// let middleware = applyMiddleware(promise, thunk, logger)
let middleware = applyMiddleware(promise, thunk)
const store = createStore(reducers, middleware)

export default store