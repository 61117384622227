/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component} from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery'
import { TweenMax, Expo } from 'gsap/all';

class ContactUs extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false;
    this._onResizeWindow = this.onResizeWindow.bind(this);
    this.state = {
      mapHeight: 'none',
      infoHeight: 'none',
      responsive: {
        social: "",
        infoRow: "",
        infoTitle: "",
        infoValue: ""
      }
    }
  }

  componentDidMount() {
    //console.log("***************************** ContactUs");
    this._isMounted = true;
    this.props.setLogo(true)
    const { theRef } = this.props
    if (theRef) theRef(this)

    $( ".contact-us-wrapper" ).scrollTop(0)
    setTimeout(() => { $( ".contact-us-wrapper" ).scrollTop(0) }, 1000);
    TweenMax.set($('.contact-us-wrapper'), { y: 200, opacity: 0  })
    this.onResizeWindow();
    window.addEventListener("resize", this._onResizeWindow);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this._onResizeWindow);
  }

  onResizeWindow() {
    this.resizeWindow();
    setTimeout(this.resizeWindow.bind(this), 1000);
  }

  resizeWindow() {
    if (!this._isMounted) return;
    const detectPosition = $(".detect-bottom").position()
    const mapPosition = $(".contact-us-wrapper__map").position()
    const addressPosition = $(".contact-us-wrapper__address").position()
    const addressHeight = $(".contact-us-wrapper__address").height()
    const contentY = mapPosition ? mapPosition.top : 0
    const mapHeight = (window.innerHeight - contentY - addressHeight) + 'px'
    const infoHeight = (detectPosition.top - addressPosition.top - 70) + 'px'
    this.setState({
      mapHeight: window.innerWidth < 768 ? "" : mapHeight,
      infoHeight: window.innerWidth < 768 ? infoHeight : "",
      responsive: {
        social: (window.innerWidth < 576 && window.innerHeight >= 568) ? "fixed-bottom" : "",
        infoRow: window.innerWidth < 768 ? "row" : "",
        infoTitle: window.innerWidth < 768 ? "col-4 offset-2 col-md-12 offsetmd-0 " : "",
        infoValue: window.innerWidth < 768 ? "col-6 col-md-12" : "",
      }
    })
  }

  openContactus(complete = () => {}) {
    //console.log("ContactUs", "openContactus"); //TODO: Remove later when end this project
    this.props.setLogo(true)
    TweenMax.fromTo($('.contact-us-wrapper'), 1, { y: 200, opacity: 0  },{ y: 0, opacity: 1, ease: Expo.easeInOut, onComplete: () => complete && complete() })
  }

  closeContactus(complete = () => {}) {
    //console.log("ContactUs", "closeContactus"); //TODO: Remove later when end this project
    TweenMax.to($('.contact-us-wrapper'), 1, { y: 200, opacity: 0, ease: Expo.easeInOut, onComplete: () => complete && complete() })
  }

  render() {
    const { mapHeight, infoHeight, responsive } = this.state
    return (
      <div className="contact-us-wrapper bs4">
        <div className="contact-us-wrapper__title">
          <h1 className="d-none d-sm-block">CONTACT US</h1>
          <div className="d-block d-sm-none padding-wrapper small"></div>
        </div>
        <div className="contact-us-wrapper__map" style={{ height: mapHeight }}>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                width="100%"
                height="100%"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=superscriptmedia&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
              </iframe>
            </div>
          </div>
        </div>
        <div className="contact-us-wrapper__address container-fluid">
          <div className="address-group row" style={{ height: infoHeight }}>
            <div className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 m-auto">
              <div className="row">
                <div className="location-with-logo col-12 col-md-5">
                  <img className="logo" src="/assets/images/svg/logo.svg" alt="logo"/>
                  <div className="location-address">
                    Sathorn Square 98 North Sathorn Road, <br/>37/F Sathorn Square, Silom, Bangrak, <br/>10500 Bangkok
                  </div>
                </div>

                <div className="location-with-number col-12 col-md-3 px-3 px-sm-0 mt-2 mt-md-3">
                  <div className="row">
                    <div className="col-12 col-md-auto mx-auto info-group">
                      <div className={`bussiness ${responsive.infoRow} my-2 my-md-4`}>
                        <span className={`title ${responsive.infoTitle}`}>MON-FRI</span>
                        <span className={`value ${responsive.infoValue}`}>9:00 - 18:00</span>
                      </div>
                      <div className={`bussiness ${responsive.infoRow} my-2 my-md-4`}>
                        <span className={`title ${responsive.infoTitle}`}>TEL</span>
                        <a href="tel:+6622872245" className={`link-hover ${responsive.infoValue}`}>
                          <span className="value">02 287 2245</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`location-with-social col-12 col-md-4 px-0 mt-2 mt-sm-3 ${responsive.social}`}>
                  <div className="container px-0">
                    <div className="title">FOR MORE INFORMATION</div>
                    <div className="icon-group">
                      <a href="https://www.facebook.com/superscriptmedia/" rel="noopener noreferrer" target="_blank">
                        <i className="icon facebook" />
                      </a>
                      <span className="icon"> | </span>
                      <a href="mailto:digital@superscriptmedia.co.th">
                        <i className="icon mail" />
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => {
  const action = {
    setLogo:(value) => dispatch({ type: 'IS_COLOR', payload: value })
  }

  return bindActionCreators(action, dispatch)
}

export default connect(null, dispatchToProps)(ContactUs)
